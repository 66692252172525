import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './core';
import { AuthLayoutComponent } from './core';

import { AuthGuard } from './services/auth/auth.guard';
import { PrintLayoutComponent } from './print-layout';
import { RptCISAttendeesPrintComponent } from './printreports/cisattendeesreport/rptcisattendees.component';
import{CMEHoursPrintComponent} from'./printreports/cmehoursreport/cmehoursreport.component'
import { MedicallicensbystatePrintComponent } from './printreports/medicallicensereportbystate/medicallicensereportbystate.component';
import { FirtsLevelSurvayReportComponent } from './printreports/firstlevelsurveyreport/firstlevelsurveyreport.component';
import { SupervisoryAgreementPrintComponent } from './printreports/supervisoryagreementreport/supervisoryagreementreport.component';
import {CriticalPrintComponent} from'./printreports/criticalreport/criticalreport.component'
import {employmentstatusreportPrintComponent} from './printreports/employmentstatusreport/employmentstatusreport.component'
import {CMOCornerLoyaltyscorePrintComponent} from './printreports/cmocornerloyaltyscorereport/cmocornerloyaltyscorereport.component'
import {CMEReimbursementPrintComponent} from './printreports/cmereimbursementrereport/cmereimbursementrereport.component'
import {RecruitsOnstaffPrintComponent} from './printreports/onstaffcontactreport/onstaffcontactreport.component'
import {RecruitsMultipleliveclinicPrintComponent} from './printreports/multipleliveclinicreport/multipleliveclinicreport.component'
import {ClinicLocationPrintComponent} from './printreports/cliniclocationreport/cliniclocationreport.component'
import {RecruitsEWNNScorePrintComponent} from './printreports/ewnnscorereport/ewnnscorereport.component'
import {CMOCornerReportComponent} from './printreports/cmocornerreport/cmocornerreport.component'
import { OnstafflistwithuniquecodereportComponent } from './printreports/onstafflistwithuniquecodereport/onstafflistwithuniquecodereport.component';
import { SupervisoryphysiciansPrintComponent } from './printreports/supervisoryphysiciansreport/supervisoryphysiciansreport.component';
import{ FollowupreportComponent } from './printreports/followupreport/followupreport.component'
import { ClinicalleadersteamsPrintComponent } from './printreports/clinicalleadersteamsreport/clinicalleadersteamsreport.component';
export const AppRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'access2md',
        loadChildren: './access2md/access2md.module#Accdss2mdModule'
      },
      {
        path: 'newrecruits',
        loadChildren: './newrecruits/new-recruits.module#NewRecruitsModule'
      },
      {
        path: 'paststaff',
        loadChildren: './paststaff/paststaff.module#PastStaffModule'
      },
      {
        path: 'access2ancillary',
        loadChildren: './access2ancillary/access2ancillary.module#Access2AncillaryModule'
      },
      {
        path: 'narrownetwork',
        loadChildren: './narrownetwork/narrow-network.module#NarrowNetworkModule'
      },      
      {
        path: 'ewnn',
        loadChildren: './ewnn/ewnn.module#EwnnModule'
      },
      {
        path: 'a2i',
        loadChildren: './a2i/a2i.module#A2iModule'
      },
      {
        path: 'masonhealth',
        loadChildren: './masonhealth/mason-health.module#MasonHealthModule'
      },
      {
        path: 'bronson',
        loadChildren: './bronson/bronson.module#BronsonModule'
      },
      {
        path: 'bronson',
        loadChildren: './bronson/bronson.module#BronsonModule'
      },
      {
        path: 'cisattendees',
        loadChildren: './cisattendees/cisattendees.module#CISAttendeesModule'
      },
      {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule'
      },
      {
        path: 'followups',
        loadChildren: './followups/followups.module#FollowupsModule'
      }
      // ,
      // {
      //   path: 'followupsdrcrumpler',
      //   loadChildren: './followupsdrcrumpler/followupsdrcrumpler.module#FollowupsdrcrumplerModule'
      // }
      ,
      {
        path: 'liveclinic',
        loadChildren: './liveclinic/liveclinic.module#LiveClinicModule'
      }
      ,
      {
        path: 'activerecruiting',
        loadChildren: './activerecruiting/activerecruiting.module#ActiveRecruitingModule'
      }
      ,
      {
        path: 'reports',
        loadChildren: './reports/reports.module#ReportsModule'
      },
      {
        path: 'interview',
        loadChildren: './interview/interview.module#InterviewModule'
      },
       {
        path: 'cliniclocation',
        loadChildren: './cliniclocation/cliniclocation.module#ClinicLocationModule'
      }
      ,
       {
        path: 'cmocorner',
        loadChildren: './cmocorner/cmocorner.module#CMOCornerModule'
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'account',
        loadChildren: './account/account.module#AccountModule'
      },

      {
        path: 'error',
        loadChildren: './error/error.module#ErrorModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
  ,
  { path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'proactivemdInstitute/:Id', component: RptCISAttendeesPrintComponent }
    ]
  }  
  ,
  { path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'medicallicensbystatePrint/:Id', component: MedicallicensbystatePrintComponent }
    ]
  }  
  ,
  { path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'medicallicensbystatePrint/:invoiceIds', component: MedicallicensbystatePrintComponent }
    ]
  }  
  ,
  { path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'firstlevelsurveyreports/:Id', component: FirtsLevelSurvayReportComponent }
    ]
  } 
  ,
  { path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'supervisoryagreementprint/:Id', component: SupervisoryAgreementPrintComponent }
    ]
  } 
  ,
  {
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      {path: 'clinicalleadersteamsprint/:Id', component: ClinicalleadersteamsPrintComponent }
    ]
  } 
  ,
  { path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'cmehoursreports/:Id', component: CMEHoursPrintComponent }
    ]
  }
  ,
  { path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'criticalreportscomponent/:Id', component: CriticalPrintComponent }
    ]
  } 
  ,
  { path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'employmentstatusreportscomponent/:Id', component: employmentstatusreportPrintComponent }
    ]
  },
  { path: 'print',
  outlet: 'print',
  component: PrintLayoutComponent,
  children: [
    { path: 'cmocornerloyaltyscorescomponent/:Id', component: CMOCornerLoyaltyscorePrintComponent }
  ]
  },
  { path: 'print',
  outlet: 'print',
  component: PrintLayoutComponent,
  children: [
    { path: 'cmereimbursementPrintComponent/:Id', component: CMEReimbursementPrintComponent }
  ]
  }
  ,
  { path: 'print',
  outlet: 'print',
  component: PrintLayoutComponent,
  children: [
    { path: 'onstaffprintcomponent/:Id', component: RecruitsOnstaffPrintComponent }
  ]
  }
  ,
  { path: 'print',
  outlet: 'print',
  component: PrintLayoutComponent,
  children: [
    { path: 'multipleliveclinicprintcomponent/:Id', component: RecruitsMultipleliveclinicPrintComponent }
  ]
  }
  ,
  { path: 'print',
  outlet: 'print',
  component: PrintLayoutComponent,
  children: [
    { path: 'cliniclocationprintcomponent/:Id', component: ClinicLocationPrintComponent }
  ]
  }
  ,
  { path: 'print',
  outlet: 'print',
  component: PrintLayoutComponent,
  children: [
    { path: 'ewnnscorecomponent/:Id', component: RecruitsEWNNScorePrintComponent }
  ]
  }
  ,
  { path: 'print',
  outlet: 'print',
  component: PrintLayoutComponent,
  children: [
    { path: 'commonprintreport/:Id', component: CMOCornerReportComponent }
  ]
  },
  { path: 'print',
  outlet: 'print',
  component: PrintLayoutComponent,
  children: [
    { path: 'followupprintreport/:Id', component: FollowupreportComponent }
  ]
  }
  ,
  { path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'onstafflistwithuniquecodereportprint/:Id', component: OnstafflistwithuniquecodereportComponent }
    ]
  }
  ,
  { path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'supervisoryphysicians/:Id', component: SupervisoryphysiciansPrintComponent }
    ]
  }  
];
