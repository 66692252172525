import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import { FollowupreportService } from './followupreport.service';
import {FollowupTableView} from'./followupreport.model';

@Component({
  selector: 'app-followupreport',
  templateUrl: './followupreport.component.html',
  styleUrls: ['./followupreport.component.scss']
})
export class FollowupreportComponent implements OnInit {
  rowIds: string[];
  rowDetails: Promise<any>[];
  fetch: any[] = [];
  rowId:number;
  FollowupsReportlist:FollowupTableView[]=[];
  name:string;
  reporttype:string;
  print:string;

  constructor(private route: ActivatedRoute, private follwupreportservice:FollowupreportService,
    private printService: PrintService,
    private http: HttpClient ) { 
      this.rowIds = route.snapshot.params['Id']
          .split(',');
          this.rowId = +this.rowIds[0];
          this.reporttype=this.rowIds[2];
          this.print=this.rowIds[1];
    }

  ngOnInit() {
    this.follwupreportservice.getFollowupsReportlist()
         .toPromise()
         .then(res => {
           this.FollowupsReportlist = res;
           if(this.print=='PDF')
           {
            this.printService.onDataReady(true,this.reporttype,'PDF') 
          
           }
           if(this.print=='EXCEL')
           {
            this.printService.onDataReady(true,this.reporttype,'EXCEL',this.reporttype+'.xlsx',0,'fuptable') 
            
           }
        
      
          
          
           
         })
         .catch(err => { console.log(err) });
     
    
      
     }

}
