import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import { RecruitsOnstaffPrintService } from './onstaffcontactreport.service';
import {onstaffcontactlist} from'./onstaffcontactreport.model';
@Component({
    selector: 'app-form-onstaffcontactreport',
    templateUrl: './onstaffcontactreport.component.html',
    styleUrls: ['./onstaffcontactreport.component.scss'] 
  })
  export class RecruitsOnstaffPrintComponent{
    rowIds: string[];
    rowDetails: Promise<any>[];
    fetch: any[] = [];
    rowId:number;
    recruitsonstaffcontactlist:onstaffcontactlist[]=[];
    name:string;
    print:string;
   
    constructor( private route: ActivatedRoute, private recruitsOnstaffPrintService:RecruitsOnstaffPrintService,
        private printService: PrintService,
        private http: HttpClient ){
          this.rowIds = route.snapshot.params['Id']
          .split(',');
          this.rowId = +this.rowIds[0];
          this.print=this.rowIds[1];
        
    }
    ngOnInit() {
   

       
         
         this.recruitsOnstaffPrintService.getRecruitsOnstafflist()
         .toPromise()
         .then(res => {
           this.recruitsonstaffcontactlist = res;
          
           if(this.print=='PDF')
           {
            this.printService.onDataReady(true,'On Staff Clinicians, Cell, Date Hire, FTE/PT/Locums') 
           }
           if(this.print=='EXCEL')
           {
            this.printService.onDataReady(true,'On Staff Clinicians, Cell, Date Hire, FTE/PT/Locums','EXCEL','On Staff Clinicians, Cell, Date Hire, FTE/PT/Locums.xlsx',0,'customers') 
           }
           
          
        
          
          
           
         })
         .catch(err => { console.log(err) });
     
    
      
     }
  }