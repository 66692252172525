import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  // transform(items: any[], field : string, value : string): any[] {  
  //   if (!items) return [];
  //   if (!value || value.length == 0) return items;
  //   return items.filter(it => 
  //   it[field].toString().toLowerCase().indexOf(value.toLowerCase()) !=-1);
  transform(items: Array<any>, filter: {[key: string]: any },arrayValues): Array<any> {
    if (!items) return [];
    if(filter){        
      return items.filter(item => {
       
        let notMatchingField = Object.keys(filter)
                              .find(key => filter[key].indexOf(item[key]) == -1);
                return !notMatchingField;
      });
               
  }else{
      let notMatchingField=items;
      return notMatchingField
    }
  }
 
}