import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitstbl,statuspara,affiliationscategory,recruiteducation,recruitboard,
    recruitshonors,recruitsmembership,recruitsfellowship,recruitsresidencies,recruitsinternships
    ,recruitsaffiliations,recruitsemployer,recruitsreferences,recruitsmalpractice,recruitsmedicallicenses
,recruitsotherdetails,recruitsclinicalexperience,recruitsewexperience,recruitsewfollowups,recruitscisAattendees
,recruitscliniclocation,recruitsinterview,recruitsinterviewform,recruitsinterviewby,othercolumnName,recruitsotherdetailsview
,boardCertifiednamelist,recruitscmocorner,recruitsexitinterview,recruitscmocornerlist
,recruitsfte,
clinicalleadrs} from './recruits-view.model'

@Injectable()

export class RecruitsviewService{
    public recruitview: recruitstbl;
    public status:statuspara[] = [];
    public category:affiliationscategory[] = [];
    public recruiteducationlist:recruiteducation[] = [];
    public recruitboardlist:recruitboard[] = [];
    public recruitshonorslist:recruitshonors[]=[];
    public recruitsmembershiplist:recruitsmembership[]=[];
    public recruitsfellowshiplist:recruitsfellowship[]=[];
    public recruitsresidencieslist:recruitsresidencies[]=[];
    public recruitsinternshipslist:recruitsinternships[]=[];
    public recruitsaffiliationslist:recruitsaffiliations[]=[];
    public recruitsemployerlist:recruitsemployer[]=[];
    public recruitsreferenceslist:recruitsreferences[]=[];
    public recruitsmalpracticelist:recruitsmalpractice[]=[];
    public recruitsmedicallicenseslist:recruitsmedicallicenses[]=[];
    public recruitsotherdetailslist:recruitsotherdetailsview[]=[];
    public recruitsewfollowupslist:recruitsewfollowups[]=[];
    public recruitsclinicalexperiencelist:recruitsclinicalexperience;
    public recruitsewexperiencelist:recruitsewexperience;
    public recruitscisAattendeeslist:recruitscisAattendees[]=[];
    public recruitsclinicanlocationlist:recruitscliniclocation[]=[];
    public recruitsinterviewlist:recruitsinterview;
    public recruitsinterviewformlist:recruitsinterviewform;
    public recruitsinterviewbylist:recruitsinterviewby[]=[];
    public boardCertifiednamelist:boardCertifiednamelist[]=[];
    public othercolumnNamelist:othercolumnName;
    public recruitscmocornerlist:recruitscmocorner[]=[];
    public recruitsexitinterviewlist:recruitsexitinterview;
    public recruitscmocornerviewlist:recruitscmocornerlist[]=[];
    public recruitsftelist:recruitsfte[]=[];
    public recruitsfteyearlist:recruitsfte[]=[];
    public Leaders:clinicalleadrs[] = [];
    constructor(private http:HttpClient){

    }
    onGetRecruits(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/${id}`)
            .map( (data:recruitstbl)=>{
                this.recruitview = data;
                return true;
            });
    }
    OnGetStatuspara(): Observable<boolean> {
        return this.http.get('api/recruits/getstatuspara')
            .map( (data:any[])=>{
                this.status = data;
                return true;
            });
      }
      OnGetClinicalLeadrespara(): Observable<boolean> {
        return this.http.get('api/recruits/getleaders')
            .map( (data:any[])=>{
                this.Leaders = data;
                return true;
            });
      }
      OnGetAffiliationscategory(cid:number): Observable<boolean> {
         
        return this.http.get(`api/recruits/getaffiliationscategory?cid=${cid}`)
            .map( (data:any[])=>{
                this.category = data;
                return true;
            });
      }
      DeleteFile(id:number,fieldfile:string): Observable<boolean> {
        
        return this.http.get(`api/recruits/deleteclinicianfile/${id}?fieldfile=${fieldfile}`)
            .map( (data:recruitstbl)=>{
                this.recruitview = data;
                return true;
            });
    }
    onPostForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits`, this.recruitview,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    onDeleteForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruits/${id}`)
            .map(()=>{            
                return true;
            });
    } 
// Recruit Education
      onGetRecruitsEductionbyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitseductionbyrid?rid=${rid}`)
        .map( (data:any[])=>{
            this.recruiteducationlist = data;
            return true;
        });
    }
// Recruit Education
    // Recruit Board
    onGetRecruitsBoardbyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsboardcertificationbyrid?rid=${rid}`)
        .map( (data:any[])=>{
            this.recruitboardlist = data;
            return true;
        });
    }
    onDeleteRecruitsBoardForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitsboardcertification/${id}`)
            .map(()=>{            
                return true;
            });
    } 
    // Recruit Board

     // Recruit Honors
     onGetRecruitsHonorsbyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitshonorsbyrid?rid=${rid}`)
        .map( (data:any[])=>{
            this.recruitshonorslist = data;
            return true;
        });
    }
    onDeleteRecruitsHonorsForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitshonors/${id}`)
            .map(()=>{            
                return true;
            });
    } 
    // Recruit Honors
  
      // Recruit Membership
      onGetRecruitsMembershipbyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsmembershipbyrid?rid=${rid}`)
        .map( (data:any[])=>{
            this.recruitsmembershiplist = data;
            return true;
        });
    }
    onDeleteRecruitsMembershipForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitsmembership/${id}`)
            .map(()=>{            
                return true;
            });
    } 
    // Recruit Membership

     // Recruit Fellowship
     onGetRecruitsFellowshipbyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsfellowshiphipbyrid?rid=${rid}`)
        .map( (data:any[])=>{
            this.recruitsfellowshiplist = data;
            return true;
        });
    }
    onDeleteRecruitsFellowshipForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitsfellowship/${id}`)
            .map(()=>{            
                return true;
            });
    } 
    // Recruit Fellowship

    // Recruit Residencies
    onGetRecruitsResidenciesbyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsresidenciesbyrid?rid=${rid}`)
        .map( (data:any[])=>{
            this.recruitsresidencieslist = data;
            return true;
        });
    }
    onDeleteRecruitsResidenciesForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitsresidencies/${id}`)
            .map(()=>{            
                return true;
            });
    } 
    // Recruit Residencies

       // Recruit Internships
       onGetRecruitsInternshipsbyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsinternshipsbyrid?rid=${rid}`)
        .map( (data:any[])=>{
            this.recruitsinternshipslist = data;
            return true;
        });
    }
    onDeleteRecruitsInternshipsForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitsinternships/${id}`)
            .map(()=>{            
                return true;
            });
    } 
    // Recruit Internships

     // Recruit Affiliations
     onGetRecruitsAffiliationsbyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsaffiliationsbyrid?rid=${rid}`)
        .map( (data:any[])=>{
            this.recruitsaffiliationslist = data;
            return true;
        });
    }
    onDeleteRecruitsAffiliationsForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitsaffiliations/${id}`)
            .map(()=>{            
                return true;
            });
    } 
    // Recruit Affiliations

       // Recruit Employer
       onGetRecruitsEmployerbyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsemployerbyrid?rid=${rid}`)
        .map( (data:any[])=>{
            this.recruitsemployerlist = data;
            return true;
        });
    }
    onDeleteRecruitsEmployerForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitsemployer/${id}`)
            .map(()=>{            
                return true;
            });
    } 
    // Recruit Employer

       // Recruit References
       onGetRecruitsReferencesbyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsreferencesbyrid?rid=${rid}`)
        .map( (data:any[])=>{
            this.recruitsreferenceslist = data;
            return true;
        });
    }
    onDeleteRecruitsReferencesForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitsreferences/${id}`)
            .map(()=>{            
                return true;
            });
    } 
    // Recruit References
       // Recruit Malpractice
       onGetRecruitsMalpracticebyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsmalpracticebyrid?rid=${rid}`)
        .map( (data:any[])=>{
            this.recruitsmalpracticelist = data;
            return true;
        });
    }
    onDeleteRecruitsMalpracticeForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitsmalpractice/${id}`)
            .map(()=>{            
                return true;
            });
    } 
    // Recruit Malpractice
      // Recruit MedicalLicenses
      onGetRecruitsMedicalLicensesbyrid(rid:number,search:string): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsmedicallicensesbyrid?rid=${rid}&search=${search}`)
        .map( (data:any[])=>{
            this.recruitsmedicallicenseslist = data;
            return true;
        });
    }
    onDeleteRecruitsMedicalLicensesForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitsmedicallicenses/${id}`)
            .map(()=>{            
                return true;
            });
    } 
  
    // Recruit MedicalLicenses

      // Recruit Other Details
      onGetRecruitsOtherDetailsbyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsotherdetailsbyrid?rid=${rid}`)
        .map( (data:any[])=>{
            this.recruitsotherdetailslist = data;
            return true;
        });
    }
    onDeleteRecruitsOtherDetailsForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitsotherdetails/${id}`)
            .map(()=>{            
                return true;
            });
    } 
    onGetRecruitsOtherDetailscolumnnameid(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getotherdetails?rid=${id}`)
        .map( (data:any)=>{
            this.othercolumnNamelist = data;
            return true;
        });
    }
    onGetRecruitsOtherPrivilegesDetailsbyrid(rid:number,privileges:boolean): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsotherPrivilegesdetailsbyrid?rid=${rid}&privileges=${privileges}`)
        .map( (data:any[])=>{
            this.recruitsotherdetailslist = data;
            return true;
        });
    }
    // Recruit Other Details
      // Recruit EWNN Followup
      onGetRecruitsEWFollowupbyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsennfollowupsbyrid?rid=${rid}`)
        .map( (data:any[])=>{
            this.recruitsewfollowupslist = data;
            return true;
        });
    }
    onDeleteRecruitsEWFollowupForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitsennfollowups/${id}`)
            .map(()=>{            
                return true;
            });
    } 
    // Recruit EWNN Followup

     // Recruit Clinical Experience
     onGetRecruitsClinicalExperiencebyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsclinicalexperiencebyrid?rid=${rid}`)
        .map( (data:any)=>{
            this.recruitsclinicalexperiencelist = data;
            return true;
        });
    }
    onPostClinicalExperience(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsclinicalexperience`, this.recruitsclinicalexperiencelist,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
  
    // Recruit Clinical Experience

       // Recruit Expertwitness Experience
       onGetRecruitsEWExperiencebyrid(rid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsexpertwitnessexperiencebyrid?rid=${rid}`)
        .map( (data:any)=>{
            this.recruitsewexperiencelist = data;
            return true;
        });
    }
    onPostEWExperience(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsexpertwitnessexperience`, this.recruitsewexperiencelist,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
  
    // Recruit Expertwitness Experience
  // Recruit CISAattendees
  onGetRecruitsCISAattendeesbyrid(rid:number): Observable<boolean> {
        
    return this.http.get(`api/recruits/getrecruitscisaattendeesbyrid?rid=${rid}`)
    .map( (data:any[])=>{
        this.recruitscisAattendeeslist = data;
        return true;
    });
}
onDeleteRecruitsCISAattendeesForm(id:number): Observable<boolean> {        
    return this.http.delete(`api/recruits/deleterecruitscisaattendees/${id}`)
        .map(()=>{            
            return true;
        });
} 
// Recruit CISAattendees
// Recruit Liveclinic
onGetRecruitsLiveclinicbyrid(rid:number): Observable<boolean> {
        
    return this.http.get(`api/recruits/getrecruitsliveclinicbyrid?rid=${rid}`)
    .map( (data:any[])=>{
        this.recruitsclinicanlocationlist = data;
        return true;
    });
}
onDeleteRecruitsLiveClinicForm(id:number): Observable<boolean> {        
    return this.http.delete(`api/recruits/deleterecruitsliveclinic/${id}`)
        .map(()=>{            
            return true;
        });
} 
// Recruit Liveclinic

//Recruits Interview

onGetRecruitsInterviewbyrid(rid:number): Observable<boolean> {
        
    return this.http.get(`api/interview/getrecruitsinterviewbyrid?rid=${rid}`)
    .map( (data:any)=>{
        this.recruitsinterviewlist = data;
        return true;
    });
} 
onPostInterview(): Observable<boolean> {                    
    return this.http.post(`api/interview`, this.recruitsinterviewlist,{
        headers:new HttpHeaders()
        .set('Content-Type','application/json')
        })
        .map(()=>{            
            return true;
        });
}
onGetRecruitsInterviewformrecord(): Observable<boolean> {
        
    return this.http.get(`api/interview/getrecruitformdetail`)
    .map( (data:any)=>{
        this.recruitsinterviewformlist = data;
        return true;
    });
} 
OnGetRecruitsInterviewby(): Observable<boolean> {
    return this.http.get('api/interview/getrecruitinterviewby')
        .map( (data:any[])=>{
            this.recruitsinterviewbylist = data;
            return true;
        });
  }
//Recruits Interview

 // Recruit CMO Corner 
 onGetRecruitsCMOCornerbyrid(rid:number,uid:number): Observable<boolean> {
        
    return this.http.get(`api/recruits/getrecruitscmocornerbyrid?rid=${rid}&uid=${uid}`)
    .map( (data:any[])=>{
        this.recruitscmocornerviewlist = data;
        return true;
    });
}
onDeleteRecruitsCMOCornerForm(id:number): Observable<boolean> {        
    return this.http.delete(`api/recruits/deleterecruitscmocorner/${id}`)
        .map(()=>{            
            return true;
        });
} 
// Recruit CMO Corner 

// Recruit Exit Interview
onGetRecruitsExitInterviewbyrid(rid:number): Observable<boolean> {
        
    return this.http.get(`api/recruits/getrecruitsexitinterviewbyrid?rid=${rid}`)
    .map( (data:any)=>{
        this.recruitsexitinterviewlist = data;
        return true;
    });
}
onPostExitInterview(): Observable<boolean> {                    
    return this.http.post(`api/recruits/addrecruitsexitinterview`, this.recruitsexitinterviewlist,{
        headers:new HttpHeaders()
        .set('Content-Type','application/json')
        })
        .map(()=>{            
            return true;
        });
}

// Recruit Exit Interview
  // Recruit FTE
  onGetRecruitsFTEbyrid(rid:number): Observable<boolean> {
        
    return this.http.get(`api/recruits/getrecruitsftebyrid?rid=${rid}`)
    .map( (data:any[])=>{
        this.recruitsftelist = data;
        return true;
    });
}
onDeleteRecruitsFTEForm(id:number): Observable<boolean> {        
    return this.http.delete(`api/recruits/deleterecruitsfte/${id}`)
        .map(()=>{            
            return true;
        });
} 
onGetRecruitsFTEbyyear(year:number,rid:number): Observable<boolean> {
        
    return this.http.get(`api/recruits/getrecruitsftebyyear?Year=${year}&rid=${rid}`)
    .map( (data:any[])=>{
        this.recruitsfteyearlist = data;
        return true;
    });
}
// Recruit FTE
}