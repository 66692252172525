import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ToastrService } from 'ngx-toastr';
import {MembershipService} from './recruitsmembership-view.service'
import { HttpClient ,HttpEventType} from '@angular/common/http';
@Component({
    selector: 'app-form-recruitsmembership',
    templateUrl: './recruitsmembership-view.component.html',
    styleUrls: ['./recruitsmembership-view.component.scss'] 
  })
  export class MembershipComponent implements OnInit{
    @ViewChild('dw7') dw_7;
    Document: File = null;
    @Input() Documentvalue:string;
    public response: {'dbPath': ''};
    @Output() eventSave = new EventEmitter();
    @Output() eventClose = new EventEmitter();
    @Input() recruit_id:number;
    eventtab = {'activeId':'tab-6',
                  'nextId':'tab-7'}
                  constructor(public service:MembershipService, private toastr: ToastrService
                    ,private http: HttpClient){   
                }  
                ngOnInit(){
                this.onResetForm();
              }
              onResetForm(){
                this.response = {
                  'dbPath':''
              };
                  this.service.formData7 = {
                    id: 0,
                    recruitId: this.recruit_id,
                    membership:'',
                    refNo: '',
                    documents:'' 
                  };
            
                }
                async onSaveForm()
                {
                  if(this.Documentvalue==null || this.Documentvalue=='')
                    {
                      await this.onFileUpload();
                      this.service.formData7.documents = this.response.dbPath;
                    }
                        this.service.onPostRecruitsMembershipForm().subscribe(success =>{
                            if(success) {
                                this.toastr.success('Data saved successfully!!','Membership');
                                this.onReset();
                                this.eventSave.emit(this.eventtab);
                                this.eventClose.emit('false');
                            }
                            else
                            {
                                this.toastr.error('Error while updating Data!!', 'Membership');
                            }
                        })
                }  
         public onReset()
            {
              this.response = {
                'dbPath':''
            };
                this.dw_7.reset({
                    id: 0,
                    recruitId: this.recruit_id,
                    membership:'',
                    refNo: '',
                    documents:''      
                });
            }   
         onCancelForm(){
                  this.eventClose.emit('false'); 
        }   
        onFileSelected(event){
    
          if(event.target.files[0]!=null)
             
              
                  this.Document = event.target.files[0];
                  this.service.formData7.documents = event.target.files[0];
             
          }
        onFileUpload()
        {
            if (this.Document == null)
            {
                return this.response;
            }
            let fileToUpload = this.Document;
               const formData = new FormData();
              formData.append('Document', fileToUpload, fileToUpload.name);
                return this.http.post(`api/upload?foldername=${'Recruits'}`, formData, {observe: 'events'})
              .toPromise().then(event => {
                  if (event.type === HttpEventType.Response) {
                  this.uploadFinished(event.body);
                  }
              });
            
        }
        public uploadFinished = (event) => {
          this.response = event;
      }
      DeleteFile()
      {
         
          if (confirm('Are you sure to delete File?'))
          {
              this.service.DeleteFile(this.service.formData7.id).subscribe(success =>{
                  if(success) {
                      
                      this.toastr.info('File is Deleted','Document')
                      this.Documentvalue=null;
                    
                  }
                  else
                  {
                      this.toastr.error('Error while Updating Data!!', 'Document');
                  }
              })
          }
      }              
  }