import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitboard ,boardCertifiednamelist} from '../recruits-view.model'
@Injectable()
export class BoardCertificationService{
    public formData5: recruitboard;
public boardCertifiednamelist:boardCertifiednamelist[]=[];
    constructor(private http:HttpClient){

    }
    onGetRecruitsBoard(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsboardcertification/${id}`)
            .map( (data:recruitboard)=>{
                this.formData5 = data;
                return true;
            });
    }
    onPostRecruitsBoardForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsboardcertification`, this.formData5,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    OnBoardCertificationAlllist(): Observable<boolean> {
        return this.http.get('api/recruits/getboardcertificationalllist')
            .map( (data:any[])=>{
                this.boardCertifiednamelist = data;
                return true;
            });
      }
    DeleteFile(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/deleterecruitsboardcertificationfile/${id}`)
            .map( (data:recruitboard)=>{
                this.formData5 = data;
                return true;
            });
    }
}