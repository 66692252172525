import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitscmocorner,recruitsloyaltyscore} from '../recruits-view.model'
@Injectable()
export class CMOCornerService{
    public formData21: recruitscmocorner;
    public recruitsloyaltyscorelist:recruitsloyaltyscore[]=[];

    constructor(private http:HttpClient){

    }
    onGetRecruitsCMOCorner(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitscmocorner/${id}`)
            .map( (data:recruitscmocorner)=>{
                this.formData21 = data;
                return true;
            });
    }
    onPostRecruitsCMOCornerForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitscmocorner`, this.formData21,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    // OnGetLoyaltyScore(): Observable<boolean> {
    //     return this.http.get('api/recruits/getrecruitsloyaltyscore')
    //         .map( (data:any[])=>{
    //             this.recruitsloyaltyscorelist = data;
    //             return true;
    //         });
    //   }
    OnGetLoyaltyScore(cvtype:string): Observable<boolean> {
        return this.http.get(`api/recruits/getrecruitsloyaltyscorebycvtype?cvtype=${cvtype}`)
            .map( (data:any[])=>{
                this.recruitsloyaltyscorelist = data;
                return true;
            });
      }
   
}