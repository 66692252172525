import { Injectable } from '@angular/core';
import { HttpClient,HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import {FollowupTableView} from './followupreport.model'

@Injectable({
  providedIn: 'root'
})
export class FollowupreportService {
    public FollowupsReportlist:FollowupTableView[] = [];


  constructor(private http: HttpClient) { }


  //Get
  getFollowupsReportlist(): Observable<FollowupTableView[]>  {
    return this.http.get(`api/recruits/getfollowupreport`)
    .map( (data:any[])=>{
        this.FollowupsReportlist = data;
        return this.FollowupsReportlist;
       // return true;
    });
}
}
