import { Injectable } from '@angular/core';

import {Router,ActivatedRoute} from '@angular/router';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;
  stringToSubmit:string;
  landscape: boolean=true;
  fileName= 'ExcelSheet.xlsx';
  constructor(private router: Router,private route: ActivatedRoute) { }

  printDocument(documentName: string, documentData: string[]) {

    this.isPrinting = true;
    this.route.queryParams.subscribe(params => {
        
      this.stringToSubmit = params['CME'];
    
      
  })
 
  // console.log("CME RECORD :" +this.stringToSubmit)
    this.router.navigate(['/',
      { outlets: {
        'print': ['print', documentName, documentData.join()]
      }}],{ queryParams: { CME: this.stringToSubmit } });
  }
   
  onDataReady(landscape: boolean=false,title:string='',printformate:string='PDF',filename:string='ExcelSheet.xlsx',record:number=0,elementid:string='customer') {
    setTimeout(() => {
     

      if(record==0)
      {
        var head = document.head || document.getElementsByTagName('head')[0];

        var style = document.createElement('style');
        style.type = 'text/css';
        style.media = 'print';
        style.appendChild(document.createTextNode(landscape ?
          '@page { size:landscape; }' :
          '@page { size: A4;   }'));
      
        head.appendChild(style);
          if(printformate=='PDF')
          { 
            document.title=title;
          //  console.log(document.getElementById('print-content').innerHTML)
            window.print();}
          if(printformate=='EXCEL')
        {
          let element = document.getElementById(elementid); 
         // console.log(element)
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
    
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
        /* save to file */
        XLSX.writeFile(wb, filename);
        }
        this.isPrinting = false;
      }
    //  this.router.navigate(['/cisattendees'],{ queryParams: { CME: this.stringToSubmit } });
      this.router.navigate([{ outlets: { print: null }}],{ queryParams: { CME: this.stringToSubmit } });
    },100);

   
   
  }
}
