import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitsaffiliations} from '../recruits-view.model'
@Injectable()
export class AffiliationsService{
    public formData11: recruitsaffiliations;

    constructor(private http:HttpClient){

    }
    onGetRecruitsAffiliations(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsaffiliations/${id}`)
            .map( (data:recruitsaffiliations)=>{
                this.formData11 = data;
                return true;
            });
    }
    onPostRecruitsAffiliationsForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsaffiliations`, this.formData11,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    DeleteFile(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/deleteruitsrecruitsaffiliationsfile/${id}`)
            .map( (data:recruitsaffiliations)=>{
                this.formData11 = data;
                return true;
            });
    }
}