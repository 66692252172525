import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ToastrService } from 'ngx-toastr';
import { MedicalLicenseService } from './recruitsmedicallicense-view.service';
import {medicallicensestbl} from './recruitsmedicallicense-view.model'
import {  CommonService } from '../../services/common/common.service';
import {  States } from '../../services/common/common.model';
import { HttpClient ,HttpEventType} from '@angular/common/http';
@Component({
    selector: 'app-form-recruitsmedicallicense',
    templateUrl: './recruitsmedicallicense-view.component.html',
    styleUrls: ['./recruitsmedicallicense-view.component.scss'] 
  })
  export class MedicalLicenseComponent implements OnInit{
    @ViewChild('dw4') dw_4;
    medicallicensestbllist:medicallicensestbl[]=[];
    states:States[] = [];
    Document: File = null;
    @Input() Documentvalue:string;
    @Output() eventSave = new EventEmitter();
    @Output() eventClose = new EventEmitter();
    @Input() recruit_id:number;
    public response: {'dbPath': ''};
    eventtab = {'activeId':'tab-3',
                  'nextId':'tab-4'}
    revokedlist=[
                 {id:'Choose...',value:''},
                 {id:'Revoked',value:'Revoked'},
                 {id:'Restricted',value:'Restricted'},
                 {id:'Suspended',value:'Suspended'},
                 {id:'Lapsed',value:'Lapsed'},
               ]
    constructor(public service:MedicalLicenseService, private toastr: ToastrService,private commonService:CommonService
      ,private http: HttpClient){   
      }  
      ngOnInit(){
      this.onResetForm();
      this.service.onGetMedicalLicenses().subscribe(success =>{
        if(success) {
            this.medicallicensestbllist = this.service.medicallicensestbllist;
        }

    })
    this.commonService.getStates(231).subscribe(success =>{
      if(success) {
          this.states = this.commonService.states;
      }
  }) 
    }
    onResetForm(){
      this.response = {
        'dbPath':''
    };
        this.service.formData4 = {
        id: 0,
        recruitId: this.recruit_id,
        licenseId: 0,
        licenses: '',
        stateId: 0,
        licensesNo: '',
        expireDate:'',
        refNo: '',
        medicalDoc:'',
        licenseIssued:'',
        licenseType:'',
        revoked:'',
        comment:''
        };
  
      }
      async onSaveForm()
      {
              if(this.service.formData4.id==0)
              {
                this.service.formData4.licenses=this.medicallicensestbllist[(this.service.formData4.licenseId)-1].licenses;
              }
              
             if(this.Documentvalue==null || this.Documentvalue=='')
              {
                await this.onFileUpload();
                this.service.formData4.medicalDoc = this.response.dbPath;
              }
              
           
              this.service.onPostRecruitsMedicalLicenseForm().subscribe(success =>{
                  if(success) {
                      this.toastr.success('Data saved successfully!!','Medical License');
                      this.onReset();
                      this.eventSave.emit(this.eventtab);
                      this.eventClose.emit('false');
                  }
                  else
                  {
                      this.toastr.error('Error while updating Data!!', 'Medical License');
                  }
              })
      }  
      public onReset()
  {
    this.response = {
      'dbPath':''
  };
      this.dw_4.reset({
        id: 0,
        recruitId: this.recruit_id,
        licenseId: 0,
        licenses: '',
        stateId: 0,
        licensesNo: '',
        expireDate:'',
        refNo: '',
        medicalDoc:'',
        licenseIssued:'',
        licenseType:'' ,
        revoked:'',
        comment:''            
      });
  }   
      onCancelForm(){
        this.eventClose.emit('false'); 
      }  
      onFileSelected(event){
    
        if(event.target.files[0]!=null)
           
            
                this.Document = event.target.files[0];
                this.service.formData4.medicalDoc = event.target.files[0];
           
        }
      onFileUpload()
    {
        if (this.Document == null)
        {
            return this.response;
        }
        let fileToUpload = this.Document;
           const formData = new FormData();
          formData.append('medicalDoc', fileToUpload, fileToUpload.name);
            return this.http.post(`api/upload?foldername=${'Recruits'}`, formData, {observe: 'events'})
          .toPromise().then(event => {
              if (event.type === HttpEventType.Response) {
              this.uploadFinished(event.body);
              }
          });
        
    }
    public uploadFinished = (event) => {
      this.response = event;
  }
  DeleteFile()
{
   
    if (confirm('Are you sure to delete File?'))
    {
        this.service.DeleteFile(this.service.formData4.id).subscribe(success =>{
            if(success) {
                
                this.toastr.info('File is deleted','Medical License')
                this.Documentvalue=null;
              
            }
            else
            {
                this.toastr.error('Error while updating Data!!', 'Medical License');
            }
        })
    }
}
  }