import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import {ReportTableService } from '../../reports/view/reports-view.service';
import {supervisoryagreement} from '../../reports/view/reports-view.model' 
import {  ViewChild, ElementRef } from '@angular/core';
import {DateParaService} from'../../reports/dateparameter/dateparameter-para.service'

@Component({
    selector: 'app-form-supervisoryagreementreport',
    templateUrl: './supervisoryagreementreport.component.html',
    styleUrls: ['./supervisoryagreementreport.component.scss'] 
  })
  
  export class SupervisoryAgreementPrintComponent{
    rowIds: string[];
    rowDetails: Promise<any>[];
    fetch: any[] = [];
    rowId:number;
    sdate:string;
    tdate:string;
    print:string;
    isxls:boolean = false;
    supervisoryagreementlist:supervisoryagreement[]=[];
    constructor( private route: ActivatedRoute, private supervisoryagreementprintService:ReportTableService,
      private printService: PrintService, private dateParaService:DateParaService,
      private http: HttpClient ){
        this.rowIds = route.snapshot.params['Id']
        .split(',');
        this.rowId = +this.rowIds[0];
        this.print= String(this.rowIds[1]);
  }
  ngOnInit() {
   
console.log(this.rowIds);
   if (this.rowId==0)
   {
    this.isxls=false;
    this.supervisoryagreementprintService.getRecruitsSupervisoryAgreementList()
    .toPromise()
    .then(res => {
      this.supervisoryagreementlist = res;
      console.log(this.supervisoryagreementlist);
      // console.log("getUsersWithPromise(): " + JSON.stringify(this.supervisoryagreementlist));
     if(this.print=='PDF')
     {
      this.printService.onDataReady(true,'Clinicians (NPs/PAs) have established collaborative/supervisory agreements with which MD/DO?','PDF') 
     }
     if(this.print=='EXCEL')
     {
      this.isxls=true;
      this.printService.onDataReady(true,'Clinicians (NPs/PAs) have established collaborative/supervisory agreements with which MD/DO?','EXCEL','Medical Affairs Office.xlsx',0,'customerxls') 
     }
                    
    })
    .catch(err => { console.log(err) });

  }

// <--_________EXCEL part______-->
  // if(this.rowId==1)
  // {
  //  console.log(this.rowId);
  //   this.supervisoryagreementprintService.getRecruitsSupervisoryAgreementList()
  //   .toPromise()
  //   .then(res => {
  //     this.supervisoryagreementlist = res;
  //     if(this.print=='EXCEL')
  //     {
  //      this.printService.onDataReady(true,'Clinicians (NPs/PAs) have established collaborative/supervisory agreements with which MD/DO?','EXCEL','Medical Affairs Office.xlsx',0,'customers') 
  //     }
  //   })
  //   .catch(err => { console.log(err) });
  // }

  // <--_________EXCEL part END______-->
  if(this.rowId==1)
  {
   
    this.supervisoryagreementprintService.getRecruitsSupervisoryAgreementLisbydate(this.rowIds[1],this.rowIds[2])
    .toPromise()
    .then(res => {
      this.supervisoryagreementlist = res;
      console.log(this.supervisoryagreementlist);
      // console.log("getUsersWithPromise(): " + JSON.stringify(this.supervisoryagreementlist));
      this.printService.onDataReady(true,'Clinicians (NPs/PAs) and Physicians that have a collaborative/supervisory agreement') 
      
    })
    .catch(err => { console.log(err) });
  }
  
}


  

  }