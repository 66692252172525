import { Injectable } from '@angular/core';
import { CommonService } from '../../services';
import {cisname} from'../../services/common/common.model' 
import { Observable } from "rxjs/Rx";
import { HttpClient } from '@angular/common/http';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state?: string;
  name?: string;
  type: string;
  icon?: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
  companyid?:number
}

const MENUITEMS = [
  //comment this befor PMD user edit
  // {
  //   state: '/',
  //   name: 'Dashboard',
  //   type: 'link',
  //   icon: 'ion-ios-speedometer'
  // },
  // {
  //   state: 'access2md',
  //   name: 'Access2MD',
  //   type: 'link',
  //   icon: 'ion-ios-medical'
  // },
  // {
  //   state: 'newrecruits',
  //   name: 'Access2NewRecruits',
  //   type: 'link',
  //   icon: 'ion-ios-people'
  // },
  // {
  //   state: 'access2ancillary',
  //   name: 'Access2Ancillary',
  //   type: 'link',
  //   icon: 'ion-ios-people'
  // },  
  // {
  //   state: 'narrownetwork',
  //   name: 'EW Narrow Network',
  //   type: 'link',
  //   icon: 'ion-link'
  // },
  // {
  //   state: 'a2i',
  //   name: 'A2I/Baron',
  //   type: 'link',
  //   icon: 'ion-ios-albums'
  // },
  // {
  //   state: 'masonhealth',
  //   name: 'Mason Health',
  //   type: 'link',
  //   icon: 'ion-ios-medkit'
  // },
  // {
  //   state: 'bronson',
  //   name: 'Bronson',
  //   type: 'link',
  //   icon: 'ion-ios-box'
  // },
  // {
  //   state: 'ewnn',
  //   name: 'EWNN Followups',
  //   type: 'link',
  //   icon: 'ion-monitor'
  // },

  //new added PMD user for 04-23 2024 GJ as per AD
  {
    state: '/',
    name: 'Dashboard',
    type: 'link',
    icon: 'ion-ios-speedometer'
  },
  // {
  //   type: 'divider'
  // },
  // {
  //   type: 'title',
  //   name: 'Main'
  // },
  {
    state: 'cmocorner',
    name: 'CMO Corner',
    type: 'link',
    icon: 'ion-ios-medical'
  },
  {
    state: 'access2md',
    name: 'On Staff',
    type: 'link',
    icon: 'ion-ios-medical'
  },
  
  {
    state: 'followups',
    name: 'Followup',
    type: 'link',
    icon: 'ion-monitor' 
  },
  // {
  //   state: 'followupsdrcrumpler',
  //   name: 'Followup Dr. Crumpler',
  //   type: 'link',
  //   icon: 'ion-monitor' 
  // },
  {
    state: 'paststaff',
    name: 'Past Staff',
    type: 'link',
    icon: 'ion-ios-medical'
  },
  // {
  //   state: 'activerecruiting',
  //   name: 'Active Recruiting',
  //   type: 'link',
  //   icon: 'ion-ios-medical'
  // },
  // {
  //   state: 'newrecruits',
  //   name: 'Clinician Pool',
  //   type: 'link',
  //   icon: 'ion-ios-people'
  // },
  // {
  //   state: 'access2ancillary',
  //   name: 'Recruiting Ancillary Staff',
  //   type: 'link',
  //   icon: 'ion-ios-people'
  // },
  
  // {
  //   state: 'liveclinic',
  //   name: 'Current Recruiting Needs',
  //   type: 'link',
  //   icon: 'ion-android-home'
  // },
  
  {
    state: 'cisattendees',
    name: 'ProactiveMD Institute',
    type: 'linkdynamic',
    icon: 'ion-ios-people',
    children: [
      {
        state: 'liveclinic',
        name: 'Active Cases',
        type: 'sublinkdynamic'
      }]
  },

  
 {
    state: 'interview',
    name: 'Interview',
    type: 'link',
    icon: 'ion-ios-book-outline'
  },
  {
    state: 'cliniclocation',
    name: 'Health Center',
    type: 'link',
    icon: 'ion-android-home'
  },
  {
    state: 'reports',
    name: 'Report',
    type: 'link',
    icon: 'ion-ios-printer-outline'
  },
 
  {
    state: '',
    name: 'Internal Resources ',
    type: 'subfolderlink',
    icon: 'ion-document',
    children: [
      {
        state: 'FileServer/Document/Electronic Signature Agreement.pdf',
        name: 'eSignature Agreement'
        
      },
      {
        state: 'FileServer/Document/ElationEMR ePrescribing Authorization.pdf',
        name: 'eRX Signature Agreement'
        
      },
      {
        state: 'FileServer/Document/state-law-physician-assistant-scope-practice.pdf',
        name: 'Guidelines on Scope of Care'
        
      },
      {
        state: 'FileServer/Document/Medical Privileges Request Practitioner Name.pdf',
        name: 'Medical Privileges Request'
        
      },
      {
        state: 'FileServer/Document/Med-Peds Physician Job Description.pdf',
        name: 'Med-Peds Physician Job Desc'
        
      },
      {
        state: 'FileServer/Document/Nurse Practitioner Job Description.pdf',
        name: 'Nurse Practitioner Job Desc'
        
      },
      {
        state: 'https://portal.indianamedicaid.com/hcp/provider/Home/ProviderEnrollment/tabid/477/Default.aspx',
        name: 'OPR Application'
        
      },
      {
        state: 'FileServer/Document/01 2019-12-10_Provider Expectations.pdf',
        name: 'Provider Expectations'
        
      },
      {
        state: 'FileServer/Document/03 2019-12-10_Provider Fitness for Proactive MD Rubric.pdf',
        name: 'Provider Fitness Rubric'
        
      },
     
      {
        state: 'FileServer/Document/Provider Interview Questions 2017.pdf',
        name: 'Provider Interview Questions'
        
      } , 
      {
        state: 'https://www.aama-ntl.org/employers/state-scope-of-practice-laws',
        name: 'Scope of practice by State'
        
      }  

    ]
  },

];
const MENUITEMS2 = [
  {
    state: '/',
    name: 'Dashboard',
    type: 'link',
    icon: 'ion-ios-speedometer'
  },
  // {
  //   type: 'divider'
  // },
  // {
  //   type: 'title',
  //   name: 'Main'
  // },
  {
    state: 'cmocorner',
    name: 'CMO Corner',
    type: 'link',
    icon: 'ion-ios-medical'
  },
  {
    state: 'access2md',
    name: 'On Staff',
    type: 'link',
    icon: 'ion-ios-medical'
  },
  
  {
    state: 'followups',
    name: 'Followup',
    type: 'link',
    icon: 'ion-monitor' 
  },
  // {
  //   state: 'followupsdrcrumpler',
  //   name: 'Followup Dr. Crumpler',
  //   type: 'link',
  //   icon: 'ion-monitor' 
  // },
  {
    state: 'paststaff',
    name: 'Past Staff',
    type: 'link',
    icon: 'ion-ios-medical'
  },
  {
    state: 'activerecruiting',
    name: 'Active Recruiting',
    type: 'link',
    icon: 'ion-ios-medical'
  },
  {
    state: 'newrecruits',
    name: 'Clinician Pool',
    type: 'link',
    icon: 'ion-ios-people'
  },
  {
    state: 'access2ancillary',
    name: 'Recruiting Ancillary Staff',
    type: 'link',
    icon: 'ion-ios-people'
  },
  
  {
    state: 'liveclinic',
    name: 'Current Recruiting Needs',
    type: 'link',
    icon: 'ion-android-home'
  },
  
  {
    state: 'cisattendees',
    name: 'ProactiveMD Institute',
    type: 'linkdynamic',
    icon: 'ion-ios-people',
    children: [
      {
        state: 'liveclinic',
        name: 'Active Cases',
        type: 'sublinkdynamic'
      }]
  },

  
 {
    state: 'interview',
    name: 'Interview',
    type: 'link',
    icon: 'ion-ios-book-outline'
  },
  {
    state: 'cliniclocation',
    name: 'Health Center',
    type: 'link',
    icon: 'ion-android-home'
  },
  {
    state: 'reports',
    name: 'Report',
    type: 'link',
    icon: 'ion-ios-printer-outline'
  },
 
  {
    state: '',
    name: 'Internal Resources ',
    type: 'subfolderlink',
    icon: 'ion-document',
    children: [
      {
        state: 'FileServer/Document/Electronic Signature Agreement.pdf',
        name: 'eSignature Agreement'
        
      },
      {
        state: 'FileServer/Document/ElationEMR ePrescribing Authorization.pdf',
        name: 'eRX Signature Agreement'
        
      },
      {
        state: 'FileServer/Document/state-law-physician-assistant-scope-practice.pdf',
        name: 'Guidelines on Scope of Care'
        
      },
      {
        state: 'FileServer/Document/Medical Privileges Request Practitioner Name.pdf',
        name: 'Medical Privileges Request'
        
      },
      {
        state: 'FileServer/Document/Med-Peds Physician Job Description.pdf',
        name: 'Med-Peds Physician Job Desc'
        
      },
      {
        state: 'FileServer/Document/Nurse Practitioner Job Description.pdf',
        name: 'Nurse Practitioner Job Desc'
        
      },
      {
        state: 'https://portal.indianamedicaid.com/hcp/provider/Home/ProviderEnrollment/tabid/477/Default.aspx',
        name: 'OPR Application'
        
      },
      {
        state: 'FileServer/Document/01 2019-12-10_Provider Expectations.pdf',
        name: 'Provider Expectations'
        
      },
      {
        state: 'FileServer/Document/03 2019-12-10_Provider Fitness for Proactive MD Rubric.pdf',
        name: 'Provider Fitness Rubric'
        
      },
     
      {
        state: 'FileServer/Document/Provider Interview Questions 2017.pdf',
        name: 'Provider Interview Questions'
        
      } , 
      {
        state: 'https://www.aama-ntl.org/employers/state-scope-of-practice-laws',
        name: 'Scope of practice by State'
        
      }  

    ]
  },
  
  // {
  //   state: 'docs',
  //   name: 'Management FAQs',
  //   type: 'link',
  //   icon: 'ion-ios-help'
  // }

];
const MENUITEMS3 = [
  {
    state: '/',
    name: 'Dashboard',
    type: 'link',
    icon: 'ion-ios-speedometer'
  },
  

];

@Injectable()
export class MenuService {
  public cisnamevalue:string;
  cisnamelist:cisname[]=[];
  constructor(private http:HttpClient,private commonService:CommonService){

  }
  companyid = localStorage.getItem('companyid');
  cusertype = localStorage.getItem('sessiontype');
  getAll(): Menu[] {
    // if(this.cusertype=='2')
    // {
    //   return MENUITEMS3
    // }
    // else
    // {
      switch(this.companyid)
      {
        case '1':
            return MENUITEMS
        break
        case '2':
            return MENUITEMS2
        break
        case 'null':
            return MENUITEMS
        break
  
      }
   // }
 
   
       
  }
 getCISnamemenulist()
 {
  this.commonService.getCISNameAllList().subscribe(success =>{
    if(success) {
       this.cisnamelist=this.commonService.cisnamelist;
       this.cisnamevalue=this.commonService.cisnamelist[0].cisName;
       //console.log(this.cisnamevalue);
    }
}) 
 }
  
}

