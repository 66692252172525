import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { followupperson,followuptbl} from './recruitsewfollowups-view.model'
@Injectable()
export class EWFollowupsService{
    public formData15: followuptbl;
public followuppersonlist:followupperson[]=[];
    constructor(private http:HttpClient){

    }
    onGetRecruitsEWFollowups(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsennfollowups/${id}`)
            .map( (data:followuptbl)=>{
                this.formData15 = data;
                return true;
            });
    }
    onPostRecruitsrecruitsEWFollowupsForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsennfollowups`, this.formData15,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    onGetFollowupPerson(): Observable<boolean> {
        
        return this.http.get(`api/recruits/getfollowuppersonlist`)
        .map( (data:any[])=>{
            this.followuppersonlist = data;
            return true;
        });
    }
    
}