import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import { CMOCornerReportService } from './cmocornerreport.service';
import {RecruitstableView} from'./cmocornerreport.model';
@Component({
    selector: 'app-form-cmocornerreport',
    templateUrl: './cmocornerreport.component.html',
    styleUrls: ['./cmocornerreport.component.scss'] 
  })
  
  export class CMOCornerReportComponent{
    rowIds: string[];
    rowDetails: Promise<any>[];
    fetch: any[] = [];
    rowId:number;
    CMoCornerReportlist:RecruitstableView[]=[];
    name:string;
   reporttype:string;
   print:string;
    constructor( private route: ActivatedRoute, private cmocornerprintService:CMOCornerReportService,
        private printService: PrintService,
        private http: HttpClient ){
          this.rowIds = route.snapshot.params['Id']
          .split(',');
          this.rowId = +this.rowIds[0];
          this.reporttype=this.rowIds[2];
          this.print=this.rowIds[1];
        
    }
    ngOnInit() {
         this.cmocornerprintService.getCMOCornerReportlist()
         .toPromise()
         .then(res => {
           this.CMoCornerReportlist = res;
           if(this.print=='PDF')
           {
            this.printService.onDataReady(true,this.reporttype,'PDF') 
          
           }
           if(this.print=='EXCEL')
           {
            this.printService.onDataReady(true,this.reporttype,'EXCEL',this.reporttype+'.xlsx',0,'cmotable') 
            
           }
        
      
          
          
           
         })
         .catch(err => { console.log(err) });
     
    
      
     }
  }