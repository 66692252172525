import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import { RecruitsEWNNScorePrintService } from './ewnnscorereport.service';
import {ewnnscore} from'./ewnnscorereport.model';
@Component({
    selector: 'app-form-ewnnscorereport',
    templateUrl: './ewnnscorereport.component.html',
    styleUrls: ['./ewnnscorereport.component.scss'] 
  })
  export class RecruitsEWNNScorePrintComponent{
    rowIds: string[];
    rowDetails: Promise<any>[];
    fetch: any[] = [];
    rowId:number;
    ewnnscorelist:ewnnscore[]=[];
    name:string;
   
    constructor( private route: ActivatedRoute, private RecruitsewnnscorePrintService:RecruitsEWNNScorePrintService,
        private printService: PrintService,
        private http: HttpClient ){
          this.rowIds = route.snapshot.params['Id']
          .split(',');
          this.rowId = +this.rowIds[0];
        
    }
    ngOnInit() {
   

       
         
         this.RecruitsewnnscorePrintService.getRecruitsEWNNSCorelist()
         .toPromise()
         .then(res => {
           this.ewnnscorelist = res;
          
           this.printService.onDataReady(true,'Experts by Speciality','EXCEL','Experts by Speciality.xlsx',0,'ewnnscore')
    // this.printService.onDataReady(true,'Experts by Speciality') 
        
          
          
           
         })
         .catch(err => { console.log(err) });
     
    
      
     }
  }