import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import {mediclalicensesbystate,supervisoryagreement,employmentstatus,printformatpara,uniquecodereport, supervisoryphysicians, ClinicalLeadersTeams} from './reports-view.model';
@Injectable()
export class ReportTableService{

    public dw: mediclalicensesbystate[] = [];
    public supervisoryagreementlist:supervisoryagreement[]=[];
    public employmentstatuslist: employmentstatus[]=[];
    public formData:printformatpara;
    public uniquecodereport:uniquecodereport[]=[];
    supervisoryphysicianslist: supervisoryphysicians[]=[];
    public clinicalleadersteams: ClinicalLeadersTeams[] = [];
    constructor(private http: HttpClient) { }
    
    //Get
    getRecruitsMediclalicensesbystate(sid:Number): Observable<HttpResponse<mediclalicensesbystate[]>> {
        return this.http.get<mediclalicensesbystate[]>(`api/recruits/getrecruitsmedicallicensesbysid?sid=${sid}`
        , { observe: 'response' });
    }    
   
    getRecruitsSupervisoryAgreementList(): Observable<supervisoryagreement[]> {
        
        return this.http.get(`api/recruits/getsupervisoryagreementlist`)
        .map( (data:any[])=>{
            this.supervisoryagreementlist = data;
            return this.supervisoryagreementlist;
           // return true;
        });
    } 
    getRecruitsSupervisoryAgreementLisbydate(sdate:string,edate:string): Observable<supervisoryagreement[]> {
        
        return this.http.get(`api/recruits/getsupervisoryagreementlistbydate?sdate=${sdate}&edate=${edate}`)
        .map( (data:any[])=>{
            this.supervisoryagreementlist = data;
            return this.supervisoryagreementlist;
           // return true;
        });
    } 
    getRecruitsMedicalLicenseslid(): Observable<mediclalicensesbystate[]> {
        
        return this.http.get(`api/recruits/getrecruitsmedicallicensesbylid`)
        .map( (data:any[])=>{
            this.dw = data;
            return this.dw;
           // return true;
        });
    }
    getuniquecodereportlist(): Observable<uniquecodereport[]> {
        
        return this.http.get(`api/recruits/getuniquecodereport`)
        .map( (data:any[])=>{
            this.uniquecodereport = data;
            return this.uniquecodereport;
           // return true;
        });
    }
    // getemploymentstatus(id:number): Observable<employmentstatus[]> {
        
    //     return this.http.get(`api/recruits/getemploymentstatus`)
    //     .map( (data:any[])=>{
    //         this.employmentstatuslist = data;
    //         return this.employmentstatuslist;
    //        // return true;
    //     });
    // }
    getRecruitsSupervisoryPhysiciansList(): Observable<supervisoryphysicians[]> {
        
        return this.http.get(`api/recruits/getsupervisoryagreementlist`)
        .map( (data:any[])=>{
            this.supervisoryphysicianslist = data;
            return this.supervisoryphysicianslist;
           // return true;
        });
    }
    
    //reprt 12 GJ 04042024 
    getClinicalLeadershipTeamsList(): Observable<ClinicalLeadersTeams[]> {
        
        return this.http.get(`api/recruits/getleadershipteamslist`)
        .map( (data:any[])=>{
            this.clinicalleadersteams = data;
            return this.clinicalleadersteams;
           // return true;
        });
    }     

}