import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import {employmentstatus,locationpara  } from './locationparameter-para.model'
import{RecruitsClinicLocation} from '../../liveclinic/shared/liveclinic-form.model'

@Injectable()

export class LocationParaService{
    public formData:locationpara;
    public formData1:RecruitsClinicLocation;
    public employmentstatuslist: employmentstatus[] = [];
    constructor(private http: HttpClient) { }
 
    getemploymentstatus(id:number): Observable<employmentstatus[]> {
        
        return this.http.get(`api/recruits/getemploymentstatus?id=${id}`)
        .map( (data:any[])=>{
            this.employmentstatuslist = data;
            return this.employmentstatuslist;
            //return true;
        });
    }  
}