import { Component, OnInit } from '@angular/core'
import { Pipe, PipeTransform } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import { RecruitsMultipleliveclinicPrintService } from './multipleliveclinicreport.service';
import {multipleliveclinic,GroupedData,separatemutlipleliveclinic} from'./multipleliveclinicreport.model';
@Component({
    selector: 'app-form-multipleliveclinicreport',
    templateUrl: './multipleliveclinicreport.component.html',
    styleUrls: ['./multipleliveclinicreport.component.scss'] 
  })
  export class RecruitsMultipleliveclinicPrintComponent{
    rowIds: string[];
    rowDetails: Promise<any>[];
    fetch: any[] = [];
    rowId:number;
    multilevel:boolean=false;
    multipleliveclinictlist:multipleliveclinic[]=[];
    separatemutliplelivecliniclist:separatemutlipleliveclinic[]=[];
    dataExt: any[] = [];
    sdataExt: any[] = [];
    name:string;
    print:string;
    constructor( private route: ActivatedRoute, private recruitsMultipleliveclinicPrintService:RecruitsMultipleliveclinicPrintService,
        private printService: PrintService,
        private http: HttpClient ){
          this.rowIds = route.snapshot.params['Id']
          .split(',');
          this.rowId = +this.rowIds[0];
          this.print=this.rowIds[1];
    }
    ngOnInit() {
      if(this.rowId==0)
      {
        this.recruitsMultipleliveclinicPrintService.getRecruitsMultiplelivecliniclist()
        .toPromise()
        .then(res => {
          this.multipleliveclinictlist = res;
          this.multilevel=true;
          this.processData();
        
          if(this.print=='PDF')
          {
           this.printService.onDataReady(false,'List of Clinicians and their regions') 
         
          }
          if(this.print=='EXCEL')
          {
           this.printService.onDataReady(true,'List of Clinicians and their regions','EXCEL','List of Clinicians and their regions.xlsx',0,'customers') 
           
          }
       
         
         
          
        })
        .catch(err => { console.log(err) });
      }
      else
      {
        this.recruitsMultipleliveclinicPrintService.getRecruitsSeparateMultiplelivecliniclist()
        .toPromise()
        .then(res => {
          this.separatemutliplelivecliniclist = res;
          this.multilevel=false;
          this.seprateprocessData();
          if(this.print=='PDF')
           {
            this.printService.onDataReady(false,'Dr Sandra L. Daily report') 
          
           }
           if(this.print=='EXCEL')
           {
            this.printService.onDataReady(true,'Dr Sandra L. Daily report','EXCEL','Dr Sandra L. Daily report.xlsx',0,'customers') 
            
           }
        
       
         
         
          
        })
        .catch(err => { console.log(err) });
      }
     
     
    
      
     }
     private seprateprocessData()
     {
      const sfirstnameSeen = {};
      const sstatusSeen = {};
      this.sdataExt = this.separatemutliplelivecliniclist.sort((a, b) => {
        const firstnameComp = a.firstName.localeCompare(b.firstName);
        return firstnameComp ? firstnameComp : a.statusName.localeCompare(b.statusName) 

        
      }).map(x => {
        const sfirstnameSpan = sfirstnameSeen[x.firstName] ? 0 :
          this.separatemutliplelivecliniclist.filter(y => y.firstName === x.firstName).length;
  
          sfirstnameSeen[x.firstName] = true;
         
  
        const sstatusnameSpan = sstatusSeen[x.firstName] && sstatusSeen[x.firstName][x.statusName] ? 0 :
          this.separatemutliplelivecliniclist.filter(y => y.firstName === x.firstName && y.statusName === x.statusName).length;
  
          sstatusSeen[x.firstName] = sstatusnameSpan[x.statusName] || {};
          sstatusSeen[x.firstName][x.statusName] = true;
  

          // const degreeSpan = degreeSeen[x.firstName] && degreeSeen[x.firstName][x.lastName] && degreeSeen[x.firstName][x.lastName][x.degree] ? 0 :
          // this.multipleliveclinictlist.filter(y => y.firstName === x.firstName && y.lastName === x.lastName && y.degree === x.degree).length;
  
          // degreeSeen[x.firstName] = degreeSeen[x.lastName] || {};
          // degreeSeen[x.firstName][x.lastName][x.degree] = true;
  

        return { ...x, sfirstnameSpan, sstatusnameSpan };
      });
     }
     private processData() {
      const firstnameSeen = {};
      const lastnameSeen = {};
      const degreeSeen = {};
      const cellSeen = {};
      this.dataExt = this.multipleliveclinictlist.sort((a, b) => {
        const firstnameComp = a.firstName.localeCompare(b.firstName);
        return firstnameComp ? firstnameComp : a.lastName.localeCompare(b.lastName) 
        // , a.degree.localeCompare(b.degree);
      }).map(x => {
        const firstnameSpan = firstnameSeen[x.firstName] ? 0 :
          this.multipleliveclinictlist.filter(y => y.firstName === x.firstName).length;
  
          firstnameSeen[x.firstName] = true;
         
  
        const lastnameSpan = lastnameSeen[x.firstName] && lastnameSeen[x.firstName][x.lastName] ? 0 :
          this.multipleliveclinictlist.filter(y => y.firstName === x.firstName && y.lastName === x.lastName).length;
  
          lastnameSeen[x.firstName] = lastnameSpan[x.lastName] || {};
          lastnameSeen[x.firstName][x.lastName] = true;
  

          // const degreeSpan = degreeSeen[x.firstName] && degreeSeen[x.firstName][x.lastName] && degreeSeen[x.firstName][x.lastName][x.degree] ? 0 :
          // this.multipleliveclinictlist.filter(y => y.firstName === x.firstName && y.lastName === x.lastName && y.degree === x.degree).length;
  
          // degreeSeen[x.firstName] = degreeSeen[x.lastName] || {};
          // degreeSeen[x.firstName][x.lastName][x.degree] = true;
  

        return { ...x, firstnameSpan, lastnameSpan };
      });
    }  }


//   @Pipe({name: 'groupByEmail'})
// export class GroupByEmailPipe implements PipeTransform {
//   transform(array: any[]): GroupedData[] {
//     const dataByUser: {[key:string]: GroupedData} = {};
//     for(const item of array) {
//       if(!dataByUser.hasOwnProperty(item.recruits_id)) {
//         dataByUser[item.recruits_id] = {
//           recruits_id:item.recruits_id,
//           firstName: item.firstName,
//           lastName: item.lastName,
//           degree:  item.degree,
//           alternate_Name: item.alternate_Name,
//           cell:  item.cell,
//           statusName: [],
//           clinic1: [],
//           location1:[],
//           clinic2: [],
//           location2:[],
//           clinic3:[],
//           location3:[],
         
         
//         };
//       }

//       dataByUser[item.recruits_id].statusName.push(item.statusName ? item.statusName : '');

//       // for simplicity, I'm considering that none of the following values can be zero
//       dataByUser[item.recruits_id].clinic1.push(item.clinic1 ? item.clinic1 : '');
//       dataByUser[item.recruits_id].location1.push(item.location1 ? item.location1 : '');

//       dataByUser[item.recruits_id].clinic2.push(item.clinic2 ? item.clinic2 : '');
//       dataByUser[item.recruits_id].location2.push(item.location2 ? item.location2 : '');

//       dataByUser[item.recruits_id].clinic3.push(item.clinic3 ? item.clinic3 : '');
//       dataByUser[item.recruits_id].location3.push(item.location3 ? item.location3 : '');
//     }

//     return Object.keys(dataByUser).map(key => dataByUser[key]);
//   }
// }