import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import{InterviewFormService} from '../../interview/shared/interview-form.service'
import { from } from 'rxjs';
@Component({
    selector: 'app-form-firstlevelsurveyreport',
    templateUrl: './firstlevelsurveyreport.component.html',
    styleUrls: ['./firstlevelsurveyreport.component.scss'] 
  })
  
  export class FirtsLevelSurvayReportComponent{
    rowIds: string[];
    rowDetails: Promise<any>[];
    rowId:number;
    name:string;
    interviewby:string;
    interviewdate:string;
    totalScoreper:string;
    totalScore:Number;
    constructor( private route: ActivatedRoute, private interviewFormService:InterviewFormService,
      private printService: PrintService, private http: HttpClient ){
        this.rowIds = route.snapshot.params['Id']
        .split(',');
        this.rowId = +this.rowIds[0];
  }
  ngOnInit() {
    this.interviewFormService.OnGetRecruitsFirstlevellistbyid(this.rowId).subscribe(success =>{
      if(success) {
        this.interviewFormService.RecruitsFirstLevelDetailByIDlist=  this.interviewFormService.RecruitsFirstLevelDetailByIDlist;
         Promise.all([this.interviewFormService.RecruitsFirstLevelDetailByIDlist])
        .then((values ) => 
        {
      
         this.name=this.interviewFormService.RecruitsFirstLevelDetailByIDlist[0].firstName+' '
         +this.interviewFormService.RecruitsFirstLevelDetailByIDlist[0].lastName+', '+this.interviewFormService.RecruitsFirstLevelDetailByIDlist[0].post;
          this.interviewby=this.interviewFormService.RecruitsFirstLevelDetailByIDlist[0].interview_by;
          this.interviewdate=this.interviewFormService.RecruitsFirstLevelDetailByIDlist[0].interview_date;
          this.totalScoreper=this.interviewFormService.RecruitsFirstLevelDetailByIDlist[0].totalScoreper;
         this.totalScore=Number(this.interviewFormService.RecruitsFirstLevelDetailByIDlist[0].totalScore);
          this.printService.onDataReady(true,'Interview')
        });
    
     
      } 
    });  
  }
  }