import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitsreferences} from '../recruits-view.model'
@Injectable()
export class ReferencesService{
    public formData13: recruitsreferences;

    constructor(private http:HttpClient){

    }
    onGetRecruitsReferences(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsreferences/${id}`)
            .map( (data:recruitsreferences)=>{
                this.formData13 = data;
                return true;
            });
    }
    onPostRecruitsrecruitsReferencesForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsreferences`, this.formData13,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    DeleteFile(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/deleteruitsrecruitsreferencesfile/${id}`)
            .map( (data:recruitsreferences)=>{
                this.formData13 = data;
                return true;
            });
    }
}