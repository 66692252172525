import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ToastrService } from 'ngx-toastr';
import { LiveClinicFormService} from '../../liveclinic/shared/liveclinic-form.service';
import{RecruitsList,RecruitsClinicLocation,ClinicianRegionList} from '../../liveclinic/shared/liveclinic-form.model'
import { CommonService } from '../../services/common/common.service'
import { HttpClient ,HttpEventType} from '@angular/common/http';
import { Country, States, Cities,TimeZone } from '../../services/common/common.model';
import { MaskComponent } from '../../services/common/masks.pipe'
import {RecruitsviewService} from '../../recruits/recruits-view.service'
@Component({
    selector: 'app-form-clinicliveclinicform',
    templateUrl: './recruitscliniclocation-view.component.html',
    styleUrls: ['./recruitscliniclocation-view.component.scss'] 
  })
  export class RecruitsLiveClinicFormComponent
  {
    @ViewChild('dw19') dw_19;
    Document: File = null;
    @Input() Documentvalue:string;
    public response: {'dbPath': ''};
    @Output() eventSave = new EventEmitter();
    @Output() eventClose = new EventEmitter();
    @Input() recruit_id:number;
    @Input() iseditcliniclocation:number;
    eventtab = {'activeId':'tab-18',
    'nextId':'tab-19'}
    userid = localStorage.getItem('userid');
    states:States[] = [];
    supervisoryMds:RecruitsList[]=[];
    clinicname:RecruitsClinicLocation[]=[];
    ClinicRegion:ClinicianRegionList[]=[];
    @Input() cities:Cities[] = [];
    dateNow : Date = new Date();
    public mask=this.maskservice.mask;
    // Degreeslist=[
    //   {id:'Choose...',value:''}, 
    //   {id:'NPS/PI',value:'NPS/PI'},
    //   {id:'MD/DO',value:'MD/DO'} 
      
    // ]
    Degreeslist = [
      { id: 'Choose...', value: '' },
      { id: 'MD', value: 'MD' },
      { id: 'DO', value: 'DO' },
      { id: 'PA', value: 'PA' },
      { id: 'NP', value: 'NP' }
       ];
    Statuslist=[
      {id:'Choose...',value:''}, 
      {id:'FTE',value:'FTE'},
      {id:'PRN',value:'PRN'} ,
      {id:'Part Time',value:'Part Time'},
      {id:'Float',value:'Float'},
      {id:'FTE – Traveling',value:'FTE – Traveling'},
      {id:'Retain current',value:'Retain current'},
      {id:'Locums',value:'Locums'},
      {id:'Contract',value:'Contract'}
       
    ]
    constructor(public service:LiveClinicFormService, private toastr: ToastrService,private commonService:CommonService
      ,private http: HttpClient,
      private maskservice:MaskComponent
      ,private recruitsviewService:RecruitsviewService){   
      }  
      ngOnInit(){
      this.onResetForm();
      this.commonService.getStates(231).subscribe(success =>{
        if(success) {
            this.states = this.commonService.states;
        }
    })   
     this.commonService.getCities(this.service.Recruitsliveclinictbllist.stateId).subscribe(success =>{
      if(success) {
          this.cities = this.commonService.cities;
        }
      })
      this.service.onGetRecruitlist().subscribe(success =>{
        if(success) {
            this.supervisoryMds = this.service.RecruitsMDLists;
        }
    }) 
    this.service.onGetRecruitscliniclocationlist().subscribe(success =>{
      if(success) {
          this.clinicname = this.service.RecruitsClinicLocationlist;
      }
  }) 
  this.service.onGetRecruitsclinicregionlist().subscribe(success =>{
    if(success) {
        this.ClinicRegion = this.service.ClinicianregionList;
    }
}) 
    this.recruitsviewService.onGetRecruits(this.recruit_id).subscribe(success =>{
      if(success) {
        this.service.Recruitsliveclinictbllist.firstName=this.recruitsviewService.recruitview.firstName;
        this.service.Recruitsliveclinictbllist.middleName=this.recruitsviewService.recruitview.middleName;
        this.service.Recruitsliveclinictbllist.lastName=this.recruitsviewService.recruitview.lastName;
         this.service.Recruitsliveclinictbllist.degree=this.recruitsviewService.recruitview.degree;
         this.service.Recruitsliveclinictbllist.recruitId=this.recruitsviewService.recruitview.id;
        this.service.Recruitsliveclinictbllist.primaryContact=this.recruitsviewService.recruitview.primaryContact;
        this.service.Recruitsliveclinictbllist.primaryEmail=this.recruitsviewService.recruitview.primaryEmail;
    
      }
  }) 
    }
    async onSaveForm()
    {
      this.service.onPostRecruitsLiveclinicForm().subscribe(success =>{
        if(success) {
            this.toastr.success('Data saved successfully!!','Current Recruiting Needs');
            this.onReset();
            this.eventSave.emit(this.eventtab);
            this.eventClose.emit('false');
        }
        else
        {
            this.toastr.error('Error while updating Data!!', 'Current Recruiting Needs');
        }
    })
    }
    onDeleteForm()
    {
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.DeleteLiveclinic(this.service.Recruitsliveclinictbllist.id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','Current Recruiting Needs')
                    
                    this.onReset();
                    this.eventSave.emit(this.eventtab);
                    this.eventClose.emit('false');
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'Current Recruiting Needs');
                }
            })
        }
    }
    public onReset(){
      this.dw_19.reset({
        id: 0,
        recruitId: this.recruit_id,
        startDate: '',
        supervisoryMd:0,
        firstName: '',
        middleName:'',
        lastName:'',
        cityId:0,
        stateId:0,
        primaryContact:'',
        primaryEmail:'',
        degree:'',
        clinicId:0,
        active:true,
        updatedDate:this.dateNow,
        updatedby:parseInt(this.userid),
        comment:'',
        status:'',
        clinicRegionId:0,
        coLeader:false
      });

    }
    onResetForm(){
     
        this.service.Recruitsliveclinictbllist = {
          id: 0,
          recruitId: this.recruit_id,
          startDate: '',
          supervisoryMd:0,
          firstName: '',
          middleName:'',
          lastName:'',
          cityId:0,
          stateId:0,
          primaryContact:'',
          primaryEmail:'',
          degree:'',
          clinicId:0,
          active:true,
          updatedDate:this.dateNow,
          updatedby:parseInt(this.userid),
          comment:'',
          status:'',
          clinicRegionId:0,
          coLeader:false
        };
  
      }
      onCancelForm(){
        this.eventClose.emit('false'); 
      } 
     
      onStateChange(id:number)
      {
          this.service.Recruitsliveclinictbllist.cityId=null; 
          this.commonService.getCities(id).subscribe(success =>{
              if(success) {
                  this.cities = this.commonService.cities;
              }
          })      
      }
      onClinicChange(id:number)
      {
        this.service.onGetRecruitsCliniclOcationbyid(id).subscribe(success =>{
          if(success) {
            this.service.Recruitsliveclinictbllist.stateId=  this.service.RecruitsClinicLocationtbl.stateId;
           
            this.commonService.getCities(this.service.Recruitsliveclinictbllist.stateId).subscribe(success =>{
              if(success) {
                  this.cities = this.commonService.cities;
              }
          })
          this.service.Recruitsliveclinictbllist.cityId=  this.service.RecruitsClinicLocationtbl.cityId; 
          }
      })  
      }
     
      onCopyFirm(event){
        if (this.service.Recruitsliveclinictbllist.recruitId == null)
        {
            return;
        }
        if (event==true){
          this.recruitsviewService.onGetRecruits(this.service.Recruitsliveclinictbllist.recruitId).subscribe(success =>{
            if(success) {
              this.service.Recruitsliveclinictbllist.firstName=this.recruitsviewService.recruitview.firstName;
              this.service.Recruitsliveclinictbllist.middleName=this.recruitsviewService.recruitview.middleName;
              this.service.Recruitsliveclinictbllist.lastName=this.recruitsviewService.recruitview.lastName;
              this.service.Recruitsliveclinictbllist.primaryContact=this.recruitsviewService.recruitview.primaryContact;
              this.service.Recruitsliveclinictbllist.primaryEmail=this.recruitsviewService.recruitview.primaryEmail;

            }
          })
        }
      }
  }
