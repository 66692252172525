import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ToastrService } from 'ngx-toastr';
import { MalpracticeService } from './recruitsmalpractice-view.service';

@Component({
    selector: 'app-form-recruitsmalpractice',
    templateUrl: './recruitsmalpractice-view.component.html',
    styleUrls: ['./recruitsmalpractice-view.component.scss'] 
  })
  export class MalpracticeComponent implements OnInit{
    @ViewChild('dw14') dw_14;
   
    @Output() eventSave = new EventEmitter();
    @Output() eventClose = new EventEmitter();
    @Input() recruit_id:number;
  
    eventtab = {'activeId':'tab-13',
                  'nextId':'tab-14'}
    constructor(public service:MalpracticeService, private toastr: ToastrService){   
      }  
      ngOnInit(){
      this.onResetForm();
    }
    onResetForm(){
        this.service.formData14 = {
          id: 0,
          recruitId: this.recruit_id,
          patientName: '',
          caseDate:'',
          caseSummary: '',
          caseOutcome: ''
        
        };
  
      }
      async onSaveForm()
      {
              this.service.onPostRecruitsrecruitsmalpracticeForm().subscribe(success =>{
                  if(success) {
                      this.toastr.success('Data saved successfully!!','Malpractice');
                      this.onReset();
                      this.eventSave.emit(this.eventtab);
                      this.eventClose.emit('false');
                  }
                  else
                  {
                      this.toastr.error('Error while updating Data!!', 'Malpractice');
                  }
              })
      }  
      public onReset()
  {
 
      this.dw_14.reset({
        id: 0,
        recruitId: this.recruit_id,
        patientName: '',
        caseDate:'',
        caseSummary: '',
        caseOutcome: ''        
      });
  }   
      onCancelForm(){
        this.eventClose.emit('false'); 
      }  
  }