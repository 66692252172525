import { Injectable } from '@angular/core';
import { HttpClient,HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import {onstaffcontactlist} from './onstaffcontactreport.model'
@Injectable()
export class RecruitsOnstaffPrintService{
    public recruitsonstaffcontactlist:onstaffcontactlist[] = [];
    
   
  
    constructor(private http: HttpClient) { }
    
    //Get
    getRecruitsOnstafflist(): Observable<onstaffcontactlist[]>  {
        return this.http.get(`api/recruits/getrecruitsonstafflist`)
        .map( (data:any[])=>{
            this.recruitsonstaffcontactlist = data;
            return this.recruitsonstaffcontactlist;
           // return true;
        });
    }
  
}