import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ToastrService } from 'ngx-toastr';
import {OtherDetailsService} from './recruitsotherdetails-view.service'
import {recruitsotherdetails} from '../recruits-view.model'
import {RecruitsviewService} from '../recruits-view.service'
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms'
import {pairwise, startWith} from 'rxjs/operators';
import { HttpClient ,HttpEventType} from '@angular/common/http';
import { CommonService } from '../../services/common/common.service'
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-form-recruitsotherdetails',
    templateUrl: './recruitsotherdetails-view.component.html',
    styleUrls: ['./recruitsotherdetails-view.component.scss'] 
  })
  export class OtherDetailsComponent implements OnInit{
  
    public recruitsotherdetailstable:recruitsotherdetails[]=[];
    public data:recruitsotherdetails;
   public prevvalue:any[]=[];
    public myevent:string = '';
    Document: File = null;
    isDisabled:boolean=false;
    dw2: FormGroup;
    @Output() eventSave = new EventEmitter();
    @Output() eventClose = new EventEmitter();
    
    @Input() recruit_id:number;
    public myFormValueChanges$;
    @Input() Documentvalue:string;
    @Input() columndatatype:string;
    @Input() tabname:string;
    public response: {'dbPath': ''};
    @ViewChild('dw2') dw_2;
    public eventtab:{};
    constructor(public service:OtherDetailsService,private commonService:CommonService, private toastr: ToastrService,
      private fb: FormBuilder,private recruitsviewService:RecruitsviewService,private datepipe:DatePipe
      ,private http: HttpClient){   
    }  
    ngOnInit(){
   
      if (this.tabname =='tab-2')
      {
        this.eventtab = {'activeId':'tab-1',
        'nextId':'tab-2'}
      }
      if (this.tabname =='tab-22')
      {
        this.eventtab = {'activeId':'tab-2',
        'nextId':'tab-22'}
      }
   
    this.onResetForm();
    //console.log("value 1    "+ this.service.formdataview.columnDatatype);
    // this.dw2 = this.fb.group({
    //   otherdetailstable: this.fb.array([])
    // }
    // );
    // this.myFormValueChanges$ = this.dw2.controls['otherdetailstable'].valueChanges
    // .pipe(startWith(null), pairwise());  
    
  }
  // updateTable(prevotherdtl: any, otherdtl: any) {
  //   const control = <FormArray>this.dw2.controls['otherdetailstable'];
    
  // }
  onResetForm(){
    this.response = {
      'dbPath':''
  };
      this.service.formData2 = {
        id: 0,
        recruitId: this.recruit_id,
        columnsId: 0,
        columnName: '',
        columnValue: '',
        refNo: '',
        documents:'',
        columnDatatype:''
      };
     
     
    }
    onFileSelected(event){
    
      if(event.target.files[0]!=null)
         
          
              this.Document = event.target.files[0];
              this.service.formData2.documents = event.target.files[0];
         
      }
    onFileUpload()
  {
      if (this.Document == null)
      {
          return this.response;
      }
      let fileToUpload = this.Document;
         const formData = new FormData();
        formData.append('Document', fileToUpload, fileToUpload.name);
          return this.http.post(`api/upload?foldername=${'Recruits'}`, formData, {observe: 'events'})
        .toPromise().then(event => {
            if (event.type === HttpEventType.Response) {
            this.uploadFinished(event.body);
            }
        });
      
  }
  public uploadFinished = (event) => {
    this.response = event;
}
 async onSaveForm()
      {
             
        this.isDisabled=true; 
             if(this.Documentvalue==null || this.Documentvalue=='')
              {
                await this.onFileUpload();
                this.service.formData2.documents = this.response.dbPath;
              }
              if(this.service.formData2.columnDatatype=='date')
              {
                
                this.service.formData2.columnValue=this.datepipe.transform(this.service.formData2.columnValue, 'MM/dd/yyyy')
               
              }
           
              this.service.onPostRecruitsOtherDetailsForm(this.service.formData2).subscribe(success =>{
                  if(success) {
                      this.toastr.success('Data saved successfully!!','');
                    
                      this.eventSave.emit(this.eventtab);
                      this.eventClose.emit('false');
                  }
                  else
                  {
                      this.toastr.error('Error while updating Data!!', '');
                  }
              })
      }  
      public onReset()
      {
        this.response = {
          'dbPath':''
      };
          this.dw_2.reset({
            id: 0,
        recruitId: this.recruit_id,
        columnsId: 0,
        columnName: '',
        columnValue: '',
        refNo: '',
        documents:''              
          });
      }  
  // initiatForm(): FormGroup {
  //   return this.fb.group({
  //     id: [0],
  //     recruitId: [this.recruit_id],
  //     columnsId: [0],
  //     columnName: [null],
  //     columnValue: [null],
  //     refNo:[null],
  //     documents:[null]
          
  //   });
  // }
      
  // async onSaveForm(i:number)
  // {

  //    const control = <FormArray>this.dw2.controls['otherdetailstable'];
  //    this.data = {
  //     id: control.at(+i).get('id').value,
  //     recruitId: control.at(+i).get('recruitId').value,
  //     columnsId: control.at(+i).get('columnsId').value,
  //     columnName: control.at(+i).get('columnName').value,
  //     columnValue: control.at(+i).get('columnValue').value,
  //     refNo: control.at(+i).get('refNo').value,
  //     documents:control.at(+i).get('documents').value
  //   }
  //         this.service.onPostRecruitsOtherDetailsForm(this.data).subscribe(success =>{
  //             if(success) {
  //                 //this.toastr.success('Data saved successfully!!','Other Information');
  //             }
  //             else
  //             {
  //                 this.toastr.error('Error while updating Data!!', 'Other Information');
  //             }
  //         })
  // } 
  // onAfterDataSave(id:number)
  // {
  //   this.recruitsviewService.onGetRecruitsOtherDetailsbyrid(id).subscribe(success => {
  //     if (success) {
  //       this.recruitsviewService.recruitsotherdetailslist=this.recruitsviewService.recruitsotherdetailslist;
  //       if(this.recruitsviewService.recruitsotherdetailslist.length>0)
  //       {
  //         this.recruitsotherdetailstable= this.recruitsviewService.recruitsotherdetailslist;
  //         if (this.recruitsotherdetailstable.length > 0){
  //           let recruitsotherdetailstbl = this.recruitsotherdetailstable;
  //           this.dw2.patchValue({'otherdetailstable':recruitsotherdetailstbl}) 
  //         }
  //       }
  //       else
  //             {
  //                 this.recruitsotherdetailstable = null;
  //             } 
  //     }
  //   });
  // } 
  // async onOtherRetrieve()
  // {
  //   const control = <FormArray>this.dw2.get('otherdetailstable');
  
  //   if (this.recruitsotherdetailstable!= null)
  //   {
  //     if (this.recruitsotherdetailstable.length > 0)
  //     {
  //       let otherdetailmodel = this.recruitsotherdetailstable;
       
  //       for (let i = 0; i < this.recruitsotherdetailstable.length; i++) {
          
        
  //         control.push(this.initiatForm());
  //       }
  //       this.dw2.patchValue({'otherdetailstable':otherdetailmodel}, { emitEvent: false })          
  //     }
  //   }else
  //   {
  //     this.clearAll();
  //   }      
  // }
  // clearAll() {
  //   const control = <FormArray>this.dw2.controls['otherdetailstable'];
  //   while(control.length) {
  //     control.removeAt(control.length - 1);
  //   }
  //   control.clearValidators();
  //   //control.push(this.initiatForm());
  // }
  onCancelForm(){
          this.eventClose.emit('false'); 
  }     
  DeleteFile()
  {
     
      if (confirm('Are you sure to delete File?'))
      {
          this.service.DeleteFile(this.service.formData2.id).subscribe(success =>{
            console.log(this.service.formData2.id);
              if(success) {
                  
                  this.toastr.info('File is Deleted','Document')
                  this.Documentvalue=null;
                
              }
              else
              {
                  this.toastr.error('Error while Updating Data!!', 'Document');
              }
          })
      }
  }           
}