import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitsfellowship} from '../recruits-view.model'
@Injectable()
export class FellowshipService{
    public formData8: recruitsfellowship;

    constructor(private http:HttpClient){

    }
    onGetRecruitsFellowship(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsfellowship/${id}`)
            .map( (data:recruitsfellowship)=>{
                this.formData8 = data;
                return true;
            });
    }
    onPostRecruitsFellowshipForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsfellowship`, this.formData8,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    DeleteFile(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/deleterecruitsfellowshipfile/${id}`)
            .map( (data:recruitsfellowship)=>{
                this.formData8 = data;
                return true;
            });
    }
}