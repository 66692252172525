import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import { CMOCornerLoyaltyscorePrintService } from './cmocornerloyaltyscorereport.service';
import {cmocornerloyaltyscore} from'./cmocornerloyaltyscorereport.model';
@Component({
    selector: 'app-form-cmocornerloyaltyscorereport',
    templateUrl: './cmocornerloyaltyscorereport.component.html',
    styleUrls: ['./cmocornerloyaltyscorereport.component.scss'] 
  })
  export class CMOCornerLoyaltyscorePrintComponent{
    rowIds: string[];
    rowDetails: Promise<any>[];
    fetch: any[] = [];
    rowId:number;
    sdate:string;
    tdate:string;
    print:string;
    cmocornerloyaltyscorelist:cmocornerloyaltyscore[]=[];
    constructor( private route: ActivatedRoute, private cmocornerloyaltyscorePrintService:CMOCornerLoyaltyscorePrintService,
      private printService: PrintService,
      private http: HttpClient ){
        this.rowIds = route.snapshot.params['Id']
        .split(',');
        this.rowId = +this.rowIds[0];
        this.print=this.rowIds[1];
  }
  ngOnInit() {
   

   if (this.rowId==0)
   {
    this.cmocornerloyaltyscorePrintService.getRecruitsCMOCornerLoyaltyscorelist()
    .toPromise()
    .then(res => {
      this.cmocornerloyaltyscorelist = res;
      // console.log("getUsersWithPromise(): " + JSON.stringify(this.supervisoryagreementlist));
      if(this.print=='PDF')
      {
        this.printService.onDataReady(true,'CMOs Loyalty Score per Clinician') 
     
      }
      if(this.print=='EXCEL')
      {
       this.printService.onDataReady(true,'CMOs Loyalty Score per Clinician','EXCEL','CMOs Loyalty Score per Clinician.xlsx',0,'customers') 
       
      }
     
   
     
     
      
    })
    .catch(err => { console.log(err) });

  }
 
}
}