import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { Recruitsliveclinictbl,RecruitsClinicLocation,RecruitsList,ClinicianRegionList} from './liveclinic-form.model'
@Injectable()
export class LiveClinicFormService{
    public Recruitsliveclinictbllist: Recruitsliveclinictbl;
    RecruitsClinicLocationlist:RecruitsClinicLocation[]=[];
    RecruitsMDLists:RecruitsList[]=[];
    RecruitsClinicLocationtbl:RecruitsClinicLocation;
    ClinicianregionList:ClinicianRegionList[]=[];
    constructor(private http:HttpClient){

    }
    onGetRecruitsLiveclinic(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsliveclinicbyid/${id}`)
            .map( (data:Recruitsliveclinictbl)=>{
                this.Recruitsliveclinictbllist = data;
                return true;
            });
    }
    onPostRecruitsLiveclinicForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsliveclinic`, this.Recruitsliveclinictbllist,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    DeleteLiveclinic(id:number): Observable<boolean> {
        
        return this.http.delete(`api/recruits/deleterecruitsliveclinic/${id}`)
            .map(()=>{            
                return true;
            });
    }
    onGetRecruitlist(): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitslist`)
            .map( (data:any[])=>{
                this.RecruitsMDLists = data;
                return true;
            });
    }
    onGetRecruitscliniclocationlist(): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitscliniclocationlist`)
            .map( (data:any[])=>{
                this.RecruitsClinicLocationlist = data;
                return true;
            });
    }
      onGetRecruitsCliniclOcationbyid(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitscliniclocationbyid/${id}`)
            .map( (data:RecruitsClinicLocation)=>{
                this.RecruitsClinicLocationtbl = data;
                return true;
            });
    }
    onGetRecruitsclinicregionlist(): Observable<boolean> {
        
        return this.http.get(`api/recruits/getclinicregionlist`)
            .map( (data:any[])=>{
                this.ClinicianregionList = data;
                return true;
            });
    }
}
