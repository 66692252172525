import { Component, ViewChild, Input, Output, EventEmitter, TemplateRef, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms'
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FTEdtlService } from './recruitsftedetail-view.service'
import {FTEService} from '../recruitsfte-view.service'
import {recruitsftedetails} from'./recruitsftedetail-view.model'
import { CommonService } from '../../../services';
import { pairwise } from 'rxjs/operators';
@Component({
    selector: 'app-form-recruitsftedetail',
    templateUrl: './recruitsftedetail-view.component.html',
    styleUrls: ['./recruitsftedetail-view.component.scss'] 
  })
  export class FTEdtlComponent {
     dateNow : Date = new Date();
  
     @Input() FTEId:number;
     public ftedtldata: recruitsftedetails[] = [];
     public data:recruitsftedetails;
     dw1fte: FormGroup;
    myFormValueChanges$; 
    totalCMEHours:number;
    totalCMEAllowance:number; 
    constructor(public service:FTEdtlService,  private fb: FormBuilder,
      private serive1:FTEService, private commonService:CommonService,
      private toastr: ToastrService, private http: HttpClient){

  }
  onResetForm()
    {
      this.service.recruitftedtl = {
        id:0,
        fteId: this.FTEId,
        date: '',
        cmehours: 0,
        cmeallowance: '0',
        comments:'' 
      }
    }
    ngOnInit()
    {
      this.onResetForm();
      
      this.dw1fte = this.fb.group({
        ftEdtl: this.fb.array([])
        
      }
      ,
      {
        updateOn: 'submit'
      });
     
      this.myFormValueChanges$ = this.dw1fte.controls['ftEdtl'].valueChanges
                                  .pipe(pairwise());                                             
      this.myFormValueChanges$.subscribe(([prev, next]:[any, any]) => this.updateTotalCME(next));      
   
    }
    ngOnDestroy(): void {
      this.myFormValueChanges$.unsubscribe();
    }   
    initiatForm(): FormGroup {
      return this.fb.group({
        id:[0],
        fteId: [this.FTEId],
        date: ['',Validators.required],
        cmehours: [0],
        cmeallowance:['0'],
        comments:['']    
      });
    }
  updateTotalCME(ftedtl: any) {
    const control = <FormArray>this.dw1fte.controls['ftEdtl'];
    this.totalCMEHours=0;
    this.totalCMEAllowance=0;
    for (let i in ftedtl) {
      let totalcmehours:number = +ftedtl[i].cmehours;
      let totalcmeallowance:number=+Number(ftedtl[i].cmeallowance.replace(',',''));
      this.totalCMEHours += +totalcmehours.toFixed(2);
      this.totalCMEAllowance += +totalcmeallowance.toFixed(2);
      control.at(+i).get('cmeallowance').setValue(this.commonService.transformdigitalnumber(ftedtl[i].cmeallowance), {onlySelf: true, emitEvent: false});
      
    }

    this.serive1.formData24.inCmehours=this.totalCMEHours;
    this.serive1.formData24.balanceCmehours=this.serive1.formData24.cmehours-this.serive1.formData24.inCmehours
    this.serive1.formData24.inCmeallowance=this.commonService.transformdigitalnumber(this.totalCMEAllowance);
    this.serive1.formData24.balanceCmeallowance=this.commonService.transformdigitalnumber( Number(this.serive1.formData24.cmeallowance.replace(',',''))-Number(this.serive1.formData24.inCmeallowance.replace(',','')));
    console.log( Number(this.serive1.formData24.cmeallowance.replace(',',''))-Number(this.serive1.formData24.inCmeallowance.replace(',','')))
  }
    addNew() {
      const control = <FormArray>this.dw1fte.get('ftEdtl');
      control.push(this.initiatForm());
  }
  remove(index: number) {
    const control = <FormArray>this.dw1fte.get('ftEdtl');
    
    let id =  control.at(+index).get('id').value;
    if (id != 0)
    {
      if (confirm('Are you sure to delete this record?'))
      {
          this.service.onDeleteFTEdtl(id).subscribe(success =>{
              if(success) {
                control.removeAt(index);
                this.toastr.info('Data deleted successfully!!','')
              }
              else
              {
                this.toastr.error('Error while updating Data!!', '');
              }
          })
      }
    }else
    {
      control.removeAt(index);
    }                 
  }
      
  async onSaveForm()
  {
    const control = <FormArray>this.dw1fte.controls['ftEdtl'];

    // console.log(control.at(0).get('fteId').value+'save'+control.at(0).get('cmehours').value+"getrow"+this.dw1fte.getRawValue());
   

    this.service.onPostFTEdtl(this.dw1fte.getRawValue()).subscribe(success =>{
      if(success) {
       // this.toastr.success('Data saved successfully!!','');
      //  this.onAfterDataSave(this.FTEId);
      this.clearAll();

      }
      else
      {
         // this.toastr.error('Error while updating Data!!', '');
      }        
    })

  }  
  onAfterDataSave(id:number)
      {

        this.serive1.onGetRecruitsFTE(id).subscribe(success => {
          if (success) {
            this.serive1.formData24 = this.serive1.formData24;
  
              this.FTEId =  this.serive1.formData24.id;
            
  
              if ( this.serive1.formData24.ftEdtl.length > 0)
              {
                  this.ftedtldata = this.serive1.formData24.ftEdtl;
                  
                  if (this.ftedtldata.length > 0)
                  {
                    let FTEDetails = this.ftedtldata;
                    for (let i = 0; i < this.ftedtldata.length; i++) {
                      
                      FTEDetails[i].date = this.commonService.transformDate(this.ftedtldata[i].date)
                      FTEDetails[i].cmeallowance = this.commonService.transformdigitalnumber(this.ftedtldata[i].cmeallowance)
                    }
                    this.dw1fte.patchValue({'ftEdtl':FTEDetails}) 
                  }              
  
              }else
              {
                  this.ftedtldata = null;
              } 

          }});
      }
      async onFTEDetailsRetrieve()
      {
        const control = <FormArray>this.dw1fte.get('ftEdtl');

        if (this.ftedtldata!= null)
        {
          if (this.ftedtldata.length > 0)
          {
            let FTEDetails = this.ftedtldata;
            for (let i = 0; i < this.ftedtldata.length; i++) {
              
              FTEDetails[i].date = this.commonService.transformDate(this.ftedtldata[i].date)
              FTEDetails[i].cmeallowance = this.commonService.transformdigitalnumber(this.ftedtldata[i].cmeallowance)
            
              control.push(this.initiatForm());
            }
            this.dw1fte.patchValue({'ftEdtl':FTEDetails})        
          }
        }else
        {
          this.clearAll();
        }      
      }
  clearAll() {
    const control = <FormArray>this.dw1fte.controls['ftEdtl'];
    while(control.length) {
      control.removeAt(control.length - 1);
    }
    control.clearValidators();
    //control.push(this.initiatForm());
  } 

  }