import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { InterviewModel, RecruitsFirstLevelModel, RecruitsFirstLevelDetailModel,RecruitsFirstLevelDetailByID } from './interview-form.model'
import * as Survey from 'survey-angular';


@Injectable()
export class InterviewFormService{

    public InterviewFormData: InterviewModel[] = [];
    public formData1: RecruitsFirstLevelDetailModel;
    public formData: RecruitsFirstLevelModel;
    public RecruitsFirstLevelDetailByIDlist:RecruitsFirstLevelDetailByID[]=[];

    constructor(private http: HttpClient) { }

    onGetInterviewDetail(id:number,columnname:string): Observable<boolean> {        
        return this.http.get(`api/interview/interviewdetail?interviewId=${id}&columnname=${columnname}`)
            .map( (data:RecruitsFirstLevelDetailModel)=>{
                this.formData1 = data;
                return true;
            });
    }    
    
    onPostInterview(): Observable<boolean> {                    
        return this.http.post(`api/interview`, this.formData,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }   
    
    onPostInterviewDetail(formData): Observable<boolean> {                    
        return this.http.post(`api/interview/interviewdetail`, formData,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    OnGetRecruitsFirstlevellistbyid(id:number): Observable<boolean> {
        return this.http.get(`api/interview/getrecruitfirstlevelinterviewlist?id=${id}`)
            .map( (data:any[])=>{
                this.RecruitsFirstLevelDetailByIDlist = data;
                return true;
            });
      }
}