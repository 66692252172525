import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitsftedetails } from './recruitsftedetail-view.model'

@Injectable()

export class FTEdtlService{
    public recruitftedtl: recruitsftedetails;
    public recruitsftedetailslist:recruitsftedetails[]=[]
    constructor(private http:HttpClient){

    }

    onPostFTEdtl(data): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsftedetail`, data,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
   

    onDeleteFTEdtl(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitsftedetail/${id}`)
            .map(()=>{            
                return true;
            });
    }
    onGetRecruitsFTEdetailsbyrid(fid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsftedetailsbyfid?fid=${fid}`)
        .map( (data:any[])=>{
            this.recruitsftedetailslist = data;
            return true;
        });
    }
    
}