import { Injectable } from '@angular/core';
import { HttpClient,HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import {RecruitstableView} from './cmocornerreport.model'
@Injectable()
export class CMOCornerReportService{
    public CMoCornerReportlist:RecruitstableView[] = [];
    
   
  
    constructor(private http: HttpClient) { }
    
    //Get
    getCMOCornerReportlist(): Observable<RecruitstableView[]>  {
        return this.http.get(`api/recruits/getcmocornerreport`)
        .map( (data:any[])=>{
            this.CMoCornerReportlist = data;
            return this.CMoCornerReportlist;
           // return true;
        });
    }
  
}