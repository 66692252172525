import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ToastrService } from 'ngx-toastr';
import { EWFollowupsService } from './recruitsewfollowups-view.service';
import {followupperson} from './recruitsewfollowups-view.model'
@Component({
    selector: 'app-form-recruitsewfollowups',
    templateUrl: './recruitsewfollowups-view.component.html',
    styleUrls: ['./recruitsewfollowups-view.component.scss'] 
  })
  export class EWFollowupsComponent implements OnInit{
    followupTypeList = [
      {id:'Choose...',value:''},
      {id:'Phone',value:'Phone'},
      {id:'Email',value:'Email'},
      {id:'Meeting',value:'Meeting'},
      {id:'Virtual Meeting' , value: 'Virtual Meeting'}                                                                                                                  
    ]
    statusList = [
      {id:'Choose...',value:''},
      {id:'Pending',value:'Pending'},
      {id:'To-do',value:'To-do'},
      {id:'Completed',value:'Completed'},
      {id: 'Meeting Scheduled' , value: 'Meeting Scheduled'}                                                                                                                         
    ]
    @ViewChild('dw15') dw_15;
    dateNow : Date = new Date();
    @Output() eventSave = new EventEmitter();
    @Output() eventClose = new EventEmitter();
    @Input() recruit_id:number;
    eventtab = {'activeId':'tab-14',
                  'nextId':'tab-15'}
    public followuppersonlist:followupperson[]=[];
    constructor(public service:EWFollowupsService, private toastr: ToastrService){   
      }  
      ngOnInit(){
      this.onResetForm();
      this.service.onGetFollowupPerson().subscribe(success =>{
        if(success) {
            this.followuppersonlist = this.service.followuppersonlist;
        }
      });
    }
    onResetForm(){
        this.service.formData15 = {
          id: 0,
          recruitId: this.recruit_id,
          followupDt: this.dateNow,
          followupType: '',
          followupNotes: '',
          followupPersonId: 0,
          updatedDt: this.dateNow,
          status: ''
        
        };
  
      }
      async onSaveForm()
      {
              this.service.onPostRecruitsrecruitsEWFollowupsForm().subscribe(success =>{
                  if(success) {
                      this.toastr.success('Data saved successfully!!','EWNN Followups');
                      this.onReset();
                      this.eventSave.emit(this.eventtab);
                      this.eventClose.emit('false');
                  }
                  else
                  {
                      this.toastr.error('Error while updating Data!!', 'EWNN Followups');
                  }
              })
      }  
      public onReset()
  {
 
      this.dw_15.reset({
        id: 0,
          recruitId: this.recruit_id,
          followupDt: this.dateNow,
          followupType: '',
          followupNotes: '',
          followupPersonId: 0,
          updatedDt: this.dateNow,
          status: ''     
      });
  }   
      onCancelForm(){
        this.eventClose.emit('false'); 
      }  
  }