import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ToastrService } from 'ngx-toastr';
import {CMOCornerService} from './recruitscmocorner-view.service'
import { recruitsloyaltyscore} from '../recruits-view.model'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
    selector: 'app-form-recruitscmocorner',

    templateUrl: './recruitscmocorner-view.component.html',
    styleUrls: ['./recruitscmocorner-view.component.scss'] 
  })
  export class CMOCornerComponent implements OnInit{
    @ViewChild('dw21') dw_21;
   
    @Output() eventSave = new EventEmitter();
    @Output() eventClose = new EventEmitter();
    @Input() recruit_id:number;
    public recruitsloyaltyscorelistvalue:recruitsloyaltyscore[]=[];
    userid = localStorage.getItem('userid');
    @Input() cvtype:string='';
    eventtab = {'activeId':'tab-20',
                  'nextId':'tab-21'}
                  constructor(public service:CMOCornerService, private toastr: ToastrService){   
                }
                editor = ClassicEditor;
                ckeConfig = {
                    toolbar: {
                        items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'link',
                            'bulletedList',
                            'numberedList',
                            'imageUpload',
                            'mediaEmbed',
                            'blockQuote',
                            'undo',
                            'redo',
                            'insertTable',
                        ]
                    },
                    // This value must be kept in sync with the language defined in webpack.config.js.
                    language: 'en'
                  };
                            
                

                ngOnInit(){
                this.onResetForm();
                this.service.OnGetLoyaltyScore(this.cvtype).subscribe(success =>{
                  if(success) {
                      this.recruitsloyaltyscorelistvalue = this.service.recruitsloyaltyscorelist;
                      
                  }
              })  
              }
              onResetForm(){
                  this.service.formData21 = {
                    id: 0,
                    recruitId: this.recruit_id,
                    comments: '',
                    ptivate:true,
                    userId:Number(this.userid),
                    loyaltyScore:0,
                    cmoDate:''
                  };
            
                }
                async onSaveForm()
                {
               
                        this.service.onPostRecruitsCMOCornerForm().subscribe(success =>{
                            if(success) {
                                this.toastr.success('Data saved successfully!!','CMO Corner');
                                this.onReset();
                                this.eventSave.emit(this.eventtab);
                                this.eventClose.emit('false');
                            }
                            else
                            {
                                this.toastr.error('Error while updating Data!!', 'CMO Corner');
                            }
                        })
                }  
                public onReset()
            {
           
                this.dw_21.reset({
                    id: 0,
                    recruitId: this.recruit_id,
                    comments: '',
                    ptivate:true,
                    userId:Number(this.userid),
                    loyaltyScore:0,
                    cmoDate:''
                       
                });
            }   
                onCancelForm(){
                  this.eventClose.emit('false'); 
                }                
  }