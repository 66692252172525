import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import {ReportTableService } from '../../reports/view/reports-view.service';
import {supervisoryagreement,mediclalicensesbystate} from '../../reports/view/reports-view.model' 
@Component({
    selector: 'app-form-criticalreport',
    templateUrl: './criticalreport.component.html',
    styleUrls: ['./criticalreport.component.scss'] 
  })
  
  export class CriticalPrintComponent{
    rowIds: string[];
    rowDetails: Promise<any>[];
    fetch: any[] = [];
    rowId:number;
    supervisoryagreementlist:supervisoryagreement[]=[];
    mediclalicensesbylid:mediclalicensesbystate[]=[];
    constructor( private route: ActivatedRoute, private reportprintService:ReportTableService,
      private printService: PrintService, 
      private http: HttpClient ){
        this.rowIds = route.snapshot.params['Id']
        .split(',');
        this.rowId = +this.rowIds[0];
  }
  ngOnInit() {
    this.reportprintService.getRecruitsSupervisoryAgreementList()
    .toPromise()
    .then(res => {
      this.supervisoryagreementlist = res;
      // console.log("getUsersWithPromise(): " + JSON.stringify(this.supervisoryagreementlist));
      

      this.reportprintService.getRecruitsMedicalLicenseslid().subscribe(res => {
        this.mediclalicensesbylid = res;
      //   console.log("getUsersWithPromise(): " + JSON.stringify(this.mediclalicensesbylid));
        this.printService.onDataReady(true,'What certificates/licenses/agreements expire in the next 60 days') 
        
      })

    })
   
    .catch(err => { console.log(err) });

  }

  
  }