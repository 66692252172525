import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitshonors} from '../recruits-view.model'
@Injectable()
export class HonorsService{
    public formData6: recruitshonors;

    constructor(private http:HttpClient){

    }
    onGetRecruitsHonors(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitshonors/${id}`)
            .map( (data:recruitshonors)=>{
                this.formData6 = data;
                return true;
            });
    }
    onPostRecruitsHonorsForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitshonors`, this.formData6,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    DeleteFile(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/deleterecruitshonorsfile/${id}`)
            .map( (data:recruitshonors)=>{
                this.formData6 = data;
                return true;
            });
    }
}