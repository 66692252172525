import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ToastrService } from 'ngx-toastr';
import {BoardCertificationService} from './recruitsboard-view.service'
import { HttpClient ,HttpEventType} from '@angular/common/http';
import { recruitboard ,boardCertifiednamelist} from '../recruits-view.model'
@Component({
    selector: 'app-form-recruitsboard',
    templateUrl: './recruitsboard-view.component.html',
    styleUrls: ['./recruitsboard-view.component.scss'] 
  })
  export class BoardCertificationComponent implements OnInit{
    @ViewChild('dw5') dw_5;
    Document: File = null;
    @Input() Documentvalue:string;
    public response: {'dbPath': ''};
    @Output() eventSave = new EventEmitter();
    @Output() eventClose = new EventEmitter();
    @Input() recruit_id:number;
    @Input() boardCertifiednamelists:boardCertifiednamelist[] = [];
    Certificationlist=[
      {id:'ABEM',value:'ABEM'},
      {id:'ABIM',value:'ABIM'},
      {id:'ABFP',value:'ABFP'},
      {id:'AANP',value:'AANP'},
      {id:'ANCC',value:'ANCC'},
      {id:'NCCPA',value:'NCCPA'},
      {id:'AAMRO',value:'AAMRO'},
      {id:'FCN',value:'FCN'},
      {id:'AAPA',value:'AAPA'},
      {id:'AHA',value:'AHA'},
      {id:'CNA',value:'CNA'},
      {id:'ARC',value:'ARC'},
      {id:'IDFPR',value:'IDFPR'},
      {id:'IAPA',value:'IAPA'},
      {id:'NRCME',value:'NRCME'},
      {id:'CFMP',value:'CFMP'},
      {id:'CFMP',value:'CFMP'}
    ]
    eventtab = {'activeId':'tab-4',
                  'nextId':'tab-5'}
                  constructor(public service:BoardCertificationService, private toastr: ToastrService
                    ,private http: HttpClient){   
                }  
                ngOnInit(){
                this.onResetForm();
                this.service.OnBoardCertificationAlllist().subscribe(success =>{
                  if(success) {
                      this.boardCertifiednamelists = this.service.boardCertifiednamelist;
                    }
                  })
              }
              onResetForm(){
                this.response = {
                  'dbPath':''
              };
                  this.service.formData5 = {
                    id: 0,
                    recruitId: this.recruit_id,
                    certification: '',
                    fromDate: '',
                    toDate: '',
                    refNo: '',
                    documents:'' ,
                    certificationTitle:''
                  };
            
                }
                async onSaveForm()
                {
                  if(this.Documentvalue==null || this.Documentvalue=='')
                  {
                    await this.onFileUpload();
                    this.service.formData5.documents = this.response.dbPath;
                  }
                        this.service.onPostRecruitsBoardForm().subscribe(success =>{
                            if(success) {
                                this.toastr.success('Data saved successfully!!','Board Certifications');
                                this.onReset();
                                this.eventSave.emit(this.eventtab);
                                this.eventClose.emit('false');
                            }
                            else
                            {
                                this.toastr.error('Error while updating Data!!', 'Board Certifications');
                            }
                        })
                }  
                public onReset()
            {
              this.response = {
                'dbPath':''
            };
                this.dw_5.reset({
                    id: 0,
                    recruitId: this.recruit_id,
                    certification: '',
                    fromDate: '',
                    toDate: '',
                    refNo: '' ,
                    documents:'' ,
                    certificationTitle:''           
                });
            }   
                onCancelForm(){
                  this.eventClose.emit('false'); 
                } 
                onFileSelected(event){
    
                  if(event.target.files[0]!=null)
                     
                      
                          this.Document = event.target.files[0];
                          this.service.formData5.documents = event.target.files[0];
                     
                  }
                onFileUpload()
                {
                    if (this.Document == null)
                    {
                        return this.response;
                    }
                    let fileToUpload = this.Document;
                       const formData = new FormData();
                      formData.append('Document', fileToUpload, fileToUpload.name);
                        return this.http.post(`api/upload?foldername=${'Recruits'}`, formData, {observe: 'events'})
                      .toPromise().then(event => {
                          if (event.type === HttpEventType.Response) {
                          this.uploadFinished(event.body);
                          }
                      });
                    
                }
                public uploadFinished = (event) => {
                  this.response = event;
              }
              DeleteFile()
              {
                 
                  if (confirm('Are you sure to delete File?'))
                  {
                      this.service.DeleteFile(this.service.formData5.id).subscribe(success =>{
                          if(success) {
                              
                              this.toastr.info('File is Deleted','Document')
                              this.Documentvalue=null;
                            
                          }
                          else
                          {
                              this.toastr.error('Error while Updating Data!!', 'Document');
                          }
                      })
                  }
              }                
  }