import { Injectable } from '@angular/core';
import { HttpClient,HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import {cliniclocation} from './cliniclocationreport.model'
@Injectable()
export class ClinicLocationPrintService{
    public cliniclocationlist:cliniclocation[] = [];
   
    
   
  
    constructor(private http: HttpClient) { }
    
    //Get
    getClinicLocationlist(): Observable<cliniclocation[]>  {
        return this.http.get(`api/recruits/getcliniclocationlist`)
        .map( (data:any[])=>{
            this.cliniclocationlist = data;
            return this.cliniclocationlist;
           // return true;
        });
    }
  
  
}