import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitscisAattendees } from '../recruits-view.model'
@Injectable()
export class CISAttendeesService{
    public formData18: recruitscisAattendees;

    constructor(private http:HttpClient){

    }
    onGetRecruitsCISAttendees(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitscisaattendees/${id}`)
            .map( (data:recruitscisAattendees)=>{
                this.formData18 = data;
                return true;
            });
    }
    onPostRecruitsCISAttendeesForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitscisaattendees`, this.formData18,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
}