import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ToastrService } from 'ngx-toastr';
import {CISAttendeesService} from './recruitscisattendees-view.service'
@Component({
    selector: 'app-form-recruitscisattendees',
    templateUrl: './recruitscisattendees-view.component.html',
    styleUrls: ['./recruitscisattendees-view.component.scss'] 
  })
  export class CISAttendeesComponent implements OnInit{
    @ViewChild('dw18') dw_18;
   
    @Output() eventSave = new EventEmitter();
    @Output() eventClose = new EventEmitter();
    @Input() recruit_id:number;
    roomlist=[
    
      {id:'0',value:'0'},
      {id:'0.5',value:'0.5'},
      {id:'1',value:'1'}
     
    ]
    eventtab = {'activeId':'tab-15',
                  'nextId':'tab-18'}
                  constructor(public service:CISAttendeesService, private toastr: ToastrService){   
                }  
                ngOnInit(){
                this.onResetForm();
              }
              onResetForm(){
                  this.service.formData18 = {
                    id: 0,
                    recruitId: this.recruit_id,
                    fromDate: '',
                    toDate: '',
                    rsvpDate:'',
                    hours:'',
                    comment:'',
                    active:true,
                    roomOnFirst:'',
                    roomOnSecond:'',
                    dinnerOn:'',
                    lunchOn:'',
                    arriveAt:'',
                    departAt:'',
                    quantity:''
                  };
            
                }
                async onSaveForm()
                {
                        this.service.onPostRecruitsCISAttendeesForm().subscribe(success =>{
                            if(success) {
                                this.toastr.success('Data saved successfully!!','CIS Attendees');
                                this.onReset();
                                this.eventSave.emit(this.eventtab);
                                this.eventClose.emit('false');
                            }
                            else
                            {
                                this.toastr.error('Error while updating Data!!', 'CIS Attendees');
                            }
                        })
                }  
                public onReset()
            {
           
                this.dw_18.reset({
                    id: 0,
                    recruitId: this.recruit_id,
                    fromDate: '',
                    toDate: '',
                    rsvpDate:'',
                    hours:'',
                    comment:'',
                    active:true,
                    roomOnFirst:'',
                    roomOnSecond:'',
                    dinnerOn:'',
                    lunchOn:'',
                    arriveAt:'',
                    departAt:'',
                    quantity:''        
                });
            }   
                onCancelForm(){
                  this.eventClose.emit('false'); 
                }                
  }