import { Injectable } from '@angular/core';
import { HttpClient,HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import {recruitscmereimbursement} from './cmereimbursementrereport.model'
@Injectable()
export class CMEReimbursementPrintService{
    public recruitscmereimbursementlist:recruitscmereimbursement[] = [];
  
   
  
    constructor(private http: HttpClient) { }
    
    //Get
    getRecruitsCMEReimbursementlist(id:number): Observable<recruitscmereimbursement[]>  {
        return this.http.get(`api/recruits/getrecruitscmereimbursement?id=${id}`)
        .map( (data:any[])=>{
            this.recruitscmereimbursementlist = data;
            return this.recruitscmereimbursementlist;
           // return true;
        });
    }

   
    
}