import { Injectable } from '@angular/core';
import { HttpClient,HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import {cisattendeesreport,cisattendeeshourreport,cisattendeeshourspara} from './cmehoursreport.model'
@Injectable()
export class CMEHoursPrintService{
    public printformdata:cisattendeesreport[] = [];
  
   public cisattendeeshourreportlist:cisattendeeshourreport[]=[];
   public cisattendeeshoursparalist:cisattendeeshourspara[]=[];
  
    constructor(private http: HttpClient) { }
    
    //Get
    getRecruitsCISAttendeesData( sdate:string, tdate:string ): Observable<HttpResponse<cisattendeesreport[]>> {
        return this.http.get<cisattendeesreport[]>(`api/recruits/getrecruitscisaattendeesbydate?sdate=${sdate}&tdate=${tdate}`
        , { observe: 'response' });
    }
    getRecruitsCISAttendeeshoursData( sdate:string, tdate:string ): Observable<HttpResponse<cisattendeeshourreport[]>> {
        return this.http.get<cisattendeeshourreport[]>(`api/recruits/getrecruitscisaattendeeshoursbydate?sdate=${sdate}&tdate=${tdate}`
        , { observe: 'response' });
    }
    getRecruitsCISAttendeeshoursPara( sdate:string, tdate:string ): Observable<HttpResponse<cisattendeeshourspara[]>> {
        return this.http.get<cisattendeeshourspara[]>(`api/recruits/getrecruitscisaattendeeshourspara?sdate=${sdate}&tdate=${tdate}`
        , { observe: 'response' });
    }
    
}