import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSidenavModule } from '@angular/material';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library as fontLibrary } from '@fortawesome/fontawesome-svg-core';
import { faCalendar,  faClock } from '@fortawesome/free-regular-svg-icons';

import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';


import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { PrintLayoutComponent,
  PrintService } from './print-layout/index'
import {RptCISAttendeesPrintComponent,RptCISAttendeesPrintService,MedicallicensbystatePrintComponent,FirtsLevelSurvayReportComponent
,SupervisoryAgreementPrintComponent,CMEHoursPrintComponent,CMEHoursPrintService
,CriticalPrintComponent,employmentstatusreportPrintComponent,CMOCornerLoyaltyscorePrintComponent,CMOCornerLoyaltyscorePrintService
,CMEReimbursementPrintComponent,CMEReimbursementPrintService,RecruitsOnstaffPrintComponent,RecruitsOnstaffPrintService
,RecruitsMultipleliveclinicPrintComponent,RecruitsMultipleliveclinicPrintService,ClinicLocationPrintComponent,ClinicLocationPrintService
,RecruitsEWNNScorePrintComponent,RecruitsEWNNScorePrintService,CMOCornerReportService,CMOCornerReportComponent} from './printreports/index'

import {StateParaService } from './reports/stateparameter/stateparameter-para.service';
import{DateParaService} from './reports/dateparameter/dateparameter-para.service'
import { InterviewFormService} from './interview/shared/interview-form.service';
import {ReportTableService} from'./reports/view/reports-view.service';
import {LocationParaService} from './reports/locationparameter/locationparameter-para.service'
import {CustomDatepickerModule} from './services/custom-datepicker/custom-datepicker.module';

import {
  MenuComponent,
  HeaderComponent,
  SidebarComponent,
  FooterComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './core';
import { RecruitViewModule } from './recruits/recruits-view.module'
import { FollowupMaster } from './recruits/recruitsewfollowups/recruitsewfollowups-view.module'



import {
  UserService,
  AuthInterceptor,
  CommonService,
  OrderByPipe,
  MinusSignToParens,
  MaskComponent,
  ExcelService,
  DigitalNumberPipe
} from './services'

import { FilterPipesModule } from './services/filterby/filterpipe.module'
import { GroupByPipeModule } from './services/groupby/groupby.module'
import {SumPipeModule} from './services/common/sumpipe.pipe';
import { OnstafflistwithuniquecodereportComponent } from './printreports/onstafflistwithuniquecodereport/onstafflistwithuniquecodereport.component';
import { SupervisoryphysiciansPrintComponent } from './printreports/supervisoryphysiciansreport/supervisoryphysiciansreport.component';
import { FollowupreportComponent } from './printreports/followupreport/followupreport.component'
import { FollowupreportService } from './printreports/followupreport/followupreport.service';
import { ClinicalleadersteamsPrintComponent } from './printreports/clinicalleadersteamsreport/clinicalleadersteamsreport.component';
fontLibrary.add(
  faCalendar,
  faClock
);

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    PrintLayoutComponent,
    RptCISAttendeesPrintComponent,
    MedicallicensbystatePrintComponent,
    FirtsLevelSurvayReportComponent,
    SupervisoryAgreementPrintComponent,
    CMEHoursPrintComponent,
    CriticalPrintComponent,
    employmentstatusreportPrintComponent,
    CMOCornerLoyaltyscorePrintComponent,
    CMEReimbursementPrintComponent,
    RecruitsOnstaffPrintComponent,
    RecruitsMultipleliveclinicPrintComponent,
    ClinicLocationPrintComponent,
    RecruitsEWNNScorePrintComponent,
    CMOCornerReportComponent,
    OnstafflistwithuniquecodereportComponent,
    SupervisoryphysiciansPrintComponent,
    FollowupreportComponent,
    ClinicalleadersteamsPrintComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes),
    FormsModule, ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    LoadingBarRouterModule, FontAwesomeModule,
    NgbModule.forRoot(),
    MatSidenavModule,
    ToastrModule.forRoot({
        progressBar: true
    }),NgxSpinnerModule, RecruitViewModule, FilterPipesModule,FollowupMaster,GroupByPipeModule,SumPipeModule,CustomDatepickerModule
  ],
  
  providers: [UserService,CommonService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      }, 
      DatePipe,OrderByPipe, MaskComponent,PrintService,RptCISAttendeesPrintService, StateParaService,InterviewFormService,ReportTableService,CMEHoursPrintService,DateParaService
      ,LocationParaService,CMOCornerLoyaltyscorePrintService,CMEReimbursementPrintService,RecruitsOnstaffPrintService
      ,RecruitsMultipleliveclinicPrintService,ClinicLocationPrintService,RecruitsEWNNScorePrintService,CMOCornerReportService,FollowupreportService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
