import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitsmedicallicensestbl,medicallicensestbl} from './recruitsmedicallicense-view.model'
@Injectable()
export class MedicalLicenseService{
    public formData4: recruitsmedicallicensestbl;
    public medicallicensestbllist:medicallicensestbl[]=[];

    constructor(private http:HttpClient){

    }
    onGetRecruitsMedicalLicense(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsmedicallicenses/${id}`)
            .map( (data:recruitsmedicallicensestbl)=>{
                this.formData4 = data;
                return true;
            });
    }
    onPostRecruitsMedicalLicenseForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsmedicallicenses`, this.formData4,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    onGetMedicalLicenses(): Observable<boolean> {
        
        return this.http.get(`api/recruits/getmedicallicenses`)
        .map( (data:any[])=>{
            this.medicallicensestbllist = data;
            return true;
        });
    }
    DeleteFile(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/deleteruitsmedicallicensesfile/${id}`)
            .map( (data:recruitsmedicallicensestbl)=>{
                this.formData4 = data;
                return true;
            });
    }
}