import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitsresidencies} from '../recruits-view.model'
@Injectable()
export class ResidenciesService{
    public formData9: recruitsresidencies;

    constructor(private http:HttpClient){

    }
    onGetRecruitsResidencies(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsresidencies/${id}`)
            .map( (data:recruitsresidencies)=>{
                this.formData9 = data;
                return true;
            });
    }
    onPostRecruitsResidenciesForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsresidencies`, this.formData9,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    DeleteFile(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/deleterecruitsresidenciesfile/${id}`)
            .map( (data:recruitsresidencies)=>{
                this.formData9 = data;
                return true;
            });
    }
}