import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import { ClinicLocationPrintService } from './cliniclocationreport.service';
import {cliniclocation} from'./cliniclocationreport.model';
@Component({
    selector: 'app-form-cliniclocationreport',
    templateUrl: './cliniclocationreport.component.html',
    styleUrls: ['./cliniclocationreport.component.scss'] 
  })
  export class ClinicLocationPrintComponent{
    rowIds: string[];
    rowDetails: Promise<any>[];
    fetch: any[] = [];
    rowId:number;
   cliniclocationlist:cliniclocation[] = [];
    name:string;
    print:string;
    constructor( private route: ActivatedRoute, private clinicLocationPrintService:ClinicLocationPrintService,
        private printService: PrintService,
        private http: HttpClient ){
          this.rowIds = route.snapshot.params['Id']
          .split(',');
          this.rowId = +this.rowIds[0];
          this.print=this.rowIds[1];
    }
    ngOnInit() {
   

       
         
         this.clinicLocationPrintService.getClinicLocationlist()
         .toPromise()
         .then(res => {
           this.cliniclocationlist = res;
           if(this.print=='PDF')
           {
            this.printService.onDataReady(true,'Health center List') 
          
           }
           if(this.print=='EXCEL')
           {
            this.printService.onDataReady(true,'Health center List','EXCEL','Health center List.xlsx',0,'customers') 
            
           }
         
           
        
          
          
           
         })
         .catch(err => { console.log(err) });
     
    
      
     }
  }