import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitsotherdetails,recruitsotherdetailsview} from '../recruits-view.model'
@Injectable()
export class OtherDetailsService{
    public formData2: recruitsotherdetailsview;
    public formdataview:recruitsotherdetailsview;
    constructor(private http:HttpClient){

    }
    onGetRecruitsOtherDetails(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsotherdetails/${id}`)
            .map( (data:recruitsotherdetailsview)=>{
                this.formData2 = data;
                return true;
            });
    }
    onPostRecruitsOtherDetailsForm(data): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsotherdetails`, data,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    DeleteFile(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/deleteruitsrecruitsotherdetailsfile/${id}`)
            .map( (data:recruitsotherdetailsview)=>{
                this.formData2 = data;
                return true;
            });
    }
}