import { Injectable } from '@angular/core';
import { HttpClient,HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import {cmocornerloyaltyscore} from './cmocornerloyaltyscorereport.model'
@Injectable()
export class CMOCornerLoyaltyscorePrintService{
    public cmocornerloyaltyscorelist:cmocornerloyaltyscore[] = [];
  
   
  
    constructor(private http: HttpClient) { }
    
    //Get
    getRecruitsCMOCornerLoyaltyscorelist(): Observable<cmocornerloyaltyscore[]>  {
        return this.http.get(`api/recruits/getrecruitscmocornerloyalityscorelist`)
        .map( (data:any[])=>{
            this.cmocornerloyaltyscorelist = data;
            return this.cmocornerloyaltyscorelist;
           // return true;
        });
    }

   
    
}