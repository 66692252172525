import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { statepara,mediclalicensesbystate } from './stateparameter-para.model'
@Injectable()

export class StateParaService{
    public formData:statepara;
    public mediclalicensesbystatelist: mediclalicensesbystate[] = [];
    constructor(private http: HttpClient) { }
     
    getRecruitsMediclalicensesbystate(sid:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsmedicallicensesbysid?sid=${sid}`)
        .map( (data:any[])=>{
            this.mediclalicensesbystatelist = data;
            return true;
        });
    }  
}