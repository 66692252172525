import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitsmalpractice} from '../recruits-view.model'
@Injectable()
export class MalpracticeService{
    public formData14: recruitsmalpractice;

    constructor(private http:HttpClient){

    }
    onGetRecruitsmalpractice(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsmalpractice/${id}`)
            .map( (data:recruitsmalpractice)=>{
                this.formData14 = data;
                return true;
            });
    }
    onPostRecruitsrecruitsmalpracticeForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsmalpractice`, this.formData14,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
}