import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitsfte} from '../recruits-view.model'
import {recruitsftedetails} from'./recruitsftedetail/recruitsftedetail-view.model'
@Injectable()
export class FTEService{
    public formData24: recruitsfte;

    constructor(private http:HttpClient){

    }
    onGetRecruitsFTE(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsfte/${id}`)
            .map( (data:recruitsfte)=>{
                this.formData24 = data;
                return true;
            });
    }
    onPostRecruitsFTEForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsfte`, this.formData24,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
   
    
}