import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';;
import { FormsModule,ReactiveFormsModule } from '@angular/forms'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from  '@ng-select/ng-select'
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner'
//import { CKEditorModule } from 'ng2-ckeditor';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { TextMaskModule } from 'angular2-text-mask';
import { Routes, RouterModule } from '@angular/router';
//import { YearPickerComponent } from '../services';
import { RecruitsviewComponent } from './recruits-view.component'
import { EducationComponent } from './recruitseducation/recruitseducation-view.component'
import { EducationService } from './recruitseducation/recruitseducation-view.service'
import {BoardCertificationComponent} from './recruitsboard/recruitsboard-view.component'
import {BoardCertificationService} from './recruitsboard/recruitsboard-view.service'
import {HonorsComponent} from './recruitshonors/recruitshonors-view.component'
import {HonorsService} from './recruitshonors/recruitshonors-view.service'
import {MembershipComponent} from './recruitsmembership/recruitsmembership-view.component'
import {MembershipService} from './recruitsmembership/recruitsmembership-view.service'
import {FellowshipComponent} from './recruitsfellowship/recruitsfellowship-view.component'
import {FellowshipService} from './recruitsfellowship/recruitsfellowship-view.service'
import {ResidenciesComponent} from './recruitsresidencies/recruitsresidencies-view.component'
import {ResidenciesService} from './recruitsresidencies/recruitsresidencies-view.service'
import {InternshipsComponent} from './recruitsinternships/recruitsinternships-view.component'
import {InternshipsService} from './recruitsinternships/recruitsinternships-view.service'
import{AffiliationsComponent} from './recruitsaffiliations/recruitsaffiliations-view.component'
import{AffiliationsService} from './recruitsaffiliations/recruitsaffiliations-view.service'
import{EmployerComponent} from'./recruitsemployer/recruitsemployer-view.component';
import{EmployerService} from'./recruitsemployer/recruitsemployer-view.service';
import{ReferencesComponent} from'./recruitsreferences/recruitsreferences-view.component';
import{ReferencesService} from'./recruitsreferences/recruitsreferences-view.service';
import{MalpracticeComponent} from'./recruitsmalpractice/recruitsmalpractice-view.component';
import{MalpracticeService} from'./recruitsmalpractice/recruitsmalpractice-view.service';
import{MedicalLicenseComponent} from'./recruitsmedicallicense/recruitsmedicallicense-view.component';
import {MedicalLicenseService} from'./recruitsmedicallicense/recruitsmedicallicense-view.service';
import {OtherDetailsComponent} from './recruitsotherdetails/recruitsotherdetails-view.component';
import {OtherDetailsService} from './recruitsotherdetails/recruitsotherdetails-view.service'
//import {EWFollowupsComponent} from './recruitsewfollowups/recruitsewfollowups-view.component';
import {EWFollowupsService} from './recruitsewfollowups/recruitsewfollowups-view.service'
import { FilterPipesModule } from '../services/filterby/filterpipe.module'
import{GroupByPipeModule} from '../services/groupby/groupby.module'
import {FollowupMaster} from './recruitsewfollowups/recruitsewfollowups-view.module'
import {OrderByPipe} from '../services/common/orderby.pipe'
import {CISAttendeesService} from './recruitscisattendees/recruitscisattendees-view.service'
import {CISAttendeesComponent} from './recruitscisattendees/recruitscisattendees-view.component'
import {LiveClinicFormService} from'../liveclinic/shared/liveclinic-form.service'
import {RecruitsLiveClinicFormComponent} from './recruitscliniclocation/recruitscliniclocation-view.component'
import { CMOCornerService } from "./recruitscmocorner/recruitscmocorner-view.service";
import { CMOCornerComponent } from "./recruitscmocorner/recruitscmocorner-view.component";
import {CustomDatepickerModule} from '../services/custom-datepicker/custom-datepicker.module';
import {FTEService} from'./recruitsfte/recruitsfte-view.service'
import{FTEComponent} from'./recruitsfte/recruitsfte-view.component'
import {FTEdtlService} from './recruitsfte/recruitsftedetail/recruitsftedetail-view.service'
import {FTEdtlComponent} from './recruitsfte/recruitsftedetail/recruitsftedetail-view.component'

@NgModule({
  imports: [
    CommonModule, FormsModule, NgSelectModule, NgxDatatableModule, NgbModule, TextMaskModule,ReactiveFormsModule,
    FontAwesomeModule, FilterPipesModule,NgxSpinnerModule,FollowupMaster,GroupByPipeModule,CKEditorModule
    ,CustomDatepickerModule
  ],
  exports:[RecruitsviewComponent],
  declarations: [RecruitsviewComponent,EducationComponent,BoardCertificationComponent,HonorsComponent
  ,MembershipComponent,FellowshipComponent,ResidenciesComponent,InternshipsComponent,AffiliationsComponent
,EmployerComponent,ReferencesComponent,MalpracticeComponent,MedicalLicenseComponent,OtherDetailsComponent
,OrderByPipe,CISAttendeesComponent,RecruitsLiveClinicFormComponent,CMOCornerComponent,FTEComponent
,FTEdtlComponent
//,EWFollowupsComponent
 //,YearPickerComponent
 //,PrintcvPageComponent,

],
  providers:[EducationService,BoardCertificationService,HonorsService,MembershipService,FellowshipService
    ,ResidenciesService,InternshipsService,AffiliationsService,EmployerService,ReferencesService,MalpracticeService
  ,MedicalLicenseService,OtherDetailsService,EWFollowupsService,CISAttendeesService,LiveClinicFormService
,CMOCornerService,FTEService,FTEdtlService]

})

export class RecruitViewModule {}
