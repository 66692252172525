import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruiteducation } from './recruitseducation-view.model'

@Injectable()
export class EducationService{
    public formData3: recruiteducation;

    constructor(private http:HttpClient){

    }
    onGetRecruitsEduction(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitseduction/${id}`)
            .map( (data:recruiteducation)=>{
                this.formData3 = data;
                return true;
            });
    }
    onPostRecruitsEductionForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitseduction`, this.formData3,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    onDeleteRecruitsEductionForm(id:number): Observable<boolean> {        
        return this.http.delete(`api/recruits/deleterecruitseduction/${id}`)
            .map(()=>{            
                return true;
            });
    }
    DeleteFile(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/deleterecruitseductionfile/${id}`)
            .map( (data:recruiteducation)=>{
                this.formData3 = data;
                return true;
            });
    }

}