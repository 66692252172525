import { Component,OnInit  } from '@angular/core';
import { MenuService } from './menu.service';
import { CommonService } from '../../services';
import {cisname} from'../../services/common/common.model' 
import { HttpClient } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
/* tslint:disable:max-line-length */
@Component({
  selector: 'app-menu',
  template: `
  <ul class="navigation" appAccordion>
    <div *ngFor="let menuitem of menuService.getAll()">
      <li class="navigation-item" appAccordionLink group="{{menuitem.state}}" *ngIf="menuitem.type !== 'divider' || menuitem.type !== 'title'">
        <a class="navigation-link" appAccordionToggle [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'">
          <i class="icon {{ menuitem.icon }} mr-3"></i>
          <span class="title mr-auto">{{ menuitem.name  }}</span>
          <span class="badge badge-{{ badge.type }} mr-3" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
        <a class="navigation-link" appAccordionToggle target="_blank" href={{menuitem.state}} *ngIf="menuitem.type === 'hyperlink'">
          <i class="icon {{ menuitem.icon }} mr-3"></i>
          <span class="title mr-auto">{{ menuitem.name  }}</span>
          <span class="badge badge-{{ badge.type }} mr-3" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
        <a class="navigation-link" appAccordionToggle href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink'">
          <i class="icon {{ menuitem.icon }} mr-3"></i>
          <span class="title mr-auto">{{ menuitem.name  }}</span>
          <span class="badge badge-{{ badge.type }} mr-3" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
        <a class="navigation-link" appAccordionToggle href="{{menuitem.state}}" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
          <i class="icon {{ menuitem.icon }} mr-3"></i>
          <span class="title mr-auto">{{ menuitem.name  }}</span>
          <span class="badge badge-{{ badge.type }} mr-3" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
        <a class="navigation-link" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
          <i class="icon {{ menuitem.icon }} mr-3"></i>
          <span class="title mr-auto">{{ menuitem.name  }}</span>
          <span class="badge badge-{{ badge.type }} mr-3" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          <i class="menu-caret icon ion-ios-arrow-down"></i>
        </a>
        <ul class="navigation-submenu" *ngIf="menuitem.type === 'sub'">
          <li class="navigation-item" *ngFor="let childitem of menuitem.children" routerLinkActive="open">
            <a [routerLink]="['/', menuitem.state, childitem.state ]" class="navigation-link relative">{{ childitem.name  }}</a>
          </li>
        </ul>
        <a class="navigation-link" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'subfolderlink'">
          <i class="icon {{ menuitem.icon }} mr-3"></i>
          <span class="title mr-auto">{{ menuitem.name  }}</span>
          <span class="badge badge-{{ badge.type }} mr-3" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          <i class="menu-caret icon ion-ios-arrow-down"></i>
        </a>
      <ul class="navigation-submenu" *ngIf="menuitem.type === 'subfolderlink'">
        <li class="navigation-item" *ngFor="let childitem of menuitem.children" routerLinkActive="open">
        <a class="navigation-link relative" appAccordionToggle target="_blank" href={{childitem.state}} >
            <span class="title mr-auto">{{ childitem.name  }}</span>
         </a>
        </li>
      </ul>
      <a class="navigation-link" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'linkdynamic'">
          <i class="icon {{ menuitem.icon }} mr-3"></i>
          <span class="title mr-auto">{{ menuitem.name  }}</span>
          <span class="badge badge-{{ badge.type }} mr-3" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          <i class="menu-caret icon ion-ios-arrow-down"></i>
        </a>
        
        <ul class="navigation-submenu" *ngIf="menuitem.type === 'linkdynamic'">
       
        <li class="navigation-item" *ngFor="let cisnamelist of menuService.cisnamelist" routerLinkActive="open">
        <a class="navigation-link relative" appAccordionToggle  href="cisattendees?CME={{ cisnamelist.cisName  }}" >		
        <span class="title mr-auto">{{ cisnamelist.cisName  }} </span>
        </a>

        </li>
       
      </ul>

      </li>
      <li class="navigation-item" *ngIf="menuitem.type === 'divider'">
        <hr/>
      </li>
      <li class="navigation-item" *ngIf="menuitem.type === 'title'">
        <span class="navigation-title">{{ menuitem.name  }}</span>
      </li>
    </div>
  </ul>`,
  providers: [MenuService]
})

export class MenuComponent  {
  currentLang = 'en';
  public cisnamevalue:string;
  cisnamelist:cisname[]=[];
  constructor(
    public menuService: MenuService,private commonService: CommonService,
    private http:HttpClient

  ) {}
  
  ngOnInit(){
   this.menuService.getCISnamemenulist();
  //   this.commonService.getCISNameList().subscribe(success =>{
  //     if(success) {
  //        this.cisnamelist=this.commonService.cisnamelist;
  //        this.cisnamevalue=this.commonService.cisnamelist[0].cisName;
         
  //     }
  // }) 
  
 
  }
  
  
}
