import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ToastrService } from 'ngx-toastr';
import {FTEService} from './recruitsfte-view.service'
import { HttpClient ,HttpEventType} from '@angular/common/http';
import {recruitsftedetails} from '../recruits-view.model'
import {FTEdtlComponent} from'./recruitsftedetail/recruitsftedetail-view.component'
import { CommonService } from '../../services/common/common.service'
@Component({
    selector: 'app-form-recruitsfte',
    templateUrl: './recruitsfte-view.component.html',
    styleUrls: ['./recruitsfte-view.component.scss'] 
  })
  export class FTEComponent implements OnInit{
    @ViewChild('dw24') dw_24;
    @ViewChild(FTEdtlComponent) dw_ftedtl:FTEdtlComponent;
    public recruitsftedtl:recruitsftedetails[]=[]
    Document: File = null;
    @Input() Documentvalue:string;
    public response: {'dbPath': ''};
    @Output() eventSave = new EventEmitter();
    @Output() eventClose = new EventEmitter();
    @Input() recruit_id:number;
    currentYearDate: number;
    isFTEdtl:boolean = false;
    cmeallowance:string;
    userid = localStorage.getItem('userid');
    eventtab = {'activeId':'tab-23',
                  'nextId':'tab-24'}
                  constructor(public service:FTEService, private commonService:CommonService, private toastr: ToastrService
                    ,private http: HttpClient){   
                }  
                ngOnInit(){
                  this.currentYearDate=Number(new Date().getFullYear());
                this.onResetForm();
              }
              onResetForm(){
                this.response = {
                  'dbPath':''
              };
                  this.service.formData24 = {
                    id: 0,
                    recruitId: this.recruit_id,
                    year: null,
                    cmehours: 0,
                    inCmehours: 0,
                    balanceCmehours: 0,
                    cmeallowance:'0',
                    inCmeallowance:'0',
                    balanceCmeallowance: '0',
                    userId: Number(this.userid),
                    ftEdtl:null
                   
                  };
            
                }
                async onSaveForm()
                {
                  
                        this.service.onPostRecruitsFTEForm().subscribe(success =>{
                            if(success) {
                              // if(this.recruitsftedtl.length>0)
                              // {
                                this.dw_ftedtl.onSaveForm();
                             //}
                                this.toastr.success('Data saved successfully!!','CME Reimbursement');
                                this.onReset();
                                this.eventSave.emit(this.eventtab);
                                this.eventClose.emit('false');
                            }
                            else
                            {
                                this.toastr.error('Error while updating Data!!', 'CME Reimbursement');
                            }
                        })
                }  
         public onReset()
            {
              this.response = {
                'dbPath':''
            };
            this.service.formData24 = {
                id: 0,
                recruitId: this.recruit_id,
                year:null ,
                cmehours: 0,
                inCmehours: 0,
                balanceCmehours: 0,
                cmeallowance:'0',
                inCmeallowance:'0',
                balanceCmeallowance: '0',
                userId: Number(this.userid),
                ftEdtl:null
               
              };
            }   
         onCancelForm(){
                  this.eventClose.emit('false'); 
        }   
        onCMEChange(): void {  
          
            this.service.formData24.balanceCmehours=this.service.formData24.cmehours-this.service.formData24.inCmehours;
          
        }
        onCMEallowanceChange(): void {  
          
          this.service.formData24.balanceCmeallowance=this.commonService.transformdigitalnumber( Number(this.service.formData24.cmeallowance.replace(',',''))-Number(this.service.formData24.inCmeallowance.replace(',','')));
         
          
      }
      addCommas(event)
      {
        if(event.which >= 37 && event.which <= 40) return;
        this.cmeallowance=String(this.service.formData24.cmeallowance);
        // format number
        
         this.cmeallowance = this.cmeallowance.replace(/\D/g, "")
           .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
           console.log('print' + this.cmeallowance)
      }
     
      onRetrieveftedtl(){      
        this.isFTEdtl = true;
        this.dw_ftedtl.ftedtldata = this.recruitsftedtl;
       this.dw_ftedtl.onFTEDetailsRetrieve();
      }
      onAddNewFTEdtl(){      
      
        this.isFTEdtl = true;
       
       this.dw_ftedtl.addNew();
      }
      updateAmount(event)
      {
       this.service.formData24.cmeallowance=this.commonService.transformdigitalnumber( this.service.formData24.cmeallowance)
       this.service.formData24.inCmeallowance=this.commonService.transformdigitalnumber( this.service.formData24.inCmeallowance)
       this.service.formData24.balanceCmeallowance=this.commonService.transformdigitalnumber( this.service.formData24.balanceCmeallowance)
      
      }
                
  }