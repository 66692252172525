import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import { recruitsmembership} from '../recruits-view.model'
@Injectable()
export class MembershipService{
    public formData7: recruitsmembership;

    constructor(private http:HttpClient){

    }
    onGetRecruitsMembership(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/getrecruitsmembership/${id}`)
            .map( (data:recruitsmembership)=>{
                this.formData7 = data;
                return true;
            });
    }
    onPostRecruitsMembershipForm(): Observable<boolean> {                    
        return this.http.post(`api/recruits/addrecruitsmembership`, this.formData7,{
            headers:new HttpHeaders()
            .set('Content-Type','application/json')
            })
            .map(()=>{            
                return true;
            });
    }
    DeleteFile(id:number): Observable<boolean> {
        
        return this.http.get(`api/recruits/deleterecruitsmembershipfile/${id}`)
            .map( (data:recruitsmembership)=>{
                this.formData7 = data;
                return true;
            });
    }
}