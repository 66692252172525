import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from 'src/app/print-layout';
import { DateParaService } from 'src/app/reports/dateparameter/dateparameter-para.service';
import { supervisoryphysicians } from 'src/app/reports/view/reports-view.model';
import { ReportTableService } from 'src/app/reports/view/reports-view.service';

@Component({
  selector: 'app-supervisoryphysiciansreport',
  templateUrl: './supervisoryphysiciansreport.component.html',
  styleUrls: ['./supervisoryphysiciansreport.component.scss']
})
export class SupervisoryphysiciansPrintComponent implements OnInit {
  rowIds: string[];
  rowDetails: Promise<any>[];
  fetch: any[] = [];
  rowId:number;
  sdate:string;
  tdate:string;
  print:string;
  //supervisoryphysicianslist:supervisoryphysicians[]=[];
  constructor( private route: ActivatedRoute, private supervisoryphysiciansprintService:ReportTableService,
    private printService: PrintService, private dateParaService:DateParaService,
    private http: HttpClient ){
      this.rowIds = route.snapshot.params['Id']
      .split(',');
      this.rowId = +this.rowIds[0];
      this.print= String(this.rowIds[1]);      
    }

  ngOnInit() {
   

    if (this.rowId==0)
    {
     this.supervisoryphysiciansprintService.getRecruitsSupervisoryPhysiciansList()
     .toPromise()
     .then(res => {
       //this.supervisoryphysicianslist = res;
       //console.log(this.supervisoryphysicianslist);
       // console.log("getUsersWithPromise(): " + JSON.stringify(this.supervisoryagreementlist));
      if(this.print=='PDF')
      {
       this.printService.onDataReady(true,'Supervisory Physicians','PDF') 
      }
      if(this.print=='EXCEL')
      {
       this.printService.onDataReady(true,'Supervisory Physicians','EXCEL','test.xlsx',0,'customers') 
      }                  
     })
     .catch(err => { console.log(err) });
 
   }
   if(this.rowId==1)
   {
    
     this.supervisoryphysiciansprintService.getRecruitsSupervisoryAgreementLisbydate(this.rowIds[1],this.rowIds[2])
     .toPromise()
     .then(res => {
       //this.supervisoryphysicianslist = res;
       //console.log(this.supervisoryphysicianslist);
       // console.log("getUsersWithPromise(): " + JSON.stringify(this.supervisoryagreementlist));
       this.printService.onDataReady(true,'test') 
       
     })
     .catch(err => { console.log(err) });
   }
   
 }

}
