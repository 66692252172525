import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import { CMEHoursPrintService } from './cmehoursreport.service';
import {cisattendeeshourreport} from'./cmehoursreport.model';
@Component({
    selector: 'app-form-cmehoursreport',
    templateUrl: './cmehoursreport.component.html',
    styleUrls: ['./cmehoursreport.component.scss'] 
  })
  export class CMEHoursPrintComponent{
    invoiceIds: string[];
    invoiceDetails: Promise<any>[];
    public filterData='cisDate';
  
    invId:number;
    constructor(public service:CMEHoursPrintService, route: ActivatedRoute,
      private printService: PrintService, private http: HttpClient ){
        this.invoiceIds = route.snapshot.params['Id']
        .split(',');
        this.invId = +this.invoiceIds[0];
      
  }
  ngOnInit() {
    Promise.all([this.service.cisattendeeshourreportlist])
    .then((values ) => 
    {
      
      this.printService.onDataReady(true,'CME Certificate by Indiana University School of Medicine')
    });
   // this.filterData.cisDate = ['January 24,2020'];
   }
  
  }