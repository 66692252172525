import { Component } from '@angular/core';
import {PrintService} from './print-layout/print.service';

@Component({
  selector: 'app-root',
  // template: '<router-outlet></router-outlet>'
  template: `<div [class.isPrinting]="printService.isPrinting" id="myprint" #myprint >
                <router-outlet></router-outlet>
                 <router-outlet name="print"></router-outlet>
            </div>`
})
export class AppComponent {
  constructor(public printService: PrintService) { }
}
