import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';;
import { FormsModule} from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from  '@ng-select/ng-select'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EWFollowupsComponent } from './recruitsewfollowups-view.component';

import { DateTimePickerComponent } from '../../services';
@NgModule({
    imports: [CommonModule, FormsModule, NgbModule, FontAwesomeModule,NgSelectModule,
        NgbModalModule,
        NgbDropdownModule
    ],
    declarations: [EWFollowupsComponent, DateTimePickerComponent],
    exports: [DateTimePickerComponent,EWFollowupsComponent],

})


export class FollowupMaster {}