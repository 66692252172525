import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import {StateParaService } from '../../reports/stateparameter/stateparameter-para.service';

@Component({
    selector: 'app-form-medicallicensereportbystate',
    templateUrl: './medicallicensereportbystate.component.html',
    styleUrls: ['./medicallicensereportbystate.component.scss'] 
  })
  
  export class MedicallicensbystatePrintComponent{
    rowIds: string[];
    rowDetails: Promise<any>[];
    rowId:number;
    statename:string;
    print:string;

    
    constructor( private route: ActivatedRoute, private stateParaService:StateParaService,
      private printService: PrintService, private http: HttpClient){
        this.rowIds = route.snapshot.params['Id']
        .split(',');
        this.rowId = +this.rowIds[0];
        this.print= String(this.rowIds[1]);
        // text: `${this.commonService.transformDateFormat(element3.oExecDate, 'MM/dd/yyyy')}`, alignment: 'left', border: [false, false, false, false], color: `${this.commonService.transformDateFormat(element3.oOutDate, 'MM/dd/yyyy') != this.commonService.transformDateFormat(element3.updated_date, 'MM/dd/yyyy') ? 'red' : 'black'}` },
  }
  ngOnInit() {
    

    Promise.all([this.stateParaService.mediclalicensesbystatelist])
    .then((values ) => 
    {
     this.statename=this.stateParaService.mediclalicensesbystatelist[0].state;
   //  this.printService.onDataReady(true,'What States are our Clinicians Licensed In?','EXCEL','Medical License.xlsx',0,'commonid') 
     if(this.print=='PDF')
     {
      
     this.printService.onDataReady(true,'What States are our Clinicians Licensed In?','PDF');
     }
     if(this.print=='EXCEL')
     {
      this.printService.onDataReady(true,'What States are our Clinicians Licensed In?','EXCEL','Medical License.xlsx') 
     }
      
    });

   }
  }