import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ToastrService } from 'ngx-toastr';
import { MaskComponent } from '../../services/common/masks.pipe'
import { ReferencesService } from './recruitsreferences-view.service';
import { HttpClient ,HttpEventType} from '@angular/common/http';

@Component({
    selector: 'app-form-recruitsreferences',
    templateUrl: './recruitsreferences-view.component.html',
    styleUrls: ['./recruitsreferences-view.component.scss'] 
  })
  export class ReferencesComponent implements OnInit{
    @ViewChild('dw13') dw_13;
    Document: File = null;
    @Input() Documentvalue:string;
    public response: {'dbPath': ''};
    @Output() eventSave = new EventEmitter();
    @Output() eventClose = new EventEmitter();
    @Input() recruit_id:number;
    public mask=this.maskservice.mask;
    eventtab = {'activeId':'tab-12',
                  'nextId':'tab-13'}
    constructor(public service:ReferencesService,private maskservice:MaskComponent, private toastr: ToastrService
      ,private http: HttpClient){   
      }  
      ngOnInit(){
      this.onResetForm();
    }
    onResetForm(){
      this.response = {
        'dbPath':''
    };
        this.service.formData13 = {
          id: 0,
          recruitId: this.recruit_id,
          name: '',
          addressDetail:'',
          contact: '',
          refNo: '',
          documents:'' 
        
        };
  
      }
      async onSaveForm()
      {
    
        if(this.Documentvalue==null || this.Documentvalue=='')
        {
          await this.onFileUpload();
          this.service.formData13.documents = this.response.dbPath;
        }
              this.service.onPostRecruitsrecruitsReferencesForm().subscribe(success =>{
                  if(success) {
                      this.toastr.success('Data saved successfully!!','References');
                      this.onReset();
                      this.eventSave.emit(this.eventtab);
                      this.eventClose.emit('false');
                  }
                  else
                  {
                      this.toastr.error('Error while updating Data!!', 'References');
                  }
              })
      }  
      public onReset()
  {
    this.response = {
      'dbPath':''
  };
      this.dw_13.reset({
        id: 0,
        recruitId: this.recruit_id,
        name: '',
        addressDetail:'',
        contact: '',
        refNo: '' ,
        documents:''          
      });
  }   
      onCancelForm(){
        this.eventClose.emit('false'); 
      }  
      onFileSelected(event){
    
        if(event.target.files[0]!=null)
           
            
                this.Document = event.target.files[0];
                this.service.formData13.documents = event.target.files[0];
           
        }
      onFileUpload()
      {
          if (this.Document == null)
          {
              return this.response;
          }
          let fileToUpload = this.Document;
             const formData = new FormData();
            formData.append('Document', fileToUpload, fileToUpload.name);
              return this.http.post(`api/upload?foldername=${'Recruits'}`, formData, {observe: 'events'})
            .toPromise().then(event => {
                if (event.type === HttpEventType.Response) {
                this.uploadFinished(event.body);
                }
            });
          
      }
      public uploadFinished = (event) => {
        this.response = event;
    }
    DeleteFile()
    {
       
        if (confirm('Are you sure to delete File?'))
        {
            this.service.DeleteFile(this.service.formData13.id).subscribe(success =>{
                if(success) {
                    
                    this.toastr.info('File is Deleted','Document')
                    this.Documentvalue=null;
                  
                }
                else
                {
                    this.toastr.error('Error while Updating Data!!', 'Document');
                }
            })
        }
    }   
  }