import { Injectable } from '@angular/core';
import { HttpClient,HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import {cisattendeesreport,cisattendeessummaryreport,
    cisattendeesreportview,cisattendeesquantity,cisattendeesquantitypara} from './rptcisattendees.model'
@Injectable()
export class RptCISAttendeesPrintService{
    public printformdata:cisattendeesreport[] = [];
    public cisattendeessummaryreportlist:cisattendeessummaryreport[]=[];
    public cisattendeesreportviewlist:cisattendeesreportview[]=[];
    public cisattendeesquantitylist:cisattendeesquantity[]=[];
    public cisattendeesquantityparalist:cisattendeesquantitypara[]=[];
  
    constructor(private http: HttpClient) { }
    
    //Get
    getRecruitsCISAttendeesData( sdate:string, tdate:string ): Observable<HttpResponse<cisattendeesreport[]>> {
        return this.http.get<cisattendeesreport[]>(`api/recruits/getrecruitscisaattendeesbydate?sdate=${sdate}&tdate=${tdate}`
        , { observe: 'response' });
    }
    getRecruitsCISAttendeesSummaryData( sdate:string, tdate:string ): Observable<HttpResponse<cisattendeessummaryreport[]>> {
        return this.http.get<cisattendeessummaryreport[]>(`api/recruits/getrecruitscisaattendeessummarybydate?sdate=${sdate}&tdate=${tdate}`
        , { observe: 'response' });
    }
    getRecruitsCISAttendeereportsData( sdate:string, tdate:string ): Observable<HttpResponse<cisattendeesreportview[]>> {
        return this.http.get<cisattendeesreportview[]>(`api/recruits/getrecruitscisaattendeesreportbydate?sdate=${sdate}&tdate=${tdate}`
        , { observe: 'response' });
    }
    getRecruitsCISAttendeesquantityData( sdate:string, tdate:string ): Observable<HttpResponse<cisattendeesquantity[]>> {
        return this.http.get<cisattendeesquantity[]>(`api/recruits/getrecruitscisaattendeesquantitybydate?sdate=${sdate}&tdate=${tdate}`
        , { observe: 'response' });
    }
    getRecruitsCISAttendeesquantityPara( sdate:string, tdate:string ): Observable<HttpResponse<cisattendeesquantitypara[]>> {
        return this.http.get<cisattendeesquantitypara[]>(`api/recruits/getrecruitscisaattendeesquantitypara?sdate=${sdate}&tdate=${tdate}`
        , { observe: 'response' });
    }
}