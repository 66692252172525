import { Injectable } from '@angular/core';
import { HttpClient,HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import {multipleliveclinic,separatemutlipleliveclinic} from './multipleliveclinicreport.model'
@Injectable()
export class RecruitsMultipleliveclinicPrintService{
    public multiplelivecliniclist:multipleliveclinic[] = [];
    public separatemutliplelivecliniclist:separatemutlipleliveclinic[]=[];
    
   
  
    constructor(private http: HttpClient) { }
    

    getRecruitsMultiplelivecliniclist(): Observable<multipleliveclinic[]>  {
        return this.http.get(`api/recruits/getrecruitsmultiplelivecliniclist`)
        .map( (data:any[])=>{
            this.multiplelivecliniclist = data;
            return this.multiplelivecliniclist; 
           // return true;
        });
    }
    getRecruitsSeparateMultiplelivecliniclist(): Observable<separatemutlipleliveclinic[]>  {
        return this.http.get(`api/recruits/getrecruitsseparatemultiplelivecliniclist`)
        .map( (data:any[])=>{
            this.separatemutliplelivecliniclist = data;
            return this.separatemutliplelivecliniclist;
           // return true;
        });
    }
  
}