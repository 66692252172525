import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import { CMEReimbursementPrintService } from './cmereimbursementrereport.service';
import {recruitscmereimbursement} from'./cmereimbursementrereport.model';
@Component({
    selector: 'app-form-cmereimbursementrereport',
    templateUrl: './cmereimbursementrereport.component.html',
    styleUrls: ['./cmereimbursementrereport.component.scss'] 
  })
  export class CMEReimbursementPrintComponent{
    rowIds: string[];
    rowDetails: Promise<any>[];
    fetch: any[] = [];
    rowId:number;
    recruitscmereimbursementlist:recruitscmereimbursement[]=[];
    name:string;
    year:number;
    cmehours:number;
    cmeallowance:number;
    incmehours:number;
    incmeallowance :number;
    constructor( private route: ActivatedRoute, private cmereimbursementPrintService:CMEReimbursementPrintService,
        private printService: PrintService,
        private http: HttpClient ){
          this.rowIds = route.snapshot.params['Id']
          .split(',');
          this.rowId = +this.rowIds[0];
        
    }
    ngOnInit() {
   

        if (this.rowId!=0)
        {
         
         this.cmereimbursementPrintService.getRecruitsCMEReimbursementlist(Number(this.rowId))
         .toPromise()
         .then(res => {
           this.recruitscmereimbursementlist = res;
          
           this.name=this.recruitscmereimbursementlist[0].firstname+' '+this.recruitscmereimbursementlist[0].lastname+', '+this.recruitscmereimbursementlist[0].degree 
           this.year=this.recruitscmereimbursementlist[0].year;
         
           this.printService.onDataReady(true,'CME Reimbursement') 
        
          
          
           
         })
         .catch(err => { console.log(err) });
     
       }
      
     }
  }