import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
  @Input()
  heading: string;
  isAuthorized: boolean;
  currentUser: string;
  profileImage:string;
  @Output()
  toggleSidenav = new EventEmitter<void>();

  constructor(private router:Router){}

  ngOnInit(){
    if (localStorage.getItem('token') != null)
    {
      this.isAuthorized = true
      this.currentUser = localStorage.getItem('username')
      this.profileImage = localStorage.getItem('profileImage')
    }
    else{
      this.router.navigate(['/account/signin']);
    }
  }
}
