import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ToastrService } from 'ngx-toastr';
import { EmployerService } from './recruitsemployer-view.service';
@Component({
    selector: 'app-form-recruitsemployer',
    templateUrl: './recruitsemployer-view.component.html',
    styleUrls: ['./recruitsemployer-view.component.scss'] 
  })
  export class EmployerComponent implements OnInit{
    @ViewChild('dw12') dw_12;
   
    @Output() eventSave = new EventEmitter();
    @Output() eventClose = new EventEmitter();
    @Input() recruit_id:number;
    eventtab = {'activeId':'tab-11',
                  'nextId':'tab-12'}
    constructor(public service:EmployerService, private toastr: ToastrService){   
      }  
      ngOnInit(){
      this.onResetForm();
    }
    onResetForm(){
        this.service.formData12 = {
          id: 0,
          recruitId: this.recruit_id,
          employer: '',
          addressDetail:'',
          designation: '',
          fromDate: '',
          toDate: ''
        
        };
  
      }
      async onSaveForm()
      {
              this.service.onPostRecruitsrecruitsemployerForm().subscribe(success =>{
                  if(success) {
                      this.toastr.success('Data saved successfully!!','Employer');
                      this.onReset();
                      this.eventSave.emit(this.eventtab);
                      this.eventClose.emit('false');
                  }
                  else
                  {
                      this.toastr.error('Error while updating Data!!', 'Employer');
                  }
              })
      }  
      public onReset()
  {
 
      this.dw_12.reset({
        id: 0,
        recruitId: this.recruit_id,
        employer: '',
        addressDetail:'',
        designation: '',
        fromDate: '',
        toDate: ''           
      });
  }   
      onCancelForm(){
        this.eventClose.emit('false'); 
      }  
  }