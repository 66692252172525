import { Component, OnInit, ViewChild,Input, Output, EventEmitter,TemplateRef, ElementRef} from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { HttpResponse ,HttpEventType} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { recruitstbl ,statuspara,affiliationscategory,recruiteducation,
recruitboard,recruitshonors,recruitsmembership,recruitsfellowship,recruitsresidencies,
recruitsinternships,recruitsaffiliations,recruitsemployer,recruitsreferences,recruitsmalpractice,recruitsmedicallicenses
,recruitsotherdetails,recruitsclinicalexperience,recruitsewexperience,recruitsewfollowups,recruitscisAattendees
,recruitscliniclocation,recruitsinterview,recruitsinterviewform,recruitsinterviewby,recruitsotherdetailsview,
boardCertifiednamelist ,recruitscmocorner,recruitsexitinterview,recruitscmocornerlist
,recruitsfte,
clinicalleadrs} from './recruits-view.model';
import { RecruitsviewService } from './recruits-view.service';
import { faSmileBeam } from '@fortawesome/free-regular-svg-icons';
import { Country, States, Cities,TimeZone } from '../services/common/common.model';
import { CommonService } from '../services/common/common.service'
import { MaskComponent } from '../services/common/masks.pipe'
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { NgbTabset, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EducationService} from './recruitseducation/recruitseducation-view.service';
import {BoardCertificationService} from './recruitsboard/recruitsboard-view.service'
import {HonorsService} from './recruitshonors/recruitshonors-view.service'
import {MembershipService} from './recruitsmembership/recruitsmembership-view.service'
import {FellowshipService} from './recruitsfellowship/recruitsfellowship-view.service'
import {ResidenciesService} from './recruitsresidencies/recruitsresidencies-view.service'
import {InternshipsService} from './recruitsinternships/recruitsinternships-view.service'
import {AffiliationsService} from'./recruitsaffiliations/recruitsaffiliations-view.service'
import {EmployerService} from './recruitsemployer/recruitsemployer-view.service'
import {ReferencesService} from './recruitsreferences/recruitsreferences-view.service'
import {MalpracticeService} from './recruitsmalpractice/recruitsmalpractice-view.service'
import {MedicalLicenseService} from './recruitsmedicallicense/recruitsmedicallicense-view.service'
import { OtherDetailsComponent } from './recruitsotherdetails/recruitsotherdetails-view.component'
import {EWFollowupsService} from './recruitsewfollowups/recruitsewfollowups-view.service'
import {CISAttendeesService} from './recruitscisattendees/recruitscisattendees-view.service'
import {OtherDetailsService} from './recruitsotherdetails/recruitsotherdetails-view.service'
import {LiveClinicFormService} from'../liveclinic/shared/liveclinic-form.service'
import {CMOCornerService} from'./recruitscmocorner/recruitscmocorner-view.service'
import {FTEService} from './recruitsfte/recruitsfte-view.service'
import { NgxSpinnerService } from 'ngx-spinner';
import{FTEComponent} from './recruitsfte/recruitsfte-view.component'
import {PrintService} from '../print-layout/print.service'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
    selector: 'app-form-recruits',
    templateUrl: './recruits-view.component.html',
    styleUrls: ['./recruits-view.component.scss'],
   
  })
  export class RecruitsviewComponent implements OnInit{
    recruitsmodel:recruitstbl;
    public statusform:statuspara[] = [];
    public categoryform:affiliationscategory[] = [];
    public recruiteducationlist:recruiteducation[] = [];
    public recruitboardlist:recruitboard[] = [];
    public recruitshonorslist:recruitshonors[]=[];
    public recruitsmembershiplist:recruitsmembership[]=[];
    public recruitsfellowshiplist:recruitsfellowship[]=[];
    public recruitsresidencieslist:recruitsresidencies[]=[];
    public recruitsinternshipslist:recruitsinternships[]=[];
    public recruitsaffiliationslist:recruitsaffiliations[]=[];
    public recruitsemployerlist:recruitsemployer[]=[];
    public recruitsreferenceslist:recruitsreferences[]=[];
    public recruitsmalpracticelist:recruitsmalpractice[]=[];
    public recruitsmedicallicenseslist:recruitsmedicallicenses[]=[];
    public recruitsotherdetailslist:recruitsotherdetailsview[]=[];
    public recruitsewfollowupslist:recruitsewfollowups[]=[];
    public recruitsclinicalexperiencelist:recruitsclinicalexperience;
    public recruitsewexperiencelist:recruitsewexperience;
    public recruitscisAattendeeslist:recruitscisAattendees[]=[];
    public recruitsclinicanlocationlist:recruitscliniclocation[]=[];
    public recruitsinterviewlist:recruitsinterview;
    public recruitsinterviewformlist:recruitsinterviewform;
    public recruitsinterviewbylist:recruitsinterviewby[]=[];
    public boardCertifiednamelists :boardCertifiednamelist[]=[];
    public recruitscmocornerlist :recruitscmocorner[]=[];
    public recruitscmocornerviewlist:recruitscmocornerlist[]=[];
    public recruitsexitinterviewlist:recruitsexitinterview;
    public recruitsftelist:recruitsfte[]=[];
    public recruitsfteyearlist:recruitsfte[]=[];
    public clinicleader:clinicalleadrs[]=[];
    country:Country[] = [];
    states:States[] = [];
    timezone:TimeZone[] = [];
    @Input() cities:Cities[] = [];
    ColumnMode = ColumnMode;
    SelectionType = SelectionType;
    profileimage: File = null;
    CVDocument:File=null;
    @Input()  profileimagevalue:string;
    @Input()  CVDocumentvalue:string;
    @ViewChild('dw1') dw_1; 
    @ViewChild('dw17') dw_17; 
    @ViewChild('dw20') dw_20; 
    @ViewChild('dw23') dw_23; 
    @ViewChild(FTEComponent) dw_fte:FTEComponent;
    @ViewChild(OtherDetailsComponent) dw2: OtherDetailsComponent;
    @ViewChild('cvprofile') content: ElementRef;
    @Input() eventName:string;
    @Input() recruit_id:number;
    @Input() isAdd:boolean;
    @Input() isewnn:boolean;
    @Input() cvprint:boolean;
    @Input() cvtype:string;
    @Input() texteditable:boolean;
    @Input() isnewrecruits:boolean;
    @Input() isClinicalLeader:boolean;
    @Output() eventCancel = new EventEmitter();
    @Output() refreshForm = new EventEmitter();
    IsCheckedCertified:boolean;
    IsCheckedEligibles:boolean;
    //IsClinicalLeader:boolean;
    public boardplaceholder:string;
   
    public response: {'dbPath': ''};
    public mask=this.maskservice.mask;
    public isLoading: boolean = false;
    public iseducation:boolean = false;
    public isboard:boolean = false;
    public ishonors:boolean=false;
    public ismembership:boolean=false;
    public isfellowship:boolean=false;
    public isresidencies:boolean=false;
    public isinternships:boolean=false;
    public isaffiliations:boolean=false;
    public isemployer:boolean=false;
    public isreferences:boolean=false;
    public ismalpractice :boolean=false;
    public ismedicallicenses:boolean=false;
    public isotherdetails:boolean=false;
    public isewfollowup:boolean=false;
    public isbuttonvisible:boolean=true;
    public isCISAattendees:boolean=false;
    public iscliniclocation:boolean=false;
    public iseditcliniclocation:boolean=false;
    public iscmocorner:boolean=false;
    public isfte:boolean=false;
    public iscmefte:boolean=false;
    public iscmefteyear:boolean=true;
    public tabname:string;
    public columndatatype:string;
    public addressDetail:number=-1;
    public modelTitle = '';
    companyid = localStorage.getItem('companyid');
    userid = localStorage.getItem('userid');
    Documentvalue:string;
    @ViewChild('modalContent')
  modalContent: TemplateRef<any>;
  dateNow : Date = new Date();
  currentYearDate: number;
  public fromdate:string;
//public cvprint:boolean = false;

  //Filter by variables
  public filterDataControlledMedicalLicense:any={};
  public filterDataMedicalLicense:any={};
  public filterDataDeaLicense:any={};
  public filterDataOtherLicense:any={};
  public filterDataState:any={};
  public filterDatacoloumn:any={};
  public filteraggrementsignedon:any={};
  public filterofferlettersignedon:any={};
  public filterDatacoloumnCompensationFund:any={};
  ratinglist=[
    {id:'Choose...',value:''},
    {id:'0',value:'0'},
    {id:'1',value:'1'},
    {id:'2',value:'2'},
    {id:'3',value:'3'},
    {id:'4',value:'4'},
    {id:'5',value:'5'},
    {id:'6',value:'6'},
    {id:'7',value:'7'},
    {id:'8',value:'8'},
    {id:'9',value:'9'}  
  ]
  agreementStatuslist=[
    {id:'Signed',value:'Signed'},
    {id:'Pending',value:'Pending'}
    
  ]
  Degreeslist=[
    {id:'Choose...',value:''}, 
    {id:'MD',value:'MD'},
    {id:'DO',value:'DO'} ,
    {id:'NP',value:'NP'},
    {id:'PA',value:'PA'},  
    {id:'DC',value:'DC'}
  ]
  RehireAbilitylist=[
    {id:'Choose...',value:''},
    {id:'0',value:'0'},
    {id:'1',value:'1'},
    {id:'2',value:'2'},
    {id:'3',value:'3'},
    {id:'4',value:'4'},
    {id:'5',value:'5'},
    {id:'6',value:'6'},
    {id:'7',value:'7'},
    {id:'8',value:'8'},
    {id:'9',value:'9'} , 
    {id:'10',value:'10'} 
  ]
////
rows3=new Array<recruiteducation>();
selected3 = [];
public filter3 = 'undefined';
public temp3 = [];
@ViewChild('myTable3') table3;
////
////
rows2=new Array<recruitsotherdetailsview>();
selected2 = [];
public filter2 = 'undefined';
public temp2 = [];
@ViewChild('myTable2') table2;
////
////
rows4=new Array<recruitsmedicallicenses>();
selected4 = [];
public filter4 = 'undefined';
public temp4 = [];
@ViewChild('myTable4') table4;
////
////
rows5=new Array<recruitboard>();
selected5 = [];
public filter5 = 'undefined';
public temp5 = [];
@ViewChild('myTable5') table5;
////
////
rows6=new Array<recruitshonors>();
selected6= [];
public filter6 = 'undefined';
public temp6 = [];
@ViewChild('myTable6') table6;
////
////
rows7=new Array<recruitsmembership>();
selected7= [];
public filter7 = 'undefined';
public temp7 = [];
@ViewChild('myTable7') table7;
////
////
rows8=new Array<recruitsfellowship>();
selected8= [];
public filter8 = 'undefined';
public temp8 = [];
@ViewChild('myTable8') table8;
////
////
rows9=new Array<recruitsresidencies>();
selected9= [];
public filter9 = 'undefined';
public temp9 = [];
@ViewChild('myTable9') table9;
////
////
rows10=new Array<recruitsinternships>();
selected10= [];
public filter10 = 'undefined';
public temp10 = [];
@ViewChild('myTable10') table10;
////
////
rows11=new Array<recruitsaffiliations>();
selected11= [];
public filter11 = 'undefined';
public temp11 = [];
@ViewChild('myTable11') table11;
////
////
rows12=new Array<recruitsemployer>();
selected12= [];
public filter12 = 'undefined';
public temp12 = [];
@ViewChild('myTable12') table12;
////
////
rows13=new Array<recruitsreferences>();
selected13= [];
public filter13 = 'undefined';
public temp13 = [];
@ViewChild('myTable13') table13;
////
////
rows14=new Array<recruitsmalpractice>();
selected14= [];
public filter14 = 'undefined';
public temp14 = [];
@ViewChild('myTable14') table14;
////
////
rows15=new Array<recruitsewfollowups>();
selected15= [];
public filter15 = 'undefined';
public temp15 = [];
@ViewChild('myTable15') table15;
////
rows18=new Array<recruitscisAattendees>();
selected18= [];
public filter18 = 'undefined';
public temp18 = [];
@ViewChild('myTable18') table18;
////
////
rows19=new Array<recruitscliniclocation>();
selected19= [];
public filter19 = 'undefined';
public temp19 = [];
@ViewChild('myTable19') table19;
////

////
rows21=new Array<recruitscmocornerlist>();
selected21= [];
public filter21 = 'undefined';
public temp21 = [];
@ViewChild('myTable21') table21;
////
////
rows22=new Array<recruitsotherdetailsview>();
selected22 = [];
public filter22 = 'undefined';
public temp22 = [];
@ViewChild('myTable22') table22;
////
////
rows24=new Array<recruitsfte>();
selected24 = [];
public filter24 = 'undefined';
public temp24 = [];
@ViewChild('myTable24') table24;
////
@ViewChild('tabset') public tabset:NgbTabset;

////
    constructor(public service:RecruitsviewService,private commonService:CommonService, 
        private toastr: ToastrService, private http: HttpClient,private maskservice:MaskComponent,
        private educationService:EducationService,private modal: NgbModal, 
        private boardCertificationService:BoardCertificationService,
        private honorsService:HonorsService, private membershipService:MembershipService,
        private fellowshipService:FellowshipService,private residenciesService:ResidenciesService,
        private internshipsService:InternshipsService ,private affiliationsService:AffiliationsService,
        private employerService:EmployerService,private referencesService:ReferencesService,
        private malpracticeService:MalpracticeService,private medicallicenseService:MedicalLicenseService,
        private ewfollowupsService:EWFollowupsService, private spinner: NgxSpinnerService
        ,private cisattendeesService:CISAttendeesService,
        private otherdetailsService:OtherDetailsService,
        private liveClinicFormService:LiveClinicFormService,
        private cmocornerService:CMOCornerService,
        private fteService:FTEService,
        private printService:PrintService
        ){
            this.filterDataControlledMedicalLicense = {
                'licenses':['Controlled Substance']};

            this.filterDataState.stateId = [3935];
            this.filterDataMedicalLicense.licenses = ['Medical License'];
            this.filterDataDeaLicense.licenses = ['DEA'];
            this.filterDataOtherLicense.licenses = ['ACLS', 'CDL', 'BLS', 'NRP', 'ATLS', 'PALS'];
            this.filterDatacoloumn.columnName=['NPDB Report'];
            this.filteraggrementsignedon.columnName=['Agreement Signed On'];
            this.filterofferlettersignedon.columnName=['Offer Letter Signed On'];
            this.filterDatacoloumnCompensationFund.columnName=['Supervisory MD with Patient Compensation Fund'];
    }

    ngOnInit(){
       
        this.onReset();
        
        this.service.OnGetClinicalLeadrespara().subscribe(success =>{
            if(success) {
                this.clinicleader = this.service.Leaders;
                
            }
        })

        this.service.OnGetStatuspara().subscribe(success =>{
            if(success) {
                this.statusform = this.service.status;
            }
        })
        this.service.OnGetAffiliationscategory(parseInt(this.companyid)).subscribe(success =>{
            if(success) {
                this.categoryform = this.service.category;
            }
    
        }) 
        this.commonService.getCountry().subscribe(success =>{
            if(success) {
                this.country = this.commonService.counrty;
                
            }
        })  
        this.commonService.getTimeZone().subscribe(success =>{
            if(success) {
                this.timezone = this.commonService.timezone;
            }
        })  
        this.commonService.getStates(this.service.recruitview.countryId).subscribe(success =>{
            if(success) {
                this.states = this.commonService.states;
            }
        })   
         this.commonService.getCities(this.service.recruitview.stateId).subscribe(success =>{
          if(success) {
              this.cities = this.commonService.cities;
            }
          })
          if(this.service.recruitview.boardCertified==false && this.service.recruitview.boardEligibles==false)
          {
              this.texteditable=false;
              this.boardplaceholder='Select Board Certified/Board Eligible'
          }
          this.service.OnGetRecruitsInterviewby().subscribe(success =>{
            if(success) {
                this.recruitsinterviewbylist = this.service.recruitsinterviewbylist;
            }
        })
        this.currentYearDate=Number(new Date().getFullYear());
        
       // this.getyear();
    }
    showSpinner(name: string) {
        this.spinner.show(name);
        setTimeout(() => {
            this.spinner.hide(name);
          }, 5000);
      }
    
      hideSpinner(name: string) {
        this.spinner.hide(name);
      }
      getyear()
      {
        var year = new Date().getFullYear();
        var range = [];
        
        for (var i = 0; i < 7; i++) {
            range.push({
              label: year + i,
              value: parseInt(String(year + i).slice(2, 4))
            });

        }
        console.log('Year'+range)
      }
    public onReset()
    {
        this.isClinicalLeader=false;
        this.response = {
            'dbPath':''
        };
        this.service.recruitview = {
            id: 0,
            cvtype: this.cvtype,
            firstName: '',
            middleName: '',
            lastName: '',
            title: '',
            specialty:'',
            dob: '',
            addressLine1: '',
            addressLine2: '',
            cityId: 0,
            stateId: 0,
            countryId: 231,
            zip: '',
            timeZone: '',
            primaryContact: '',
            primaryEmail: '',
            alternateContact: '',
            alternateEmail: '',
            profileImage: '',
            cvdoc: '',
            ratings: 0,
            status: '',
            dateOfHire:'',
            source:'',
            keywords: '',
            location:'',
            comments: '',
            clinicalExperience: '',
            residency:'',
            boardEligible:'',
            closeOut:'',
            reason:'',
            active: true,
            userId: 0,
            ewType: false,
            currentOccupation: '',
            agreementStatus:'',
            emergencyName:'',
            emergencyContact:'',
            boardCertified:false,
            boardEligibles:false,
            degree:'',
            alternateFirstname:'',
            attorneyContact:'',
            attorneyEmail:'',
            isSupervisoryMd:false,
            clinicalLeader:false,
            clinicalLeaderName:''

        };
        this.service.recruitsclinicalexperiencelist=
        {
            cwid:0,
            recruitId: this.recruit_id,
            clinicalExperience: '',
            avgClinicalHourbyMonth: '',
            mostRecentClinicalExperience: '',
            totalClinicianHoursLastYear:'',
            hospitalDisciplinaryActions: '',
            malpracticeCases: '',
            npdbCases: '',
            medicalLicenceDisciplinaryAction:''
        };
        this.service.recruitsewexperiencelist=
        {
            eid: 0,
            recruitId:  this.recruit_id,
            casesPlaintiff: 0,
            casesDefence: 0,
            totalDepositionsPlaintiff: 0,
            totalDepositionsDefence: 0,
            depositionsPlaintiff: 0,
            depositionsDefence: 0,
            totalTrialPlaintiff: 0,
            totalTrialDefence: 0,
            trialPlaintiff: 0,
            trialDefence: 0,
            totalYearsAsExpert:  0,
            ratePerHour: 0,
            depositions: 0,
            trial: 0,
            minimumRetainer:'',
            depoNotes: '',
            trialNotes:''
        };
        this.service.recruitsinterviewlist=
        {
            id: 0,
            recruitId: this.recruit_id,
            recruitFormId: 0,
            post: '',
            interviewDate: '',
            interviewBy: '',
            totalMarks: 0,
            totalScore: 0,
            comments: '',
            secondLevel: false,
            active:true,
            updatedBy:Number(this.userid),
            updatedDt:this.dateNow
        }
        this.modelTitle = '';
         this.tabset.activeId = 'tab-1';
        this.rows3 = null;

       this.cvprint=false;
        this.commonService.recruitcvprofile = {
            id: 0,
            cvtype: this.cvtype, 
            firstName: '', 
            middleName: '', 
            lastName: '', 
            title: '', 
            specialty: '', 
            dob: null, 
            addressLine1: '', 
            addressLine2: '', 
            cityId: null,
            city: '', 
            stateId: null,
            state: '', 
            countryId: null,
            country: '', 
            zip: '', 
            timeZone: '', 
            primaryContact: '', 
            primaryEmail: '', 
            alternateContact: '', 
            alternateEmail: '', 
            profileImage: '', 
            cvdoc: '', 
            ratings: null, 
            status: '', 
            dateOfHire: null, 
            source: '', 
            keywords: '', 
            location: '', 
            comments: '', 
            clinicalExperience: '', 
            residency: '', 
            boardEligible: '', 
            closeOut: '', 
            reason: '', 
            active: true,
            userId: 0,
            ewType: '', 
            currentOccupation: '', 
            emergencyName:'',
            emergencyContact:'',
            boardCertified: false,
            boardEligibles: false,
            degree: '',
            alternateFirstname:'',
            attorneyContact: '',
            attorneyEmail: '',
            recruitsAffiliations: null, 
            recruitsBoardCertification: null, 
            recruitsEducation: null, 
            recruitsEmployer: null, 
            recruitsFellowship: null, 
            recruitsHonours: null, 
            recruitsInternships: null, 
            recruitsMalpractice: null, 
            recruitsMedicalLicenses: null, 
            recruitsMembership: null, 
            recruitsOtherdetail: null, 
            recruitsReferences: null, 
            recruitsResidencies: null,
            recruitsEwexperience: null,
            recruitsClinicalexperience: null               
        }
        this.service.recruitsexitinterviewlist={
            id: 0,
            recruitId: this.recruit_id,
            exitinterviewDate: '',
            rehireAbility: '',
            commentby: '',
            comments: '',
            updatedBy:Number(this.userid),
            updatedDt:this.dateNow
        }

    }
    onSelectContactClick(id:number)
    {

        this.service.onGetRecruits(id).subscribe(success =>{
            if(success) {
                this.recruitsmodel = this.service.recruitview;           
            }
        })               
    }
    onProfileUpload()
    {
        if (this.profileimage == null)
        {
            return this.response;
        }
        let fileToUpload = this.profileimage;
        var profilesize=fileToUpload.size;
        if (profilesize<5000000)
        {
          const formData = new FormData();
          formData.append('profileimage', fileToUpload, fileToUpload.name);
    
          return this.http.post(`api/upload?foldername=${'Recruits'}`, formData, {observe: 'events'})
          .toPromise().then(event => {
              if (event.type === HttpEventType.Response) {
              this.uploadFinished(event.body);
              }
          });
        }
        else
        {
            this.toastr.error('Filesize must be less than 5 MB', 'Profile');
        }
        
    }
    onCVDocumentUpload()
    {
        if (this.CVDocument == null)
        {
            return this.response;
        }
        let fileToUpload = this.CVDocument;
        var CVDocumentsize=fileToUpload.size;
       
          const formData = new FormData();
          formData.append('CVDocument', fileToUpload, fileToUpload.name);
    
          return this.http.post(`api/upload?foldername=${'Recruits'}`, formData, {observe: 'events'})
          .toPromise().then(event => {
              if (event.type === HttpEventType.Response) {
              
             this.uploadFinished(event.body);
             
              }
          });
      
       
        
    }
    public uploadFinished = (event) => {
      this.response = event;
  }
    onFileSelected(event){
    
        if(event.target.files[0]!=null)
            var profilesize=event.target.files[0].size;
            
            if (profilesize<5000000)
            {
                this.profileimage = event.target.files[0];
                this.service.recruitview.profileImage = event.target.files[0];
            }
            else
            {
                this.profileimage=null;
                this.service.recruitview.profileImage=null;
                this.toastr.error('Filesize must be less than 5 MB', 'Recruits');
            }
           
        }
        onCVDocumentSelected(event){
        
            if(event.target.files[0]!=null)
                var CVDocumentsize=event.target.files[0].size;
                
              
                    this.CVDocument = event.target.files[0];
                    this.service.recruitview.cvdoc = event.target.files[0];
              
                    // this.CVDocument=null;
                    // this.service.recruitview.cvdoc=null;
                  
               
            }
DeleteFile(fieldfile)
{
    
    if (confirm('Are you sure to delete File?'))
    {
        this.service.DeleteFile(this.service.recruitview.id,fieldfile).subscribe(success =>{
            if(success) {
                
                this.toastr.info('File is deleted','Recruits')
                if (fieldfile== "ProfileImage")
                {
                    this.profileimagevalue=null;
                }
                if (fieldfile == "cvdoc")
                {
                    this.CVDocumentvalue=null;
                }
               
             
            }
            else
            {
                this.toastr.error('Error while updating Data!!', 'Recruits');
            }
        })
    }
}
onStateChange(id:number)
    {
        this.service.recruitview.cityId=null; 
        this.commonService.getCities(id).subscribe(success =>{
            if(success) {
                this.cities = this.commonService.cities;
            }
        })      
    }

    onCountryChange(id:number){
        this.service.recruitview.stateId=null;
        this.service.recruitview.cityId=null;
        this.commonService.getStates(id).subscribe(success =>{
            if(success) {
                this.states = this.commonService.states;
            }
        })         
    }
    async onSaveForm()
    {
            if(this.profileimagevalue==null || this.profileimagevalue=='')
             {
                await this.onProfileUpload();
                this.service.recruitview.profileImage = this.response.dbPath;
             }
                
            this.response = {
                'dbPath':''
            };
           
            if (this.CVDocumentvalue==null || this.CVDocumentvalue=='' )
            {
                
            await this.onCVDocumentUpload();
            this.service.recruitview.cvdoc = this.response.dbPath;
            }
            var date=new Date(this.service.recruitview.clinicalExperience).getFullYear();
            this.service.recruitview.clinicalExperience= String(date);
            
        this.service.onPostForm().subscribe(success =>{
            if(success) {
                
                this.toastr.success('Data saved successfully!!','Recruits');
                this.onReset();
                this.isAdd = false;
                this.isClinicalLeader=false;
                this.eventCancel.emit('true');                 
            }
            else
            {
                this.toastr.error('Error while updating Data!!', 'Recruits');
            }
        })

}
async onSaveExperienceForm()
{
    this.service.recruitsclinicalexperiencelist.recruitId=this.recruit_id;
   
    this.service.onPostClinicalExperience().subscribe(success =>{
        if(success) {
            
            this.toastr.success('Data saved successfully!!','Clinical Experience');
            this.onTabChange({'activeId':'tab-16','nextId':'tab-17'})
                          
        }
        else
        {
            this.toastr.error('Error while updating Data!!', 'Clinical Experience');
        }
    })
}
async onSaveInterviewForm()
{
    this.service.recruitsinterviewlist.recruitId=this.recruit_id;
    this.service.recruitsinterviewlist.post= this.service.recruitview.degree;
    this.service.onGetRecruitsInterviewformrecord().subscribe(success =>{
        if(success) {
            this.recruitsinterviewformlist=this.service.recruitsinterviewformlist;
            this.service.recruitsinterviewlist.recruitFormId=this.recruitsinterviewformlist.id;
          this.service.onPostInterview().subscribe(success =>{
        if(success) {
            
            this.toastr.success('Data saved successfully!!','Interview ');
            this.onTabChange({'activeId':'tab-18','nextId':'tab-20'})
                          
        }
        else
        {
            this.toastr.error('Error while updating Data!!', 'Interview ');
        }
    })
        }
    
    })
    
    
}
async onSaveEWExperienceForm()
{
    this.service.recruitsewexperiencelist.recruitId=this.recruit_id;
    this.service.onPostEWExperience().subscribe(success =>{
        if(success) {
            
            this.toastr.success('Data saved successfully!!','Expertwitness Experience');
            this.onTabChange({'activeId':'tab-1','nextId':'tab-16'})
                          
        }
        else
        {
            this.toastr.error('Error while updating Data!!', 'Expertwitness Experience');
        }
    })
}
async onSaveExitInterviewForm()
{
    this.service.recruitsexitinterviewlist.recruitId=this.recruit_id;
    this.service.onPostExitInterview().subscribe(success =>{
        if(success) {
            
            this.toastr.success('Data saved successfully!!','');
            this.onTabChange({'activeId':'tab-20','nextId':'tab-23'})
                          
        }
        else
        {
            this.toastr.error('Error while updating Data!!', '');
        }
    })
}
onDeleteForm()
{
    if (confirm('Are you sure to delete this record?'))
    {
        this.service.onDeleteForm(this.service.recruitview.id).subscribe(success =>{
            if(success) {
                this.toastr.info('Data deleted successfully!!','Recruits')
                this.onReset();
                this.eventCancel.emit('true');                 
            }
            else
            {
                this.toastr.error('Error while updating Data!!', 'Recruits');
            }
        })
    }
}
onCancelForm()
{
     this.onReset();
      this.eventCancel.emit('true');      
}

onTabChange(event: any)
{
    let tab = event;
    
    switch(tab.nextId){
        case 'tab-2':
            
            if (this.service.recruitview.id!=0)
          //  this.isotherdetails=true;
            this.isLoading = true; 
            this.service.onGetRecruitsOtherPrivilegesDetailsbyrid(this.service.recruitview.id,false).subscribe(success =>{
                if(success) {
                    
                    // this.dw2.recruitsotherdetailstable = this.service.recruitsotherdetailslist;
                    // this.dw2.onOtherRetrieve();
                    // this.isLoading = false;
                    this.recruitsotherdetailslist = this.service.recruitsotherdetailslist;
                    this.temp2 = [...this.recruitsotherdetailslist];
                    this.rows2 = this.recruitsotherdetailslist;
                    this.isLoading = false;        
                }
            })
         
        break;
        case 'tab-3':
                if (this.service.recruitview.id!=0)
                this.isLoading = true;   
                this.service.onGetRecruitsEductionbyrid(this.service.recruitview.id).subscribe(success =>{
                    if(success) {
                        this.recruiteducationlist = this.service.recruiteducationlist;
                        this.temp3 = [...this.recruiteducationlist];
                        this.rows3 = this.recruiteducationlist;
                        this.isLoading = false;    
                    }
                })
        break;
        case 'tab-4':
                if (this.service.recruitview.id!=0)
                this.isLoading = true;   
                this.service.onGetRecruitsMedicalLicensesbyrid(this.service.recruitview.id,'undefined').subscribe(success =>{
                    if(success) {
                        this.recruitsmedicallicenseslist = this.service.recruitsmedicallicenseslist;
                        this.temp4 = [...this.recruitsmedicallicenseslist];
                        this.rows4 = this.recruitsmedicallicenseslist;
                        this.isLoading = false;    
                    }
                })
        break;
        case 'tab-5':
            if (this.service.recruitview.id!=0)
            this.isLoading = true;   
            this.service.onGetRecruitsBoardbyrid(this.service.recruitview.id).subscribe(success =>{
                if(success) {
                    this.recruitboardlist = this.service.recruitboardlist;
                 
                    this.temp5 = [...this.recruitboardlist];
                    this.rows5 = this.recruitboardlist;
                    this.isLoading = false;    
                }
            })
    break;
    case 'tab-6':
            if (this.service.recruitview.id!=0)
            this.isLoading = true;   
            this.service.onGetRecruitsHonorsbyrid(this.service.recruitview.id).subscribe(success =>{
                if(success) {
                    this.recruitshonorslist = this.service.recruitshonorslist;
                    this.temp6 = [...this.recruitshonorslist];
                    this.rows6 = this.recruitshonorslist;
                    this.isLoading = false;    
                }
            })
    break;
    case 'tab-7':
            if (this.service.recruitview.id!=0)
            this.isLoading = true;   
            this.service.onGetRecruitsMembershipbyrid(this.service.recruitview.id).subscribe(success =>{
                if(success) {
                    this.recruitsmembershiplist = this.service.recruitsmembershiplist;
                    this.temp7 = [...this.recruitsmembershiplist];
                    this.rows7 = this.recruitsmembershiplist;
                    this.isLoading = false;    
                }
            })
    break;
    case 'tab-8':
            if (this.service.recruitview.id!=0)
            this.isLoading = true;   
            this.service.onGetRecruitsFellowshipbyrid(this.service.recruitview.id).subscribe(success =>{
                if(success) {
                    this.recruitsfellowshiplist = this.service.recruitsfellowshiplist;
                    this.temp8 = [...this.recruitsfellowshiplist];
                    this.rows8 = this.recruitsfellowshiplist;
                    this.isLoading = false;    
                }
            })
    break;
    case 'tab-9':
            if (this.service.recruitview.id!=0)
            this.isLoading = true;   
            this.service.onGetRecruitsResidenciesbyrid(this.service.recruitview.id).subscribe(success =>{
                if(success) {
                    this.recruitsresidencieslist = this.service.recruitsresidencieslist;
                    this.temp9 = [...this.recruitsresidencieslist];
                    this.rows9 = this.recruitsresidencieslist;
                    this.isLoading = false;    
                }
            })
    break;
    case 'tab-10':
        if (this.service.recruitview.id!=0)
        this.isLoading = true;   
        this.service.onGetRecruitsInternshipsbyrid(this.service.recruitview.id).subscribe(success =>{
            if(success) {
                this.recruitsinternshipslist = this.service.recruitsinternshipslist;
                this.temp10 = [...this.recruitsinternshipslist];
                this.rows10 = this.recruitsinternshipslist;
                this.isLoading = false;    
            }
        })
    break;
    case 'tab-11':
        if (this.service.recruitview.id!=0)
        this.isLoading = true;   
        this.service.onGetRecruitsAffiliationsbyrid(this.service.recruitview.id).subscribe(success =>{
            if(success) {
                this.recruitsaffiliationslist = this.service.recruitsaffiliationslist;
                this.temp11 = [...this.recruitsaffiliationslist];
                this.rows11 = this.recruitsaffiliationslist;
                this.isLoading = false;    
            }
        })
    break;
    case 'tab-12':
        if (this.service.recruitview.id!=0)
        this.isLoading = true;   
        this.service.onGetRecruitsEmployerbyrid(this.service.recruitview.id).subscribe(success =>{
            if(success) {
                this.recruitsemployerlist = this.service.recruitsemployerlist;
                this.temp12 = [...this.recruitsemployerlist];
                this.rows12 = this.recruitsemployerlist;
                this.isLoading = false;    
            }
        })
    break;
    case 'tab-13':
        if (this.service.recruitview.id!=0)
        this.isLoading = true;   
        this.service.onGetRecruitsReferencesbyrid(this.service.recruitview.id).subscribe(success =>{
            if(success) {
                this.recruitsreferenceslist = this.service.recruitsreferenceslist;
                this.temp13 = [...this.recruitsreferenceslist];
                this.rows13 = this.recruitsreferenceslist;
                this.isLoading = false;    
            }
        })
    break;
    case 'tab-14':
        if (this.service.recruitview.id!=0)
        this.isLoading = true;   
        this.service.onGetRecruitsMalpracticebyrid(this.service.recruitview.id).subscribe(success =>{
            if(success) {
                this.recruitsmalpracticelist = this.service.recruitsmalpracticelist;
                this.temp14 = [...this.recruitsmalpracticelist];
                this.rows14 = this.recruitsmalpracticelist;
                this.isLoading = false;    
            }
        })
    break;
    case 'tab-15':
        if (this.service.recruitview.id!=0)
        this.isLoading = true;   
        this.service.onGetRecruitsEWFollowupbyrid(this.service.recruitview.id).subscribe(success =>{
            if(success) {
                this.recruitsewfollowupslist = this.service.recruitsewfollowupslist;
                this.temp15 = [...this.recruitsewfollowupslist];
                this.rows15 = this.recruitsewfollowupslist;
                this.isLoading = false;    
            }
        })
    break;
    case 'tab-16':
        if (this.service.recruitview.id!=0)
        this.isLoading = true;   
        this.service.onGetRecruitsEWExperiencebyrid(this.service.recruitview.id).subscribe(success =>{
            if(success) {
                this.recruitsewexperiencelist = this.service.recruitsewexperiencelist;
                
            }
        })
    break;
    case 'tab-17':
        if (this.service.recruitview.id!=0)
        this.isLoading = true;   
        this.service.onGetRecruitsClinicalExperiencebyrid(this.service.recruitview.id).subscribe(success =>{
            if(success) {
                this.recruitsclinicalexperiencelist = this.service.recruitsclinicalexperiencelist;
                
            }
        })
    break;
    case 'tab-18':
        if (this.service.recruitview.id!=0)
        this.isLoading = true;   
        this.service.onGetRecruitsCISAattendeesbyrid(this.service.recruitview.id).subscribe(success =>{
            if(success) {
                this.recruitscisAattendeeslist = this.service.recruitscisAattendeeslist;
                this.temp18 = [...this.recruitscisAattendeeslist];
                this.rows18 = this.recruitscisAattendeeslist;
                this.isLoading = false;    
            }
        })
    break;
    case 'tab-19':
        if (this.service.recruitview.id!=0)
        this.isLoading = true;   
        this.service.onGetRecruitsLiveclinicbyrid(this.service.recruitview.id).subscribe(success =>{
            if(success) {
                this.recruitsclinicanlocationlist = this.service.recruitsclinicanlocationlist;
                this.temp19 = [...this.recruitsclinicanlocationlist];
                this.rows19 = this.recruitsclinicanlocationlist;
                this.isLoading = false;    
            }
        })
    break;
    case 'tab-20':
        if (this.service.recruitview.id!=0)
        this.isLoading = true;   
        this.service.onGetRecruitsInterviewbyrid(this.service.recruitview.id).subscribe(success =>{
            if(success) {
                this.recruitsinterviewlist = this.service.recruitsinterviewlist;
                this.recruitsinterviewlist.interviewDate = this.commonService.transformDate(this.recruitsinterviewlist.interviewDate);
                this.service.OnGetRecruitsInterviewby().subscribe(success =>{
                    if(success) {
                        this.recruitsinterviewbylist = this.service.recruitsinterviewbylist;
                    }
                })
            }
        })
    break;
    case 'tab-21':
        if (this.service.recruitview.id!=0)
        this.isLoading = true;   
        this.service.onGetRecruitsCMOCornerbyrid(this.service.recruitview.id,Number(this.userid)).subscribe(success =>{
            if(success) {
                this.recruitscmocornerviewlist = this.service.recruitscmocornerviewlist;
                this.temp21 = [...this.recruitscmocornerviewlist];
                this.rows21 = this.recruitscmocornerviewlist;
                this.isLoading = false;    
            }
        })
    break;
    case 'tab-22':
            
        if (this.service.recruitview.id!=0)
      //  this.isotherdetails=true;
        this.isLoading = true; 
        this.service.onGetRecruitsOtherPrivilegesDetailsbyrid(this.service.recruitview.id,true).subscribe(success =>{
            if(success) {
                
                // this.dw2.recruitsotherdetailstable = this.service.recruitsotherdetailslist;
                // this.dw2.onOtherRetrieve();
                // this.isLoading = false;
                this.recruitsotherdetailslist = this.service.recruitsotherdetailslist;
                this.temp22 = [...this.recruitsotherdetailslist];
                this.rows22 = this.recruitsotherdetailslist;
                this.isLoading = false;        
            }
        })
     
    break;
    case 'tab-23':
        if (this.service.recruitview.id!=0)
        this.isLoading = true;   
        this.service.onGetRecruitsExitInterviewbyrid(this.service.recruitview.id).subscribe(success =>{
            if(success) {
                this.recruitsexitinterviewlist = this.service.recruitsexitinterviewlist;
                this.recruitsexitinterviewlist.exitinterviewDate = this.commonService.transformDate(this.recruitsexitinterviewlist.exitinterviewDate);
            }
        })
    break;
    case 'tab-24':
        if (this.service.recruitview.id!=0)
        this.isLoading = true; 
        
        this.onTabCMETableload(this.service.recruitview.id)
       
break;
    }
    
}
onSearchTable2(search: string): void 
{
    if (search.length> 0)
    {
        // get the value of the key pressed and make it lowercase
        let filter = search.toLowerCase();
   
        // assign filtered matches to the active datatable
        this.rows2 = this.temp2.filter(item => {
            
            return (item.columnName.toLowerCase().indexOf(filter) !== -1 ||
                           
            !filter);                
        });
        
        // TODO - whenever the filter changes, always go back to the first page
        this.table2.offset = 0;
    }
    else
    {
        this.rows2= [...this.recruitsotherdetailslist];
    }

}  
    onSearchTable3(search: string): void 
    {
        if (search.length> 0)
        {
            // get the value of the key pressed and make it lowercase
            let filter = search.toLowerCase();
       
            // assign filtered matches to the active datatable
            this.rows3 = this.temp3.filter(item => {
                console.log(item);
                return (item.degree.toLowerCase().indexOf(filter) !== -1 ||
                item.institute.toLowerCase().indexOf(filter) !== -1 ||
                item.mmyyPassing.toLowerCase().indexOf(filter) !== -1 ||      
                item.refNo.toLowerCase().indexOf(filter) !== -1 ||               
                !filter);                
            });
            
            // TODO - whenever the filter changes, always go back to the first page
            this.table3.offset = 0;
        }
        else
        {
            this.rows3= [...this.recruiteducationlist];
        }

    }  
    onSearchTable4(search:string): void
    {
        if (search.length> 0)
        {
            let filter = search;
            this.service.onGetRecruitsMedicalLicensesbyrid(this.service.recruitview.id,search).subscribe(success =>{
                if(success) {
                    this.recruitsmedicallicenseslist = this.service.recruitsmedicallicenseslist;
                    this.temp4 = [...this.recruitsmedicallicenseslist];
                    this.rows4 = this.recruitsmedicallicenseslist;
                    this.isLoading = false;    
                }
            })
            this.table4.offset = 0;
        }
        else
        {
            this.service.onGetRecruitsMedicalLicensesbyrid(this.service.recruitview.id,'undefined').subscribe(success =>{
                if(success) {
                    this.recruitsmedicallicenseslist = this.service.recruitsmedicallicenseslist;
                    this.temp4 = [...this.recruitsmedicallicenseslist];
                    this.rows4 = this.recruitsmedicallicenseslist;
                    this.isLoading = false;    
                }
            })
        }
    }
    onSearchTable5(search: string): void 
    {
        if (search.length> 0)
        {
            // get the value of the key pressed and make it lowercase
            let filter = search.toLowerCase();
           
            // assign filtered matches to the active datatable
        
            this.rows5 = this.temp5.filter(item => {
                this.fromdate =this.commonService.transformDate(item.fromDate)
                return (item.certification.toLowerCase().indexOf(filter) !== -1 ||
                //this.fromdate.toLowerCase().indexOf(filter) !== -1 ||
                //item.toDate.toLowerCase().indexOf(filter) !== -1 ||      
                item.refNo.toLowerCase().indexOf(filter) !== -1 ||               
                !filter);                
            });
            
            // TODO - whenever the filter changes, always go back to the first page
            this.table5.offset = 0;
        }
        else
        {
            this.rows5= [...this.recruitboardlist];
        }

    }  
    onSearchTable6(search: string): void 
    {
        if (search.length> 0)
        {
            // get the value of the key pressed and make it lowercase
            let filter = search.toLowerCase();
           
            // assign filtered matches to the active datatable
        
            this.rows6 = this.temp6.filter(item => {
                
                return (item.honoursDescription.toLowerCase().indexOf(filter) !== -1 ||
                //this.fromdate.toLowerCase().indexOf(filter) !== -1 ||
                //item.toDate.toLowerCase().indexOf(filter) !== -1 ||      
                item.refNo.toLowerCase().indexOf(filter) !== -1 ||               
                !filter);                
            });
            
            // TODO - whenever the filter changes, always go back to the first page
            this.table6.offset = 0;
        }
        else
        {
            this.rows6= [...this.recruitshonorslist];
        }

    } 

    onSearchTable7(search: string): void 
    {
        if (search.length> 0)
        {
            // get the value of the key pressed and make it lowercase
            let filter = search.toLowerCase();
           
            // assign filtered matches to the active datatable
        
            this.rows7 = this.temp7.filter(item => {
                
                return (item.membership.toLowerCase().indexOf(filter) !== -1 ||
                item.refNo.toLowerCase().indexOf(filter) !== -1 ||               
                !filter);                
            });
            
            // TODO - whenever the filter changes, always go back to the first page
            this.table7.offset = 0;
        }
        else
        {
            this.rows7= [...this.recruitsmembershiplist];
        }

    } 
    onSearchTable8(search: string): void 
    {
        if (search.length> 0)
        {
            // get the value of the key pressed and make it lowercase
            let filter = search.toLowerCase();
           
            // assign filtered matches to the active datatable
        
            this.rows8 = this.temp8.filter(item => {
                
                return (item.fellowship.toLowerCase().indexOf(filter) !== -1 ||
                item.refNo.toLowerCase().indexOf(filter) !== -1 ||               
                !filter);                
            });
            
            // TODO - whenever the filter changes, always go back to the first page
            this.table8.offset = 0;
        }
        else
        {
            this.rows8= [...this.recruitsfellowshiplist];
        }

    } 
    onSearchTable9(search: string): void 
    {
        if (search.length> 0)
        {
            
            // get the value of the key pressed and make it lowercase
            let filter = search.toLowerCase();
            
            // assign filtered matches to the active datatable
        
            this.rows9 = this.temp9.filter(item => {
                if(item.addressDetail==null)
                {
                  this.addressDetail= -1;
                }
                else
                {
                    this.addressDetail= item.addressDetail.toLowerCase().indexOf(filter) ;
                }
              
                return (item.internships.toLowerCase().indexOf(filter) !== -1 ||
                this.addressDetail !==-1 ||
                item.refNo.toLowerCase().indexOf(filter) !== -1 ||               
                !filter);                
            });
            
            // TODO - whenever the filter changes, always go back to the first page
            this.table9.offset = 0;
        }
        else
        {
            this.rows9= [...this.recruitsresidencieslist];
        }

    
    }
    onSearchTable10(search: string): void 
    {

        if (search.length> 0)
        {
            
            // get the value of the key pressed and make it lowercase
            let filter = search.toLowerCase();
            
            // assign filtered matches to the active datatable
        
            this.rows10 = this.temp10.filter(item => {
                if(item.addressDetail==null)
                {
                  this.addressDetail= -1;
                }
                else
                {
                    this.addressDetail= item.addressDetail.toLowerCase().indexOf(filter) ;
                }
              
                return (item.organization.toLowerCase().indexOf(filter) !== -1 ||
                this.addressDetail !==-1 ||                           
                item.refNo.toLowerCase().indexOf(filter) !== -1 ||               
                !filter);                
            });
            
            // TODO - whenever the filter changes, always go back to the first page
            this.table10.offset = 0;
        }
        else
        {
            this.rows10= [...this.recruitsinternshipslist];
        }

    }
    onSearchTable11(search: string): void 
    {

        if (search.length> 0)
        {
            
            // get the value of the key pressed and make it lowercase
            let filter = search.toLowerCase();
            
            // assign filtered matches to the active datatable
        
            this.rows11 = this.temp11.filter(item => {
                             
                return (item.hospital.toLowerCase().indexOf(filter) !== -1 ||
                item.address.indexOf(filter) !==-1 ||                           
                item.refNo.toLowerCase().indexOf(filter) !== -1 ||               
                !filter);                
            });
            
            // TODO - whenever the filter changes, always go back to the first page
            this.table11.offset = 0;
        }
        else
        {
            this.rows11= [...this.recruitsaffiliationslist];
        }

    }
    onSearchTable12(search: string): void 
    {

        if (search.length> 0)
        {
            
            // get the value of the key pressed and make it lowercase
            let filter = search.toLowerCase();
            
            // assign filtered matches to the active datatable
        
            this.rows12 = this.temp12.filter(item => {
                             
                return (item.employer.toLowerCase().indexOf(filter) !== -1 ||
                item.designation.toLowerCase().indexOf(filter) !==-1 ||                           
                             
                !filter);                
            });
            
            // TODO - whenever the filter changes, always go back to the first page
            this.table12.offset = 0;
        }
        else
        {
            this.rows12= [...this.recruitsemployerlist];
        }

    }
    onSearchTable13(search: string): void 
    {

        if (search.length> 0)
        {
            
            // get the value of the key pressed and make it lowercase
            let filter = search.toLowerCase();
            
            // assign filtered matches to the active datatable
        
            this.rows13 = this.temp13.filter(item => {
                             
                return (item.name.toLowerCase().indexOf(filter) !== -1 ||
              
                !filter);                
            });
            
            // TODO - whenever the filter changes, always go back to the first page
            this.table13.offset = 0;
        }
        else
        {
            this.rows13= [...this.recruitsreferenceslist];
        }

    }
    onSearchTable14(search: string): void 
    {

        if (search.length> 0)
        {
            
            // get the value of the key pressed and make it lowercase
            let filter = search.toLowerCase();
            
            // assign filtered matches to the active datatable
        
            this.rows14 = this.temp14.filter(item => {
                             
                return (item.patientName.toLowerCase().indexOf(filter) !== -1 ||
                item.caseSummary.toLowerCase().indexOf(filter) !== -1 ||
                item.caseOutcome.toLowerCase().indexOf(filter) !== -1 ||
                !filter);                
            });
            
            // TODO - whenever the filter changes, always go back to the first page
            this.table14.offset = 0;
        }
        else
        {
            this.rows14= [...this.recruitsmalpracticelist];
        }

    }
    onSearchTable15(search: string): void 
    {
        if (search.length> 0)
        {
            // get the value of the key pressed and make it lowercase
            let filter = search.toLowerCase();
            
            // assign filtered matches to the active datatable
            this.rows15 = this.temp15.filter(item => {
                console.log(item);
                return (item.callDate.toLowerCase().indexOf(filter) !== -1 ||
                item.callTime.toLowerCase().indexOf(filter) !== -1 ||
                item.followup_Notes.toLowerCase().indexOf(filter) !== -1 ||                
                !filter);                
            });
            // TODO - whenever the filter changes, always go back to the first page
            this.table15.offset = 0;
        }

    }
    onSearchTable21(search: string): void 
    {
        if (search.length> 0)
        {
            // get the value of the key pressed and make it lowercase
            let filter = search.toLowerCase();
           
            // assign filtered matches to the active datatable
        
            this.rows21 = this.temp21.filter(item => {
                
                return (item.comments.toLowerCase().indexOf(filter) !== -1 ||
                            
                !filter);                
            });
            
            // TODO - whenever the filter changes, always go back to the first page
            this.table21.offset = 0;
        }
        else
        {
            this.rows21= [...this.recruitscmocornerviewlist];
        }

    } 
    onSearchTable22(search: string): void 
{
    if (search.length> 0)
    {
        // get the value of the key pressed and make it lowercase
        let filter = search.toLowerCase();
   
        // assign filtered matches to the active datatable
        this.rows22 = this.temp22.filter(item => {
            
            return (item.columnName.toLowerCase().indexOf(filter) !== -1 ||
                           
            !filter);                
        });
        
        // TODO - whenever the filter changes, always go back to the first page
        this.table22.offset = 0;
    }
    else
    {
        this.rows22= [...this.recruitsotherdetailslist];
    }

} 
onSearchTable24(search: string): void 
{
    if (search.length> 0)
    {
        // get the value of the key pressed and make it lowercase
        let filter = search.toLowerCase();
       
        // assign filtered matches to the active datatable
    
        this.rows24 = this.temp24.filter(item => {
         
            return (item.cmehours.toString().toLowerCase().indexOf(filter) !== -1 ||
            item.year.toString().toLowerCase().indexOf(filter) !== -1 ||
           
            item.balanceCmeallowance.toString().toLowerCase().indexOf(filter) !== -1 ||               
            !filter);                
        });
        
        // TODO - whenever the filter changes, always go back to the first page
        this.table24.offset = 0;
    }
    else
    {
        this.rows24= [...this.recruitsftelist];
   }

}  
    onOpenPopupModel(title:string, tabselect:string, id:number,value:string): void {
        this.modelTitle = title;

        //this.recruit_id = this.service.recruitview.id;
        switch(tabselect){
            case 'tab-2':
                this.iseducation=false;
                this.isotherdetails=true;
                this.ismedicallicenses=false;
                this.isboard=false;
                this.ishonors=false;
                this.ismembership=false;
                this.isfellowship=false;
                this.isresidencies=false;
                this.isinternships=false;
                this.isaffiliations=false;
                this.isemployer=false;
                this.isreferences=false;
                this.ismalpractice=false;
                this.isewfollowup=false;
                this.isCISAattendees=false; 
                this.iscliniclocation=false;
                this.iscmocorner=false;
                this.isfte=false;
                this.tabname='tab-2';
                if (id!=0)
                this.otherdetailsService.onGetRecruitsOtherDetails(id).subscribe(success =>{
                    if(success) {
                        this.otherdetailsService.formData2 = this.otherdetailsService.formData2;
                        this.modelTitle = this.otherdetailsService.formData2.columnName;
                       this.Documentvalue= this.otherdetailsService.formData2.documents;
                       this.otherdetailsService.formData2.columnDatatype=value;
                       if(value=="date")
                       {
                        this.otherdetailsService.formData2.columnValue=this.commonService.transformDate(this.otherdetailsService.formData2.columnValue)
                      
                        }
                        
                       
                        
                     
                      
                    }
                });
            break;
            case 'tab-3':
                this.iseducation=true;
                this.isotherdetails=false;
                this.ismedicallicenses=false;
                this.isboard=false;
                this.ishonors=false;
                this.ismembership=false;
                this.isfellowship=false;
                this.isresidencies=false;
                this.isinternships=false;
                this.isaffiliations=false;
                this.isemployer=false;
                this.isreferences=false;
                this.ismalpractice=false;
                this.isewfollowup=false;
                this.isCISAattendees=false;
                this.iscliniclocation=false;
                this.iscmocorner=false;
                this.isfte=false;
                if (id!=0)
                this.educationService.onGetRecruitsEduction(id).subscribe(success =>{
                    if(success) {
                        this.educationService.formData3 = this.educationService.formData3;
                        this.Documentvalue=this.educationService.formData3.documents;
                      
                    }
                });
            break;
            case 'tab-4':
                this.iseducation=false;
                this.isotherdetails=false;
                this.ismedicallicenses=true;
                this.isboard=false;
                this.ishonors=false;
                this.ismembership=false;
                this.isfellowship=false;
                this.isresidencies=false;
                this.isinternships=false;
                this.isaffiliations=false;
                this.isemployer=false;
                this.isreferences=false;
                this.ismalpractice=false;
                this.isewfollowup=false;
                this.isCISAattendees=false;
                this.iscliniclocation=false;
                this.iscmocorner=false;
                this.isfte=false;
                if (id!=0)
                this.medicallicenseService.onGetRecruitsMedicalLicense(id).subscribe(success =>{
                    if(success) {
                        this.medicallicenseService.formData4 = this.medicallicenseService.formData4;
                        this.medicallicenseService.formData4.expireDate=this.commonService.transformDate(this.medicallicenseService.formData4.expireDate)
                        this.medicallicenseService.formData4.licenseIssued=this.commonService.transformDate(this.medicallicenseService.formData4.licenseIssued)
                        this.Documentvalue=this.medicallicenseService.formData4.medicalDoc;
                    }
                });
            break;
            case 'tab-5':
                this.iseducation=false;
                this.isotherdetails=false;
                this.ismedicallicenses=false;
                this.isboard=true;
                this.ishonors=false;
                this.ismembership=false;
                this.isfellowship=false;
                this.isresidencies=false;
                this.isinternships=false;
                this.isaffiliations=false;
                this.isemployer=false;
                this.isreferences=false;
                this.ismalpractice=false;
                this.isewfollowup=false;
                this.isCISAattendees=false;
                this.iscliniclocation=false;
                this.iscmocorner=false;
                this.isfte=false;
                if (id!=0)
                this.boardCertificationService.onGetRecruitsBoard(id).subscribe(success =>{
                    if(success) {
                        this.boardCertificationService.formData5 = this.boardCertificationService.formData5;
                        this.boardCertificationService.formData5.fromDate = this.commonService.transformDate(this.boardCertificationService.formData5.fromDate);
                        this.boardCertificationService.formData5.toDate = this.commonService.transformDate(this.boardCertificationService.formData5.toDate);
                        this.Documentvalue=this.boardCertificationService.formData5.documents;
                        this.boardCertificationService.OnBoardCertificationAlllist().subscribe(success =>{
                            if(success) {
                               this.boardCertifiednamelists=this.boardCertificationService.boardCertifiednamelist;
                            }
                        });
                    }
                });
            break;
            case 'tab-6':
                    this.iseducation=false;
                    this.isotherdetails=false;
                    this.ismedicallicenses=false;
                    this.isboard=false;
                    this.ishonors=true;
                    this.ismembership=false;
                    this.isfellowship=false;
                    this.isresidencies=false;
                    this.isinternships=false;
                    this.isaffiliations=false;
                    this.isemployer=false;
                    this.isreferences=false;
                    this.ismalpractice=false;
                    this.isewfollowup=false;
                    this.isCISAattendees=false;
                    this.iscliniclocation=false;
                    this.iscmocorner=false;
                    this.isfte=false;
                if (id!=0)
                this.honorsService.onGetRecruitsHonors(id).subscribe(success =>{
                    if(success) {
                        this.honorsService.formData6 = this.honorsService.formData6;
                        this.honorsService.formData6.honoursDate = this.commonService.transformDate(this.honorsService.formData6.honoursDate);
                        this.Documentvalue=this.honorsService.formData6.documents;
                    }
                });
            break;
            case 'tab-7':
                    this.iseducation=false;
                    this.isotherdetails=false;
                    this.ismedicallicenses=false;
                    this.isboard=false;
                    this.ishonors=false;
                    this.ismembership=true;
                    this.isfellowship=false;
                    this.isresidencies=false;
                    this.isinternships=false;
                    this.isaffiliations=false;
                    this.isemployer=false;
                    this.isreferences=false;
                    this.ismalpractice=false;
                    this.isewfollowup=false;
                    this.isCISAattendees=false;
                    this.iscliniclocation=false;
                    this.iscmocorner=false;
                    this.isfte=false;
                if (id!=0)
                this.membershipService.onGetRecruitsMembership(id).subscribe(success =>{
                    if(success) {
                        this.membershipService.formData7 = this.membershipService.formData7;
                        this.Documentvalue=this.membershipService.formData7.documents;
                     }
                });   
            break;
            case 'tab-8':
                    this.iseducation=false;
                    this.isotherdetails=false;
                    this.ismedicallicenses=false;
                    this.isboard=false;
                    this.ishonors=false;
                    this.ismembership=false;
                    this.isfellowship=true;
                    this.isresidencies=false;
                    this.isinternships=false;
                    this.isaffiliations=false;
                    this.isemployer=false;
                    this.isreferences=false;
                    this.ismalpractice=false;
                    this.isewfollowup=false;
                    this.isCISAattendees=false;
                    this.iscliniclocation=false;
                    this.iscmocorner=false;
                    this.isfte=false;
                if (id!=0)
                this.fellowshipService.onGetRecruitsFellowship(id).subscribe(success =>{
                    if(success) {
                        this.fellowshipService.formData8 = this.fellowshipService.formData8;
                        this.Documentvalue=this.fellowshipService.formData8.documents;
                     }
                });   
            break;
            case 'tab-9':
                this.iseducation=false;
                this.isotherdetails=false;
                this.ismedicallicenses=false;
                this.isboard=false;
                this.ishonors=false;
                this.ismembership=false;
                this.isfellowship=false;
                this.isresidencies=true;
                this.isinternships=false;
                this.isaffiliations=false;
                this.isemployer=false;
                this.isreferences=false;
                this.ismalpractice=false;
                this.isewfollowup=false;
                this.isCISAattendees=false;
                this.iscliniclocation=false;
                this.iscmocorner=false;
                this.isfte=false;
            if (id!=0)
            this.residenciesService.onGetRecruitsResidencies(id).subscribe(success =>{
                if(success) {
                    this.residenciesService.formData9 = this.residenciesService.formData9;
                    this.residenciesService.formData9.fromDate = this.commonService.transformDate(this.residenciesService.formData9.fromDate);
                    this.residenciesService.formData9.toDate = this.commonService.transformDate(this.residenciesService.formData9.toDate);
                    this.Documentvalue=this.residenciesService.formData9.documents;
                 }
            });   
        break;
        case 'tab-10':
            this.iseducation=false;
            this.isotherdetails=false;
            this.ismedicallicenses=false;
            this.isboard=false;
            this.ishonors=false;
            this.ismembership=false;
            this.isfellowship=false;
            this.isresidencies=false;
            this.isinternships=true;
            this.isaffiliations=false;
            this.isemployer=false;
            this.isreferences=false;
            this.ismalpractice=false;
            this.isewfollowup=false;
            this.isCISAattendees=false;
            this.iscliniclocation=false;
            this.iscmocorner=false;
            this.isfte=false;
        if (id!=0)
        this.internshipsService.onGetRecruitsInternships(id).subscribe(success =>{
            if(success) {
                this.internshipsService.formData10 = this.internshipsService.formData10;
                this.internshipsService.formData10.fromDate = this.commonService.transformDate(this.internshipsService.formData10.fromDate);
                this.internshipsService.formData10.toDate = this.commonService.transformDate(this.internshipsService.formData10.toDate);
                this.Documentvalue=this.internshipsService.formData10.documents;
             }
        });   
    break;
    case 'tab-11':
        this.iseducation=false;
        this.isotherdetails=false;
        this.ismedicallicenses=false;
        this.isboard=false;
        this.ishonors=false;
        this.ismembership=false;
        this.isfellowship=false;
        this.isresidencies=false;
        this.isinternships=false;
        this.isaffiliations=true;
        this.isemployer=false;
        this.isreferences=false;
        this.ismalpractice=false;
        this.isewfollowup=false;
        this.isCISAattendees=false;
        this.iscliniclocation=false;
        this.iscmocorner=false;
        this.isfte=false;
    if (id!=0)
    this.affiliationsService.onGetRecruitsAffiliations(id).subscribe(success =>{
        if(success) {
            this.affiliationsService.formData11 = this.affiliationsService.formData11;
            this.Documentvalue=this.affiliationsService.formData11.documents;
            
         }
    });   
break;
    case 'tab-12':
        this.iseducation=false;
        this.isotherdetails=false;
        this.ismedicallicenses=false;
        this.isboard=false;
        this.ishonors=false;
        this.ismembership=false;
        this.isfellowship=false;
        this.isresidencies=false;
        this.isinternships=false;
        this.isaffiliations=false;
        this.isemployer=true;
        this.isreferences=false;
        this.ismalpractice=false;
        this.isewfollowup=false;
        this.isCISAattendees=false;
        this.iscliniclocation=false;
        this.iscmocorner=false;
        this.isfte=false;
    if (id!=0)
    this.employerService.onGetRecruitsEmployer(id).subscribe(success =>{
        if(success) {
            this.employerService.formData12 = this.employerService.formData12;
            this.employerService.formData12.fromDate = this.commonService.transformDate(this.employerService.formData12.fromDate);
            this.employerService.formData12.toDate = this.commonService.transformDate(this.employerService.formData12.toDate);
     
        }
    });   
    break;
    case 'tab-13':
        this.iseducation=false;
        this.isotherdetails=false;
        this.ismedicallicenses=false;
        this.isboard=false;
        this.ishonors=false;
        this.ismembership=false;
        this.isfellowship=false;
        this.isresidencies=false;
        this.isinternships=false;
        this.isaffiliations=false;
        this.isemployer=false;
        this.isreferences=true;
        this.ismalpractice=false;
        this.isewfollowup=false;
        this.isCISAattendees=false;
        this.iscliniclocation=false;
        this.iscmocorner=false;
        this.isfte=false;
    if (id!=0)
    this.referencesService.onGetRecruitsReferences(id).subscribe(success =>{
        if(success) {
            this.referencesService.formData13 = this.referencesService.formData13;
            this.Documentvalue=this.referencesService.formData13.documents;
        }
    });   
    break;
    case 'tab-14':
        this.iseducation=false;
        this.isotherdetails=false;
        this.ismedicallicenses=false;
        this.isboard=false;
        this.ishonors=false;
        this.ismembership=false;
        this.isfellowship=false;
        this.isresidencies=false;
        this.isinternships=false;
        this.isaffiliations=false;
        this.isemployer=false;
        this.isreferences=false;
        this.ismalpractice=true;
        this.isewfollowup=false;
        this.isCISAattendees=false;
        this.iscliniclocation=false;
        this.iscmocorner=false;
        this.isfte=false;
    if (id!=0)
    this.malpracticeService.onGetRecruitsmalpractice(id).subscribe(success =>{
        if(success) {
            this.malpracticeService.formData14 = this.malpracticeService.formData14;
            this.malpracticeService.formData14.caseDate = this.commonService.transformDate(this.malpracticeService.formData14.caseDate);
           
        }
    });   
    break;
    case 'tab-15':
        this.iseducation=false;
        this.isotherdetails=false;
        this.ismedicallicenses=false;
        this.isboard=false;
        this.ishonors=false;
        this.ismembership=false;
        this.isfellowship=false;
        this.isresidencies=false;
        this.isinternships=false;
        this.isaffiliations=false;
        this.isemployer=false;
        this.isreferences=false;
        this.ismalpractice=false;
        this.isewfollowup=true;
        this.isCISAattendees=false;
        this.iscliniclocation=false;
        this.iscmocorner=false;
        this.isfte=false;
        if (id!=0)
        this.ewfollowupsService.onGetRecruitsEWFollowups(id).subscribe(success =>{
        if(success) {
            this.ewfollowupsService.formData15 = this.ewfollowupsService.formData15;
           
        }
        });   
    break;
    case 'tab-18':
        this.iseducation=false;
        this.isotherdetails=false;
        this.ismedicallicenses=false;
        this.isboard=false;
        this.ishonors=false;
        this.ismembership=false;
        this.isfellowship=false;
        this.isresidencies=false;
        this.isinternships=false;
        this.isaffiliations=false;
        this.isemployer=false;
        this.isreferences=false;
        this.ismalpractice=false;
        this.isewfollowup=false;
        this.isCISAattendees=true;
        this.iscliniclocation=false;
        this.iscmocorner=false;
        this.isfte=false;
        if (id!=0)
        this.cisattendeesService.onGetRecruitsCISAttendees(id).subscribe(success =>{
        if(success) {
            this.cisattendeesService.formData18 = this.cisattendeesService.formData18;
            this.cisattendeesService.formData18.fromDate = this.commonService.transformDate(this.cisattendeesService.formData18.fromDate );
            this.cisattendeesService.formData18.toDate = this.commonService.transformDate(this.cisattendeesService.formData18.toDate);
            this.cisattendeesService.formData18.rsvpDate = this.commonService.transformDate(this.cisattendeesService.formData18.rsvpDate);
            
           
           
        }
        });   
    break;
    case 'tab-19':
        this.iseducation=false;
        this.isotherdetails=false;
        this.ismedicallicenses=false;
        this.isboard=false;
        this.ishonors=false;
        this.ismembership=false;
        this.isfellowship=false;
        this.isresidencies=false;
        this.isinternships=false;
        this.isaffiliations=false;
        this.isemployer=false;
        this.isreferences=false;
        this.ismalpractice=false;
        this.isewfollowup=false;
        this.isCISAattendees=false;
        this.iscliniclocation=true;
        this.iscmocorner=false;
        this.isfte=false;
        if (id!=0)
        
        this.liveClinicFormService.onGetRecruitsLiveclinic(id).subscribe(success =>{
        if(success) {
            this.iseditcliniclocation=true;
            this.liveClinicFormService.Recruitsliveclinictbllist =  this.liveClinicFormService.Recruitsliveclinictbllist;
            this.liveClinicFormService.Recruitsliveclinictbllist.startDate = this.commonService.transformDate(this.liveClinicFormService.Recruitsliveclinictbllist.startDate);
            this.liveClinicFormService.Recruitsliveclinictbllist.recruitId=this.service.recruitview.id;
            this.liveClinicFormService.Recruitsliveclinictbllist.firstName=this.service.recruitview.firstName;
            this.liveClinicFormService.Recruitsliveclinictbllist.middleName=this.service.recruitview.middleName;
            this.liveClinicFormService.Recruitsliveclinictbllist.lastName=this.service.recruitview.lastName;
            // this.liveClinicFormService.Recruitsliveclinictbllist.cityId=this.service.recruitview.cityId;
            // this.liveClinicFormService.Recruitsliveclinictbllist.stateId=this.service.recruitview.stateId;
            // this.liveClinicFormService.Recruitsliveclinictbllist.degree=this.service.recruitview.degree;
            this.liveClinicFormService.Recruitsliveclinictbllist.primaryContact=this.service.recruitview.primaryContact;
            this.liveClinicFormService.Recruitsliveclinictbllist.primaryEmail=this.service.recruitview.primaryEmail;
            this.commonService.getCities(this.liveClinicFormService.Recruitsliveclinictbllist.stateId).subscribe(success =>{
               
                if(success) {
                    this.cities = this.commonService.cities;
                  }
                })
       
           
        }
        });   
    break;
    case 'tab-21':
        this.iseducation=false;
        this.isotherdetails=false;
        this.ismedicallicenses=false;
        this.isboard=false;
        this.ishonors=false;
        this.ismembership=false;
        this.isfellowship=false;
        this.isresidencies=false;
        this.isinternships=false;
        this.isaffiliations=false;
        this.isemployer=false;
        this.isreferences=false;
        this.ismalpractice=false;
        this.isewfollowup=false;
        this.isCISAattendees=false;
        this.iscliniclocation=false;
        this.iscmocorner=true;
        this.isfte=false;
        if (id!=0)
        
        this.cmocornerService.onGetRecruitsCMOCorner(id).subscribe(success =>{
        if(success) {
           
            this.cmocornerService.formData21 =  this.cmocornerService.formData21;
            
            this.cmocornerService.formData21.cmoDate = this.commonService.transformDate(this.cmocornerService.formData21.cmoDate);
           
        }
        });   
    break;
    case 'tab-22':
        this.iseducation=false;
        this.isotherdetails=true;
        this.ismedicallicenses=false;
        this.isboard=false;
        this.ishonors=false;
        this.ismembership=false;
        this.isfellowship=false;
        this.isresidencies=false;
        this.isinternships=false;
        this.isaffiliations=false;
        this.isemployer=false;
        this.isreferences=false;
        this.ismalpractice=false;
        this.isewfollowup=false;
        this.isCISAattendees=false; 
        this.iscliniclocation=false;
        this.iscmocorner=false;
        this.isfte=false;
        this.tabname='tab-22'
        if (id!=0)
        this.otherdetailsService.onGetRecruitsOtherDetails(id).subscribe(success =>{
            if(success) {
                this.otherdetailsService.formData2 = this.otherdetailsService.formData2;
                this.modelTitle = this.otherdetailsService.formData2.columnName;
               this.Documentvalue= this.otherdetailsService.formData2.documents;
               this.otherdetailsService.formData2.columnDatatype=value;
               if(value=="date")
               {
                this.otherdetailsService.formData2.columnValue=this.commonService.transformDate(this.otherdetailsService.formData2.columnValue)
              
                }
                
               
                
             
              
            }
        });
    break;
    case 'tab-24':
        this.iseducation=false;
        this.isotherdetails=false;
        this.ismedicallicenses=false;
        this.isboard=false;
        this.ishonors=false;
        this.ismembership=false;
        this.isfellowship=false;
        this.isresidencies=false;
        this.isinternships=false;
        this.isaffiliations=false;
        this.isemployer=false;
        this.isreferences=false;
        this.ismalpractice=false;
        this.isewfollowup=false;
        this.isCISAattendees=false;
        this.iscliniclocation=false;
        this.iscmocorner=false;
        this.isfte=true;
        if (id!=0)
        this.fteService.onGetRecruitsFTE(id).subscribe(success =>{
            if(success) {
                this.fteService.formData24 = this.fteService.formData24;
                this.fteService.formData24.cmeallowance=this.commonService.transformdigitalnumber( this.fteService.formData24.cmeallowance)
                this.fteService.formData24.inCmeallowance=this.commonService.transformdigitalnumber( this.fteService.formData24.inCmeallowance)
                this.fteService.formData24.balanceCmeallowance=this.commonService.transformdigitalnumber( this.fteService.formData24.balanceCmeallowance)    
               
            }
        });
    break;
 }
        this.modal.open(this.modalContent, { size: 'lg' });
 }
 onOpenFTEModel(title:string,tab:string,id:number)
 {
    this.iseducation=false;
    this.isotherdetails=false;
    this.ismedicallicenses=false;
    this.isboard=false;
    this.ishonors=false;
    this.ismembership=false;
    this.isfellowship=false;
    this.isresidencies=false;
    this.isinternships=false;
    this.isaffiliations=false;
    this.isemployer=false;
    this.isreferences=false;
    this.ismalpractice=false;
    this.isewfollowup=false;
    this.isCISAattendees=false;
    this.iscliniclocation=false;
    this.iscmocorner=false;
    this.isfte=false;
    this.iscmefte=true;
    if (id!=0)
    this.fteService.onGetRecruitsFTE(id).subscribe(success =>{
        if(success) {
            this.fteService.formData24 = this.fteService.formData24;
            this.fteService.formData24.cmeallowance=this.commonService.transformdigitalnumber( this.fteService.formData24.cmeallowance)
            this.fteService.formData24.inCmeallowance=this.commonService.transformdigitalnumber( this.fteService.formData24.inCmeallowance)
          
            this.fteService.formData24.balanceCmeallowance=this.commonService.transformdigitalnumber( this.fteService.formData24.balanceCmeallowance)    
            if (this.fteService.formData24.ftEdtl.length > 0)
            {
               this.dw_fte.recruitsftedtl=this.fteService.formData24.ftEdtl;
               
            }
            else
            {
                this.dw_fte.recruitsftedtl=null;
            }
            this.dw_fte.onRetrieveftedtl();
        }
    });
 }
 onCloseCMEModal()
 {
 
    this.dw_fte.recruitsftedtl = null;
        this.dw_fte.dw_ftedtl.clearAll();
    this.onTabCMETableload(this.service.recruitview.id)
 }
 onTabCMETableload(id:number)
 {
    this.isfte=false;
    this.iscmefte=false;
    this.isLoading = true;  
    
    this.service.onGetRecruitsFTEbyyear(this.currentYearDate,id).subscribe(success =>{
        if(success) {
           this.recruitsfteyearlist= this.service.recruitsfteyearlist;
                
           if(this.recruitsfteyearlist.length>0)
           {
               this.iscmefteyear=false
           }
           else
           {
            this.iscmefteyear=true
           }
        }
        });
    this.service.onGetRecruitsFTEbyrid(id).subscribe(success =>{
        if(success) {
            this.recruitsftelist = this.service.recruitsftelist;
         
            this.temp24 = [...this.recruitsftelist];
            this.rows24 = this.recruitsftelist;
            this.isLoading = false;    
        }
    })
 }
    onDeleteEducation(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.educationService.onDeleteRecruitsEductionForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','Education')
                    this.onTabChange({'activeId':'tab-2','nextId':'tab-3'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'Education');
                }
            })
        }        
    }
    onDeleteBoard(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsBoardForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','Board Certifications')
                    this.onTabChange({'activeId':'tab-4','nextId':'tab-5'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'Board Certifications');
                }
            })
        }        
    }
    onDeleteHonors(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsHonorsForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','Honors')
                    this.onTabChange({'activeId':'tab-5','nextId':'tab-6'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'Honors');
                }
            })
        }        
    }
    onDeleteMembership(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsMembershipForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','Membership')
                    this.onTabChange({'activeId':'tab-6','nextId':'tab-7'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'Membership');
                }
            })
        }        
    }
    onDeleteFellowship(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsFellowshipForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','Membership')
                    this.onTabChange({'activeId':'tab-7','nextId':'tab-8'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'Fellowship');
                }
            })
        }        
    }
    onDeleteResidencies(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsResidenciesForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','Residencies')
                    this.onTabChange({'activeId':'tab-8','nextId':'tab-9'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'Residencies');
                }
            })
        }        
    }
    onDeleteInternships(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsInternshipsForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','Internships')
                    this.onTabChange({'activeId':'tab-9','nextId':'tab-10'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'Internships');
                }
            })
        }        
    }
    onDeleteAffiliations(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsAffiliationsForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','Hospital Affiliations/Privileges')
                    this.onTabChange({'activeId':'tab-10','nextId':'tab-11'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'Hospital Affiliations/Privileges');
                }
            })
        }        
    }
    onDeleteEmployer(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsEmployerForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','Employment History')
                    this.onTabChange({'activeId':'tab-11','nextId':'tab-12'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'Employment History');
                }
            })
        }        
    }
    onDeleteReferences(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsReferencesForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','References')
                    this.onTabChange({'activeId':'tab-12','nextId':'tab-13'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'References');
                }
            })
        }        
    }
    onDeleteMalpractice(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsMalpracticeForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','Malpractice')
                    this.onTabChange({'activeId':'tab-13','nextId':'tab-14'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'Malpractice');
                }
            })
        }        
    }
    onDeleteMedicalLicenses(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsMedicalLicensesForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','Medical Licenses')
                    this.onTabChange({'activeId':'tab-3','nextId':'tab-4'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'Medical Licenses');
                }
            })
        }        
    }
    onDeleteEWFollowup(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsEWFollowupForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','EWNN Followups')
                    this.onTabChange({'activeId':'tab-14','nextId':'tab-15'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'EWNN Followups');
                }
            })
        }        
    }
    onDeleteCISAattendees(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsCISAattendeesForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','CIS Attendees')
                    this.onTabChange({'activeId':'tab-15','nextId':'tab-18'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'CIS Attendees');
                }
            })
        }        
    }
    onDeletecliniclocation(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsLiveClinicForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','Clinic Location')
                    this.onTabChange({'activeId':'tab-18','nextId':'tab-19'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'Clinic Location');
                }
            })
        }        
    }
    onDeleteCMECorner(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsCMOCornerForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','CMO Corner')
                    this.onTabChange({'activeId':'tab-20','nextId':'tab-21'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'CMO Corner');
                }
            })
        }        
    }
    onDeleteFte(id:number){        
        if (confirm('Are you sure to delete this record?'))
        {
            this.service.onDeleteRecruitsFTEForm(id).subscribe(success =>{
                if(success) {
                    this.toastr.info('Data deleted successfully!!','CME Reimbursement')
                    this.onTabChange({'activeId':'tab-23','nextId':'tab-24'}); 
                          
                }
                else
                {
                    this.toastr.error('Error while updating Data!!', 'CME Reimbursement');
                }
            })
        }        
    }
    onCloseClickModal(data)
    {
        this.iseducation = false;
        this.modal.dismissAll();
    }
    onCVProfilePrint(){
        this.showSpinner('cvprintspinner');
        this.cvprint = true;
       
        this.commonService.getRecruitCVProfile(this.recruit_id).subscribe(success =>{
                    if(success) {
                      this.commonService.recruitcvprofile = this.commonService.recruitcvprofile;
                      //this.recruitcvprofile = this.commonService.recruitcvprofile;
                        if(this.isewnn)
                        {
                               this.commonService.recruitcvprofile.primaryContact= this.commonService.recruitcvprofile.primaryContact.replace('(','').replace(') ','-')
                        }
                        
                        
                        for (let i=0;i<this.commonService.recruitcvprofile.recruitsOtherdetail.length;i++)
                        {
                          //console.log("coloumnname :"+this.commonService.recruitcvprofile.recruitsOtherdetail[i].columnName);
                          if(this.commonService.recruitcvprofile.recruitsOtherdetail[i].columnName=='Agreement Signed On')
                          {
                            
                            this.commonService.aggrementsignedon = this.commonService.recruitcvprofile.recruitsOtherdetail[i].columnValue;
                            
                          }
                          if(this.commonService.recruitcvprofile.recruitsOtherdetail[i].columnName=='PMD Agreement')
                          {
                            
                            this.commonService.aggrementsignedon = this.commonService.recruitcvprofile.recruitsOtherdetail[i].columnValue;
                            
                          }
                          if(this.commonService.recruitcvprofile.recruitsOtherdetail[i].columnName=='Offer Letter Signed On')
                          {
                            
                            this.commonService.offerlettersignedon = this.commonService.recruitcvprofile.recruitsOtherdetail[i].columnValue;
                            
                          }
                          if(this.commonService.recruitcvprofile.recruitsOtherdetail[i].columnName=='Licensure Disciplinary Actions')
                          {
                            
                            this.commonService.licensuredisciplinaryactions = this.commonService.recruitcvprofile.recruitsOtherdetail[i].columnValue;
                            
                          }
                          if(this.commonService.recruitcvprofile.recruitsOtherdetail[i].columnName=='Supervisory/Collaborative Agreement Signed On')
                          {
                            
                            this.commonService.collaborativeaggrementsignedon = this.commonService.recruitcvprofile.recruitsOtherdetail[i].columnValue;
                            
                          }
                          
                        }
                        this.commonService.dlexpiredt = this.commonService.recruitcvprofile.recruitsOtherdetail[1].columnValue;
                        this.commonService.ssn = this.commonService.recruitcvprofile.recruitsOtherdetail[2].columnValue;
                        this.commonService.passport = this.commonService.recruitcvprofile.recruitsOtherdetail[3].columnValue;
                        if (this.commonService.recruitcvprofile.recruitsOtherdetail[3].refNo!=null)
                        {
                          this.commonService.passport = this.commonService.passport ;
                        }     
                                   
                        this.commonService.backgroundcheck = this.commonService.recruitcvprofile.recruitsOtherdetail[15].columnValue;
                      
                        
                        
                        this.commonService.npi = this.commonService.recruitcvprofile.recruitsOtherdetail[5].columnValue;
                        if (this.commonService.recruitcvprofile.recruitsOtherdetail[5].refNo!=null)
                        {
                          this.commonService.npi = this.commonService.npi ;
                        }                          
                        this.commonService.caqh = this.commonService.recruitcvprofile.recruitsOtherdetail[10].columnValue;
                        if (this.commonService.recruitcvprofile.recruitsOtherdetail[10].refNo!=null)
                        {
                          this.commonService.caqh = this.commonService.caqh ;
                        }                          
                        this.commonService.nihss = this.commonService.recruitcvprofile.recruitsOtherdetail[9].columnValue;
                        if (this.commonService.recruitcvprofile.recruitsOtherdetail[9].refNo!=null)
                        {
                          this.commonService.nihss = this.commonService.nihss ;
                        }  
                        this.commonService.upin = this.commonService.recruitcvprofile.recruitsOtherdetail[6].columnValue;
                        if (this.commonService.recruitcvprofile.recruitsOtherdetail[6].refNo!=null)
                        {
                          this.commonService.upin = this.commonService.upin ;
                        }                        
                        this.commonService.followupdate = this.commonService.recruitcvprofile.recruitsOtherdetail[14].columnValue;
                        this.commonService.actiontaken = this.commonService.recruitcvprofile.recruitsOtherdetail[16].columnValue;
                       
                        this.commonService.coi = this.commonService.recruitcvprofile.recruitsOtherdetail[8].columnValue;
                        if (this.commonService.recruitcvprofile.recruitsOtherdetail[8].refNo!=null)
                        {
                          this.commonService.coi = this.commonService.coi ;
                        }                         
                        this.commonService.pli = this.commonService.recruitcvprofile.recruitsOtherdetail[7].columnValue;
                        if (this.commonService.recruitcvprofile.recruitsOtherdetail[7].refNo!=null)
                        {
                          this.commonService.pli = this.commonService.pli ;
                        }                         
                        this.commonService.medicalliabilityclaimsjudgementpast10years = this.commonService.recruitcvprofile.recruitsOtherdetail[22].columnValue;
                        //this.commonService.residency = this.commonService.recruitcvprofile.recruitsOtherdetail[3].columnValue;
                        //this.commonService.clinicalpracticingsince = this.commonService.recruitcvprofile.recruitsOtherdetail[3].columnValue;
                        this.commonService.cmepast2years = this.commonService.recruitcvprofile.recruitsOtherdetail[20].columnValue;
                        if (this.commonService.recruitcvprofile.recruitsOtherdetail[20].refNo!=null)
                        {
                          this.commonService.cmepast2years = this.commonService.cmepast2years ;
                        }                          
                        //this.commonService.boardeligible = this.commonService.recruitcvprofile.recruitsOtherdetail[3].columnValue;
                        this.commonService.postinterviewscore = this.commonService.recruitcvprofile.recruitsOtherdetail[23].columnValue; 
                      }
            })                 
          
         
    }
    getpdf(){

        let HTML_Width = this.content.nativeElement.offsetWidth;
        let HTML_Height = this.content.nativeElement.offsetHeight;
        let top_left_margin = 15;
        let PDF_Width = HTML_Width+(+top_left_margin*2);
        let PDF_Height = (+PDF_Width*1.5)+(+top_left_margin*2);
        let canvas_image_width = +HTML_Width;
        let canvas_image_height = +HTML_Height;
        
        let totalPDFPages = Math.ceil(+HTML_Height/PDF_Height)-1;
        

        html2canvas(document.getElementById('cvprofile')).then(function(canvas) {
            canvas.getContext('2d');
            console.log(canvas_image_width+"  "+canvas_image_height);
            let imgData = canvas.toDataURL("image/png", 1.0);
            let pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
                pdf.addImage(imgData, 'png', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
            
            
            for (let i = 1; i <= totalPDFPages; i++) { 
            pdf.addPage(PDF_Width, PDF_Height);
            console.log(canvas_image_width+"  "+canvas_image_height);
            pdf.addImage(imgData, 'png', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
            }
            
            pdf.save("CVPrint.pdf");
           
        });        
    
    }


    generatePdf() {
        // setTimeout(() => {
         //   this.printpdf();
        //    }, 30000);    
        //window.print();
        //this.printDiv('cvprofile');    
        this.getpdf();
 
    }
    printPage()  {
        var printButton = document.getElementById("printpagebutton");
        var printButtonclose = document.getElementById("printclose");
        // //Set the print button visibility to 'hidden' 
      //  this.isbuttonvisible=false;
      printButton.style.visibility='hidden';
        printButtonclose.style.visibility='hidden';
        this.printDiv('cvprofile');
        printButton.style.visibility='visible';
        printButtonclose.style.visibility='visible';
    //     const invoiceIds = ['101', '102'];
    // this.printService
    //   .printDocument('invoice', invoiceIds);
  
        // let printContents, popupWin;
        // printContents = document.getElementById('cvprofile').innerHTML;
        // popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        // popupWin.document.open();
        // popupWin.document.write(`
        //   <html>
        //     <head>
        //       <title>Print tab</title>
        //       <style>

        //       //........Customized style.......
        //       </style>
        //     </head>
        // <body onload="window.print();window.close()">
        // ${printContents}
        // </body>
        //   </html>`
        // );
        // popupWin.document.close();
     //  window.print();
      }
      printDiv(divName) {
       
          
        
        var printContents = document.getElementById(divName).innerHTML;
     
       
        var originalContents = document.body.innerHTML;
        
        document.title='CV Print'
     // document.head.innerHTML="<style>.printbreakclass{padding-top:50px!important;}</style>"
       document.body.innerHTML = printContents;
        // console.log(htmlToPrint)
        window.print();
 
        document.body.innerHTML = originalContents; 
        // let popupWinindow
        // let innerContents = document.getElementById(divName).innerHTML;
        // popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        // popupWinindow.document.open();
        // popupWinindow.document.write('<html><head><link href="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css" rel="stylesheet" /></head><body onload="window.print()">' + innerContents + '</html>');
        // popupWinindow.document.close();
       
    }
   
    changedcheckbox(evt,field)
    {
        if(field=='boardCertified')
        {
            this.IsCheckedEligibles=false
           // this.IsCheckedCertified=true
           this.service.recruitview.boardEligibles=false
           this.boardplaceholder='Board Certified'
           this.texteditable=true;
         
        }
        if(field=='boardEligibles')
        {
            this.IsCheckedCertified=false;
          //  this.IsCheckedEligibles=true;
           this.service.recruitview.boardCertified=false
           this.boardplaceholder='Board Eligible'
           this.texteditable=true;
         
        }
        if(this.service.recruitview.boardCertified==false && this.service.recruitview.boardEligibles==false)
        {
            this.texteditable=false;
            this.boardplaceholder='Select Board Certified/Board Eligible'
        }
     
        

    }

    changedcheckboxclinicalleader(evt)
    {
        if(evt.target.checked)
        {
            this.isClinicalLeader=true;
            this.service.recruitview.clinicalLeaderName = '';
        }else
        {
            this.isClinicalLeader=false;
        }
    }
    onOpenFTEprint(id)
    {
        const Ids = [id.toString()];
        this.printService
         .printDocument('cmereimbursementPrintComponent', Ids);
    }
}