﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IUser } from '../auth/user.model'


@Injectable()

export class UserService {

constructor(private http:HttpClient){
}


login(formData) {
    return this.http.post('/api/account/login', formData);
  }

}

