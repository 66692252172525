import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import {ReportTableService } from '../../reports/view/reports-view.service';
import {uniquecodereport} from '../../reports/view/reports-view.model' 
import {  ViewChild, ElementRef } from '@angular/core';
import {DateParaService} from'../../reports/dateparameter/dateparameter-para.service'


@Component({
  selector: 'app-onstafflistwithuniquecodereport',
  templateUrl: './onstafflistwithuniquecodereport.component.html',
  styleUrls: ['./onstafflistwithuniquecodereport.component.scss']
})
export class OnstafflistwithuniquecodereportComponent implements OnInit {
  rowIds: string[];
  rowDetails: Promise<any>[];
  fetch: any[] = [];
  rowId:number;
  sdate:string;
  tdate:string;
  print:string;
  uniquecodereport:uniquecodereport[]=[];
  constructor( private route: ActivatedRoute, private reportprintService:ReportTableService,
    private printService: PrintService, private dateParaService:DateParaService,
    private http: HttpClient ){
      this.rowIds = route.snapshot.params['Id']
      .split(',');
      this.rowId = +this.rowIds[0];
      this.print= String(this.rowIds[1]);
}
ngOnInit() {
 

  this.reportprintService.getuniquecodereportlist()
  .toPromise()
  .then(res => {
    this.uniquecodereport = res;
    // console.log("getUsersWithPromise(): " + JSON.stringify(this.supervisoryagreementlist));
   if(this.print=='PDF')
   {
    this.printService.onDataReady(true,'On Staff List with Unique Code','PDF') 
   }
   if(this.print=='EXCEL')
   {
    this.printService.onDataReady(true,'On Staff List with Unique Code','EXCEL','On Staff List with Unique Code.xlsx',0,'customers') 
   }
   
   
   
    
  })
  .catch(err => { console.log(err) });




}




}
