import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import "rxjs/Rx";
import { Country, States, Cities, TimeZone, UpComingDOB, UpComingLicenseRenewal, RecruitCVProfile, RecruitsOtherdetail
,cisname } from './common.model';
import { DatePipe } from '@angular/common';

@Injectable()
export class CommonService{
    public counrty:Country[] = [];
    public states:States[] = [];
    public cities:Cities[] = [];
    public upcomingdob:UpComingDOB[] = [];
    public upcominglicenserenewal:UpComingLicenseRenewal[] = [];
    public timezone:TimeZone[] = [];
    public recruitcvprofile: RecruitCVProfile;
    public cisnamelist:cisname[]=[];
    public dlexpiredt:string;
    public passport: string;
    public ssn: string;
    public backgroundcheck: string;
    public collaborativeaggrementsignedon: string;
    public aggrementsignedon: string;
    public offerlettersignedon: string;
    public npi: string;
    public caqh: string;
    public nihss: string;
    public upin: string;
    public followupdate: string;
    public actiontaken: string;
    public licensuredisciplinaryactions: string;
    public coi: string;
    public pli: string;
    public medicalliabilityclaimsjudgementpast10years: string;
    public residency: string;
    public clinicalpracticingsince: string;
    public cmepast2years: string;
    public boardeligible: string;
    public postinterviewscore: string; 
    public cisnamevalue:string;
   
    constructor(private http:HttpClient, private datepipe:DatePipe){

    }
    //convert date to 'yyyy-MM-dd
    transformDate(date) {
        let l_date: string;
        l_date = this.datepipe.transform(date, 'yyyy-MM-dd'); //whatever format you need. 
        return l_date;
      }  
      transformDateFormat(date, format) {
        let l_date: string;
        l_date = this.datepipe.transform(date, format); //whatever format you need. 
        return l_date;
    }        
      transformdigitalnumber(value: number | string, fractionSize: number = 2): string {
        const ds = '.';
        const ts = ',';
        let [integer, fraction = ''] = (value || '').toString()
            .split(ds);

        // fraction = fractionSize > 0
        //     ? ds + (fraction + '00').substring(0, fractionSize)
        //     : '';

        // integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ts);
        // integer = integer ? integer : '0';

    

        integer=String(value);
        // format number
        
        integer = integer.replace(/\D/g, "")
           .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return integer 
    }
    getCountry(): Observable<boolean> {
        return this.http.get('api/common/country')
            .map( (data:any[])=>{
                this.counrty = data;
                return true;
            });
      }

      getStates(id?:number): Observable<boolean> {
          if(id==null) id=0
        return this.http.get(`api/common/states/${id}`)
            .map( (data:any[])=>{
                this.states = data;
                return true;
            });
      }
      getCities(id?:number): Observable<boolean> {
        if(id==null) id=0
        return this.http.get(`api/common/cities/${id}`)
            .map( (data:any[])=>{
                this.cities = data;
                return true;
            });
      }        
    
      getTimeZone(): Observable<boolean> {
        return this.http.get('api/common/timezone')
            .map( (data:any[])=>{
                this.timezone = data;
                return true;
            });
      }      
 
      getUpcomingDob(): Observable<boolean> {
        return this.http.get('api/general/upcomingdob')
            .map( (data:any[])=>{
                this.upcomingdob = data;
                return true;
            });
      }
      
      getUpcomingLicenseRenewal(): Observable<boolean> { 
        return this.http.get('api/general/upcominglicenserenewal')
            .map( (data:any[])=>{
                this.upcominglicenserenewal = data;
                return true;
            });
      }  
      
      getRecruitCVProfile(id?:number): Observable<boolean> {
        if(id==null) id=0
        return this.http.get(`api/recruits/getrecruitprofile/${id}`)
            .map( (data:RecruitCVProfile)=>{
                this.recruitcvprofile = data;
                return true;
            });
      } 
      getCISNameList(cisname:string): Observable<boolean> {
        
        return this.http.get(`api/recruits/getcisnamelist?cisname=${cisname}`)
            .map((data:any[])=>{
                this.cisnamelist = data;
                return true;
            });
      } 
      getCISNameAllList(): Observable<boolean> {
        
        return this.http.get(`api/recruits/getcisnamealllist`)
            .map((data:any[])=>{
                this.cisnamelist = data;
                return true;
            });
      }         
}


