import { Injectable } from '@angular/core';
import { HttpClient,HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs/Rx";
import {ewnnscore} from './ewnnscorereport.model'
@Injectable()
export class RecruitsEWNNScorePrintService{
    public ewnnscorelist:ewnnscore[] = [];
   
    
   
  
    constructor(private http: HttpClient) { }
    
    //Get
    getRecruitsEWNNSCorelist(): Observable<ewnnscore[]>  {
        return this.http.get(`api/recruits/getrecruitsewnnscorelist`)
        .map( (data:any[])=>{
            this.ewnnscorelist = data;
            return this.ewnnscorelist;
           // return true;
        });
    }
  
  
}