import { Pipe, PipeTransform } from "@angular/core";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@Pipe({
    name: 'SumPipe'
  })

export class SumPipe implements PipeTransform {
  // transform(items: Array<any>, field: number, value: number): Array<any> {
  //   if (!items) return [];
  //   return items.filter(it => (+it[field]) === (+value));
  //   }
    transform(input: any): any {
        return !this.isArray(input) 
        ? input : this.sum(input);
      }
       isArray(value: any): boolean {
        return Array.isArray(value);
      }
      sum(input: Array<number>, initial = 0): number {
        return input.reduce((previous: number, current: number) => previous + current, initial);
      }
      
}
@NgModule({
    imports: [
      CommonModule
    ],
    declarations: [SumPipe],
    providers: [],
    exports:[SumPipe]
  })
  export class SumPipeModule {}