import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { GroupByPipe } from './groupby.pipe'


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [GroupByPipe],
  providers: [],
  exports:[GroupByPipe]
})
export class GroupByPipeModule {}