import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import { RptCISAttendeesPrintService } from './rptcisattendees.service';
@Component({
    selector: 'app-form-rptcisattendees',
    templateUrl: './rptcisattendees.component.html',
    styleUrls: ['./rptcisattendees.component.scss'] 
  })
  export class RptCISAttendeesPrintComponent{
    Ids: string[];
    invoiceDetails: Promise<any>[];
    public filterData='cisDate';
    dataExt: any[] = [];
    Id:number;
    print:string;
    constructor(public service:RptCISAttendeesPrintService, route: ActivatedRoute,
      private printService: PrintService, private http: HttpClient ){
        this.Ids = route.snapshot.params['Id']
        .split(',');
        this.Id = +this.Ids[0];
        this.print=this.Ids[1];
  }
  ngOnInit() {
    if(this.Id==0)
    {
      Promise.all([this.service.printformdata])
      .then((values ) => 
      {
       
        this.printService.onDataReady(true,'Summit on Side Coordinator Pulse Checks')
      });
     
    }
    else
    {
      Promise.all([this.service.cisattendeesquantitylist])
      .then((values ) => 
      {
        var d = new Date(this.service.cisattendeesquantityparalist[2].columnsName);
        var quarter =d.getMonth()+1;
        var year =d.getFullYear();
        var season =String(quarter);
        if(quarter!=NaN)
        {
          if(quarter==12 || quarter==1 || quarter==2)
        {
          season='-Winter'+' '+year
        }
        if(quarter==3 || quarter==4 || quarter==5)
        {
          season='-Spring'+' '+year
        }
        if(quarter==6 || quarter==7 || quarter==8)
        {
          season='-Summer'+' '+year
        }
        if(quarter==9 || quarter==10 || quarter==11)
        {
          season='-Autumn'+' '+year
        }
        
        }
        else
        {
          season=''
        }
        
        if(this.print=='EXCEL')
        {
          this.printService.onDataReady(true,'Proactive MD Research Institute Summit '+season+'-RSVP','EXCEL','CIS RSVP LIST.xlsx',0,'customersvalue')
        }
        if(this.print=='PDF')
        {   this.printService.onDataReady(true,'Proactive MD Research Institute Summit'+season+'-RSVP')}
      //  this.processData();
   
      });
    }
  
   }
   calcStyles(orderby)
   {
     
     if(orderby==0)
     {
       return {'background-color':'yellow'}
     }
   }
   getSum(column) : number {
     
    let sum = 0;
    for(let i = 0; i < this.service.cisattendeessummaryreportlist.length; i++) {
      sum += this.service.cisattendeessummaryreportlist[i][column];
      
    }

    return sum;
  }
  getSumreport(column) : number {
     
    let sum = 0;
    for(let i = 0; i < this.service.cisattendeesreportviewlist.length; i++) {
      sum += this.service.cisattendeesreportviewlist[i][column];
      
    }

    return sum;
  }
  
  getSumquantity(column) : number
  {
    var days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday"
  ];
  
  let columnvalue=days[new Date(column).getDay()]
  let sum = 0;
  for(let i = 0; i < this.service.cisattendeesquantitylist.length; i++) {
    sum += this.service.cisattendeesquantitylist[i][columnvalue];
    
  }

  return sum;
    
  }
  getquantity(column,i) : number
  {
    var days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday"
  ];
  
  let columnvalue=days[new Date(column).getDay()]
  let quatntity = 0;
  // console.log('Name : '+this.service.cisattendeesquantitylist[i].name)
  // console.log(columnvalue)
  // console.log(this.service.cisattendeesquantitylist[i][columnvalue])
  if(this.service.cisattendeesquantitylist[i]['orderby']==1)
  {
    if(this.service.cisattendeesquantitylist[i][columnvalue]!=null)
    {
      quatntity =this.service.cisattendeesquantitylist[i][columnvalue];
    }
  }
 else{
  quatntity=null
 }
  


  return quatntity;
    
  }
  private processData()
  {
    const snameSeen = {};
    const srsvpSeen = {};
    this.dataExt = this.service.cisattendeesreportviewlist.sort((a, b) => {
      const nameComp = a.name.localeCompare(b.name);
      return nameComp ? nameComp : a.eventDate.localeCompare(b.eventDate) 

      
    }).map(x => {
      const snameSpan = snameSeen[x.name] ? 0 :
      this.service.cisattendeesreportviewlist.filter(y => y.name === x.name).length;

        snameSeen[x.name] = true;
       

      const srsvpSpan = srsvpSeen[x.name] && srsvpSeen[x.name][x.eventDate] ? 0 :
      this.service.cisattendeesreportviewlist.filter(y => y.name === x.name && y.eventDate === x.eventDate).length;

      srsvpSeen[x.name] = srsvpSpan[x.eventDate] || {};
      srsvpSeen[x.name][x.eventDate] = true;



      return { ...x, snameSpan, srsvpSpan };
    });
   
  }
  }